import React from "react";
import { useSelector } from "react-redux";

export default function SellerAvatarArea({ seller }) {
  const settingsData = useSelector((state) => state.settings);
const { theme } = settingsData;

  return (
    <div className="container px-4">
      <div className={`w-100 seller-avatar-wrapper d-flex gap-3 justify-content-center align-items-center bg-${theme}-100`}>
        <div className="seller-image-holder">
          <img src={seller?.avatarImage.url} alt="Seller image" />
        </div>
        <div className="seller-data">
          <p>
            <span>Prodavac:</span>{" "}
            <span>{seller.name}</span>
          </p>
          <p>
            <span>Radionica:</span>{" "}
            <span>Neka Tamo</span>
          </p>
          <p>
            <span>Telefon:</span>{" "}
            <span>{seller.phone}</span>
          </p>
          <p>
            <span>Email:</span>{" "}
            <span>{seller.email}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
