import {
  COMMISSION_LIST_REQUEST,
  COMMISSION_LIST_SUCCESS,
  COMMISSION_LIST_FAIL,
  COMMISSION_CREATE_REQUEST,
  COMMISSION_CREATE_SUCCESS,
  COMMISSION_CREATE_FAIL,
  COMMISSION_DELETE_REQUEST,
  COMMISSION_DELETE_SUCCESS,
  COMMISSION_DELETE_FAIL,
} from "../Constants/CommissionConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET COMMISSIONS
export const listCommissions = ({ pageNumber, keyword, commissionStatus }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: COMMISSION_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        },
      };

      const { data } = await _axios.get(`commissions/all?pageNumber=${pageNumber}&keyword=${keyword}&commissionStatus=${commissionStatus}`, config);

      dispatch({ type: COMMISSION_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: COMMISSION_LIST_FAIL,
        payload: message,
      });
    }
  };

// CREATE COMMISSION
export const createCommission =
  ({ commissionData }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: COMMISSION_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(`commissions/`, JSON.stringify(commissionData), config);

      dispatch({ type: COMMISSION_CREATE_SUCCESS, payload: data });
      dispatch(listCommissions({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: COMMISSION_CREATE_FAIL,
        payload: message,
      });
    }
  };

  // DELETE COMMISSION
export const deleteCommission = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMMISSION_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`commissions/${id}`, config);

    dispatch({ type: COMMISSION_DELETE_SUCCESS });
    dispatch(listCommissions({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: COMMISSION_DELETE_FAIL,
      payload: message,
    });
  }
};