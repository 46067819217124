import React from "react";
import { useSelector } from "react-redux";

export default function PageWrapper({ children }) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <main
      style={{ minHeight: "100vh" }}
      className={`main-wrap ${theme === "dark" ? "dark-theme-section" : ""}`}
    >
      {children}
    </main>
  );
}
