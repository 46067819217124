import React, { useState } from "react";
import ProductCard from "./ProductCard";
import NotSigninedModal from "./Modal";

export default function ProductGrid({ products, preview }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className={`product-grid-wrapper mb-4 ${
          preview === "card" ? "full-width" : ""
        }`}
      >
        {products &&
          products.map((product) => (
            <ProductCard
              key={product._id}
              product={product}
              preview={preview}
              setShowModal={setShowModal}
            />
          ))}
      </div>

      <NotSigninedModal show={showModal} setShow={setShowModal} />
    </>
  );
}
