export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST";
export const BRAND_CREATE_SUCCESS = "BRAND_CREATE_SUCCESS";
export const BRAND_CREATE_FAIL = "BRAND_CREATE_FAIL";
export const BRAND_CREATE_RESET = "BRAND_CREATE_RESET";

export const BRAND_EDIT_REQUEST = "BRAND_EDIT_REQUEST";
export const BRAND_EDIT_SUCCESS = "BRAND_EDIT_SUCCESS";
export const BRAND_EDIT_FAIL = "BRAND_EDIT_FAIL";
export const BRAND_EDIT_RESET = "BRAND_UPDATE_RESET";

export const BRAND_UPDATE_REQUEST = "BRAND_UPDATE_REQUEST";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAIL = "BRAND_EDIT_FAIL";
export const BRAND_UPDATE_RESET = "BRAND_UPDATE_RESET";

export const BRAND_DELETE_REQUEST = "BRAND_DELETE_REQUEST";
export const BRAND_DELETE_SUCCESS = "BRAND_DELETE_SUCCESS";
export const BRAND_DELETE_FAIL = "BRAND_DELETE_FAIL";