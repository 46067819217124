import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  listProducts,
} from "../../../Redux/dashboard/Actions/ProductActions";
import Pagination from "./Pagination";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import ProductFilters from "./ProductFilters";
import { Card, CardHeader, CardBody } from "../common";
import { listUser } from "../../../Redux/dashboard/Actions/UserActions";
import { isEmpty } from "lodash";
import CenteredModal from "./Modal";

export default function MainProducts({ byEmail, fromAdminDashboard, isAdmin }) {
  const [productId, setProductId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading: loadingUsers, error: errorUsers, users } = userList;

  const productList = useSelector((state) => state.productList);
  const { loading, error, page, pages, prevKeyword, sort, products } =
    productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const submitHandler = ({
    pageNumber,
    keyword,
    sellerId,
    sortBy,
    byEmail,
    toShow,
  }) => {
    dispatch(
      listProducts({
        pageNumber,
        keyword,
        sellerId,
        sortBy,
        byEmail,
        fromAdminDashboard,
        toShow,
      })
    );
  };

  const deleteHandler = (id) => {
    dispatch(deleteProduct(id));
  };

  useEffect(() => {
    if (successDelete) {
      submitHandler({ pageNumber: 1, keyword: "", byEmail, toShow: 12 });
    }
  }, [successDelete]);

  useEffect(() => {
    dispatch(listUser({ pageNumber: "all", keyword: "", status: "all" }));
  }, [dispatch]);

  console.log("products:::", products);

  return (
    <>
      <div className="container mt-3">
        <div className="d-flex flex-row justify-content-between mb-4">
          <h2 className="content-title">Proizvodi</h2>
          {!byEmail && !isAdmin && (
            <div>
              <Link to="/addproduct" className="btn btn-primary">
                Kreiraj novi
              </Link>
            </div>
          )}
        </div>

        <Card className="shadow-sm">
          <CardHeader section>
            <ProductFilters
              submitHandler={submitHandler}
              byEmail={byEmail}
              users={users}
            />
          </CardHeader>

          <CardBody section>
            {errorDelete && (
              <Message variant="alert-danger">{errorDelete}</Message>
            )}
            {loading || loadingDelete || loadingUsers ? (
              <Loading
                position="position-absolute"
                width="100%"
                height="100%"
              />
            ) : error || errorUsers ? (
              <Message variant="alert-danger">{error || errorUsers}</Message>
            ) : (
              <div className="dashboard-product-grid-wrapper">
                {/* Products */}
                {!isEmpty(products) &&
                  products.map((product) => (
                    <Product
                      product={product}
                      setProductId={setProductId}
                      setShowDeleteModal={setShowDeleteModal}
                      key={product._id}
                      isAdmin={isAdmin}
                    />
                  ))}
              </div>
            )}

            <Pagination
              page={page}
              pages={pages}
              keyword={prevKeyword}
              sort={sort}
              toShow={12}
              submitHandler={submitHandler}
            />
          </CardBody>
        </Card>
      </div>

      <CenteredModal
        title="Da li sigurno zelis da obrises ovaj proizvod??"
        show={showDeleteModal}
        onHide={() => {
          setProductId("");
          setShowDeleteModal(false);
        }}
        reqid={productId}
        submithandler={{ call: deleteHandler }}
      />
    </>
  );
}
