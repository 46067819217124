import React, { useEffect } from "react";
import Pagination from "../common/Pagination";
import ProductGrid from "./ProductGrid";
import MenuAccordiion from "../common/MenuAccordion";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../../Redux/client/Actions/ProductActions";
import ProductsTop from "./ProductsTop";
import ShopFilters from "./ShopFilters";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import SellerAvatarArea from "./SellerAvatarArea";

export default function ShopMain({ slug, byBrand, sellerId, keywordProp }) {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.clientProductList);
  const {
    loading,
    page,
    pages,
    prevBrands,
    prevKeyword,
    toShow,
    sort,
    products,
  } = productList;

  const brandList = useSelector((state) => state.brandList);
  const { loading: loadingBrands } = brandList;

  const materialData = useSelector((state) => state.materialList);
  const { loading: loadingMaterials } = materialData;

  const cart = useSelector((state) => state.cart);
  const { loading: cartLoading } = cart;

  const productPreviewState = useSelector((state) => state.productPreview);
  const { preview } = productPreviewState;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const submitHandler = ({
    pageNumber,
    keyword,
    sellerId,
    toShow,
    brands,
    sortBy,
  }) => {
    window.scrollTo(0, 0);
    dispatch(
      listProduct({
        pageNumber,
        keyword,
        slug,
        sellerId,
        toShow,
        brands,
        sortBy,
      })
    );
  };

  useEffect(() => {
    if (slug || sellerId || byBrand || keywordProp) {
      submitHandler({
        pageNumber: 1,
        keyword: keywordProp,
        sellerId,
        toShow,
        brands: !keywordProp ? byBrand || prevBrands?.toString() : "",
        sortBy: sort,
      });
    }
  }, [dispatch, slug, byBrand, sellerId, keywordProp]);

  return (
    <>
      <div className="container shop-main">
        {sellerId && products[0] && (
          <SellerAvatarArea seller={products[0]?.seller} />
        )}
        <div className="section">
          <div className="row">
            <div
              className={`border-right-1-${theme}-500 col-lg-3 col-md-3 article mt-4 d-none d-md-block overflowY-auto px-3`}
            >
              <div style={{top: "60px"}} className="sticky">
                <ShopFilters keywordProp={keywordProp} />
                <div className="mt-5">
                  <h6 className="text-center text-gray mb-1">MENI</h6>
                  <MenuAccordiion />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 article mt-3 mt-md-0 mt-lg-0">
              <div className="shopcontainer">
                <ProductsTop
                  sellerId={sellerId}
                  hideSearch={true}
                  keywordProp={keywordProp}
                />
                {loading ? (
                  <div className="mb-5">{/* <Loading /> */}</div>
                ) : !products || products.length === 0 ? (
                  <Message variant="alert-danger">Nema proizvoda.</Message>
                ) : (
                  <ProductGrid products={products} preview={preview} />
                )}

                {/* Pagination */}
                <Pagination
                  sellerId={sellerId}
                  page={page}
                  pages={pages}
                  sortBy={sort}
                  toShow={toShow}
                  keyword={prevKeyword}
                  submitHandler={submitHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {(loading || loadingBrands || loadingMaterials || cartLoading) && (
        <Loading />
      )}
    </>
  );
}
