import React from 'react';
import Avatar from 'react-avatar-edit';

export default function AvatarComponent({ setImage }) {
  const onClose = () => {
    setImage("");
  };

  const onCrop = (preview) => {
    console.log('preview:::', preview);
    setImage(preview);
  };

  return (
    <div className='user-avatar'>
      <Avatar
        width={200}
        height={200}
        onCrop={onCrop}
        onClose={onClose}
      />
    </div>
  );
};