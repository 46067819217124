import React, { useEffect } from "react";
// import Pagination from "./pagination";
import BlogGrid from "./BlogGrid";
import { useDispatch, useSelector } from "react-redux";
import BlogFilters from "./BlogFilters";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import { listBlogs } from "../../../Redux/dashboard/Actions/BlogActions";
import Pagination from "../common/Pagination";

export default function BlogMain() {
  const dispatch = useDispatch();

  const blogData = useSelector((state) => state.clientBlogList);
  const { loading, error, page, pages, prevKeyword, sortBy, toShow, blogs } = blogData;

  const submitHandler = ({ pageNumber, keyword, toShow, sortBy }) => {
    window.scrollTo(0, 0);
    dispatch(
      listBlogs({
        pageNumber,
        keyword,
        toShow,
        sortBy,
      })
    );
  };

  useEffect(() => {
    if (dispatch) {
      submitHandler({
        pageNumber: 1,
        keyword: "",
      });
    }
  }, [dispatch]);

  return (
    <>
      <div className="container">
        <h1 className="text-gray my-3">NOVOSTI</h1>
        <BlogFilters submitHandler={submitHandler} hideSearch={true} />
        {error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <BlogGrid blogs={blogs} />
        )}

        {/* Pagination */}
        <Pagination
          page={page}
          pages={pages}
          sortBy={sortBy}
          toShow={toShow}
          keyword={prevKeyword}
          submitHandler={submitHandler}
        />
      </div>
      {loading && <Loading />}
    </>
  );
}
