import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

export default function DeleteMessageModal(props) {
  const { onConfirm, reqid, onShow, show, onHide } = props;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Obrisi poruku?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
            onConfirm(reqid);
          }}
        >
          Potvrdi
        </Button>
        <Button variant="danger" onClick={onHide}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
