import {
  SELLER_LIST_REQUEST,
  SELLER_LIST_SUCCESS,
  SELLER_LIST_FAIL,
  SELLER_COMMISSION_REQUEST,
  SELLER_COMMISSION_SUCCESS,
  SELLER_COMMISSION_FAIL,
  SELLER_CREATE_REQUEST,
  SELLER_CREATE_SUCCESS,
  SELLER_CREATE_FAIL,
  SELLER_EDIT_REQUEST,
  SELLER_EDIT_SUCCESS,
  SELLER_EDIT_FAIL,
  SELLER_UPDATE_REQUEST,
  SELLER_UPDATE_SUCCESS,
  SELLER_UPDATE_FAIL,
  SELLER_DELETE_REQUEST,
  SELLER_DELETE_SUCCESS,
  SELLER_DELETE_FAIL,
} from "../Constants/SellerConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET SELLERS
export const listSellers = ({ pageNumber, keyword }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SELLER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        },
      };

      const { data } = await _axios.get(`sellers/all?pageNumber=${pageNumber}&keyword=${keyword}`, config);

      dispatch({ type: SELLER_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: SELLER_LIST_FAIL,
        payload: message,
      });
    }
  };

// GET SELLERS WITH COMMISSION
export const listSellersWithCommission = ({ pageNumber, keyword }) =>
async (dispatch, getState) => {
  try {
    dispatch({ type: SELLER_COMMISSION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      },
    };

    const { data } = await _axios.get(`sellers/commission?pageNumber=${pageNumber}&keyword=${keyword}`, config);
    dispatch({ type: SELLER_COMMISSION_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_COMMISSION_FAIL,
      payload: message,
    });
  }
};

// CREATE SELLER
export const createSeller =
  ({ name, email, phone, address, amount, description }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SELLER_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();

      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("amount", amount);
      formData.append("description", description);

      const { data } = await _axios.post(`sellers/`, formData, config);

      dispatch({ type: SELLER_CREATE_SUCCESS, payload: data });
      dispatch(listSellers({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: SELLER_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT SELLER
export const editSeller = (id) => async (dispatch) => {
  try {
    dispatch({ type: SELLER_EDIT_REQUEST });
    const { data } = await _axios.get(`sellers/${id}`);
    dispatch({ type: SELLER_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_EDIT_FAIL,
      payload: message,
    });
  }
};

// UPDATE SELLER
export const updateSeller = (seller) => async (dispatch, getState) => {
  const {
    _id,
    name,
    email,
    description,
  } = seller;

  try {
    dispatch({ type: SELLER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("description", description);

    const { data } = await _axios.put(
      `sellers/${_id}`,
      formData,
      config
    );

    dispatch({ type: SELLER_UPDATE_SUCCESS, payload: data });
    dispatch({ type: SELLER_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_UPDATE_FAIL,
      payload: message,
    });
  }
};

  // DELETE SELLER
export const deleteSeller = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SELLER_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`sellers/${id}`, config);

    dispatch({ type: SELLER_DELETE_SUCCESS });
    dispatch(listSellers({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: SELLER_DELETE_FAIL,
      payload: message,
    });
  }
};