import React from "react";
import Header from "../../components/client/Header";
import ShopImage from "../../components/client/common/ShopImage";
import ShopMain from "../../components/client/shop-components/ShopMain";
import { useLocation } from "react-router-dom";
import Footer from "../../components/client/Footer";

const ShopScreen = () => {
  const location = useLocation();
  const { keywordProp, byBrand, pathname } = location;
  const slug = pathname.replace('/shop/', '');

  console.log("location:::", location);

  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <ShopImage breadcrumb={slug} />
      <ShopMain
        slug={slug}
        byBrand={byBrand}
        keywordProp={keywordProp}
      />
      <Footer />
    </>
  );
};

export default ShopScreen;
