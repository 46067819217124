import {
  CLOUDINARY_USAGE_REQUEST,
  CLOUDINARY_USAGE_SUCCESS,
  CLOUDINARY_USAGE_FAIL,
} from "../Constants/CloudinaryConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET CLOUDINARY USAGE
export const cloudinaryUsage = () => async (dispatch) => {
  try {
    dispatch({ type: CLOUDINARY_USAGE_REQUEST });

    const { data } = await _axios.get(`cloudinary/usage`);

    dispatch({ type: CLOUDINARY_USAGE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: CLOUDINARY_USAGE_FAIL,
      payload: message,
    });
  }
};
