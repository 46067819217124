import React from "react";
import SpecifiedDimensions from "./SpecifiedDimensions";
import MultiRangeSlider from "../ui/form/multiRangeSlider/MultiRangeSlider";

export default function Dimensions({
  dimensionsType,
  setDimensionsType,
  dimensions,
  setDimensions,
  items,
  setItems,
}) {
  return (
    <>
      <h3 className="text-center mt-4">Dimenzije</h3>
      <div className="d-flex justify-content-center gap-3 mb-3 mt-2">
        <div className="form-check">
          <input
            id="none"
            className="form-check-input"
            type="radio"
            name="dimensionsType"
            value="none"
            onChange={(e) => setDimensionsType(e.target.value)}
            checked={dimensionsType === "none"}
          />
          <label className="form-check-label" htmlFor="none">
            Prazno
          </label>
        </div>
        <div className="form-check">
          <input
            id="free"
            className="form-check-input"
            type="radio"
            name="dimensionsType"
            value="free"
            onChange={(e) => setDimensionsType(e.target.value)}
            checked={dimensionsType === "free"}
          />
          <label className="form-check-label" htmlFor="free">
            Dimenzije u rasponu
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="dimensionsType"
            id="specified"
            value="specified"
            onChange={(e) => setDimensionsType(e.target.value)}
            checked={dimensionsType === "specified"}
          />
          <label className="form-check-label" htmlFor="specified">
            Kreiraj dimenzije
          </label>
        </div>
      </div>

      {dimensionsType === "free" && (
        <div className="free-dimensions mb-4 row">
          <div className="col-md-4 mb-5 mb-md-4 mb-lg-4 col-lg-4 col-12">
            <label htmlFor="product_width" className="form-label">
              Širina
            </label>
            <MultiRangeSlider
              min={0}
              max={1000}
              onChange={({ min, max }) => {
                const prev = {
                  ...dimensions,
                  minWidth: min,
                  maxWidth: max,
                };
                setDimensions(prev);
              }}
              minValue={dimensions.minWidth}
              maxValue={dimensions.maxWidth}
            />
          </div>
          <div className="col-md-4 mb-5 mb-md-4 mb-lg-4 col-lg-4 col-12">
            <label htmlFor="product_length" className="form-label">
              Dužina
            </label>
            <MultiRangeSlider
              min={0}
              max={1000}
              onChange={({ min, max }) => {
                const prev = {
                  ...dimensions,
                  minLength: min,
                  maxLength: max,
                };
                setDimensions(prev);
              }}
              minValue={dimensions.minLength}
              maxValue={dimensions.maxLength}
            />
          </div>
          <div className="col-md-4 mb-5 mb-md-4 mb-lg-4 col-lg-4 col-12">
            <label htmlFor="product_height" className="form-label">
              Visina
            </label>
            <MultiRangeSlider
              min={0}
              max={1000}
              onChange={({ min, max }) => {
                const prev = {
                  ...dimensions,
                  minHeight: min,
                  maxHeight: max,
                };
                setDimensions(prev);
              }}
              minValue={dimensions.minHeight}
              maxValue={dimensions.maxHeight}
            />
          </div>
        </div>
      )}
      {dimensionsType === "specified" && (
        <SpecifiedDimensions
          items={items}
          setItems={setItems}
        />
      )}
    </>
  );
}
