import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import PageWrapper from "../../components/dashboard/common/PageWapper";
import ProfileMain from "../../components/dashboard/profile/ProfileMain";

export default function ProfileScreen({ match }) {
  const userId = match.params.id;
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <ProfileMain userId={userId} />
      </PageWrapper>
    </>
  );
}
