import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "../common";

export default function Product({
  product,
  setProductId,
  setShowDeleteModal,
  isAdmin,
}) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <Card className="relative card-product-grid shadow-sm pt-4">
      <Link
        to="#"
        className={`img-wrap d-flex align-items-center justify-content-center overflow-hidden position-relative px-3 ${
          theme === "dark" ? "bg-dark" : ""
        }`}
      >
        <img src={product.imageFiles[0]?.src} alt="Product" />
      </Link>
      <div className="info-wrap">
        <div className="d-flex justify-content-between">
          <Link
            to="#"
            className={`title text-truncate ${
              theme === "dark" ? "text-light" : ""
            }`}
          >
            {product.name}
          </Link>
          <div className="price mb-2">{product.price} rsd</div>
        </div>
        <div className="d-flex justify-content-between">
          <Link
            to="#"
            className={`title text-truncate ${
              theme === "dark" ? "text-light" : ""
            }`}
          >
            Na stanju:
          </Link>
          <div className="price mb-2">{product.countInStock}</div>
        </div>
        <div className="d-flex justify-content-between">
          <Link
            to="#"
            className={`title text-truncate ${
              theme === "dark" ? "text-light" : ""
            }`}
          >
            Broj pregleda:
          </Link>
          <div className="price mb-2">{product.views}</div>
        </div>
        {!isAdmin && (
          <div className="row">
            <Link
              to={`/product/${product._id}/edit`}
              className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
            >
              <i className="fas fa-pen"></i>
            </Link>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setProductId(product._id);
                setShowDeleteModal(true);
              }}
              className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
            >
              <i className="fas fa-trash-alt"></i>
            </Link>
          </div>
        )}
      </div>
      {product.followers.length > 0 && (
        <div
          style={{
            top: "-10px",
            right: "-10px",
            minWidth: "32px",
            minHeight: "20px",
          }}
          className="absolute p-1 flex content-center items-center bg-red rounded-circle text-white"
        >
          {product.followers.length}
        </div>
      )}
    </Card>
  );
}
