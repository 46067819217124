import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  editProduct,
  listSimilarProducts,
  updateProduct,
} from "../../../Redux/dashboard/Actions/ProductActions";
import { listCategories } from "../../../Redux/dashboard/Actions/CategoryActions";
import { sendMail } from "../../../Redux/dashboard/Actions/PostActions";
import { PRODUCT_UPDATE_RESET } from "../../../Redux/dashboard/Constants/ProductConstants";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import FollowersTable from "./FollowersTable";
import SortableImages from "../common/sortable/SortableImages";
import Toast from "./../loading-error/Toast";
import CategoryTree from "../common/CategoryTree";
import { Section, Card, CardBody } from "../common";
import Dimensions from "./Dimensions";
import ProductDescription from "./description/ProductDescription";
import { listBrands } from "../../../Redux/dashboard/Actions/BrandActions";
import Input from "../ui/form/Input";
import Select from "../ui/form/Select";
import { listMaterials } from "../../../Redux/dashboard/Actions/MaterialActions";
import ChooseProducts from "../common/ChooseProducts";

const EditProductMain = ({ productId }) => {
  const [productData, setProductData] = useState({
    targetCategory: "",
    seller: {},
    name: "",
    secretName: "",
    buildingDaysNum: 1,
    price: 0,
    brand: "",
    material: "",
    discount: { value: 0, type: "%" },
    similarProductsLocal: [],
    followers: [],
    countInStock: 0,
    description: "",
    descriptionTable: [],
    dimensionsType: "none",
    dimensionItems: [],
    freeDimensions: {
      minWidth: "",
      maxWidth: "",
      minLength: "",
      maxLength: "",
      minHeight: "",
      maxHeight: "",
    },
    showOnHome: false,
    isNew: false,
    isActive: true,
    meta: "",
    similarsToDeleteTheProductFrom: [],
  });
  const [images, setImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const dispatch = useDispatch();

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const productEdit = useSelector((state) => state.productEdit);
  const { loading, error, product } = productEdit;

  const similarProductsData = useSelector((state) => state.productSimilarList);
  const { loading: loadingSimilarProducts, products: similarProducts } =
    similarProductsData;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const materialData = useSelector((state) => state.materialList);
  const {
    loading: loadingMaterials,
    error: errorMaterials,
    materials,
  } = materialData;

  const categoriesListObj = useSelector((state) => state.categoriesList);
  const { categories } = categoriesListObj;

  // const sendMailToFollower = useSelector((state) => state.sendMail);
  // const { sendMailInfo } = sendMailToFollower;

  const brandData = useSelector((state) => state.brandList);
  const { loading: loadingBrands, error: errorBrands, brands } = brandData;

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    theme,
  };

  const {
    targetCategory,
    seller,
    name,
    secretName,
    buildingDaysNum,
    price,
    brand,
    material,
    discount,
    dimensionsType,
    freeDimensions,
    specifiedDimensions,
    description,
    descriptionTable,
    countInStock,
    showOnHome,
    isActive,
    isNew,
    meta,
    similarProductsLocal,
    followers,
    similarsToDeleteTheProductFrom,
  } = productData;

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateProduct({
        _id: productId,
        path: targetCategory.path,
        name,
        secretName,
        buildingDaysNum,
        price,
        brand,
        material,
        discount,
        dimensionsType,
        freeDimensions,
        specifiedDimensions,
        description,
        descriptionTable,
        images,
        countInStock,
        imagesToDelete,
        showOnHome,
        isActive,
        isNew,
        meta,
        similarProducts: similarProductsLocal?.map((n) => n._id),
        followers,
        similarsToDeleteTheProductFrom,
      })
    );

    const mailData = product.followers
      ?.filter((n) => n.price > price)
      .map((follower) => ({
        ...follower,
        productId,
        name,
        newPrice: price,
        link: `http://localhost:5000/products/${productId}`,
      }));

    if (!isEmpty(mailData)) {
      dispatch(sendMail({ mailData }));
    }
  };

  console.log("productData:::", product);

  const onSetProductData = (e) => {
    e?.preventDefault();
    setProductData((prevState) => ({
      ...prevState,
      name: product.name || "",
      secretName: product.secretName || "",
      description: product.description || "",
      descriptionTable: product.descriptionTable || [],
      countInStock: product.countInStock || 0,
      price: product.price || 0,
      buildingDaysNum: product.buildingDaysNum || 1,
      brand: product.brand || "",
      material: product.material || "",
      discount: product.discount || { value: 0, type: "%" },
      followers: product.followers || [],
      seller: product.seller || {},
      dimensionsType: product.dimensionsType || "",
      freeDimensions: product.freeDimensions,
      specifiedDimensions: product.specifiedDimensions || [],
      showOnHome: product.showOnHome || false,
      isActive: product.isActive || true,
      isNew: product.isNew || false,
      meta: product.meta || "",
      followers: product.followers || [],
      similarsToDeleteTheProductFrom: [],
    }));
    setImages(product.imageFiles || []);
    dispatch(listSimilarProducts({ similarProducts: product.similarProducts }));
  };

  const findCategory = useCallback(
    (categories) => {
      categories.forEach((category) => {
        if (category.path === product?.path) {
          setProductData((prevState) => ({
            ...prevState,
            targetCategory: category,
          }));
        } else if (category.children?.length) {
          findCategory(category.children);
        }
      });
    },
    [product]
  );

  const onDeleteFollower = (id) => {
    setProductData((prev) => ({
      ...prev,
      followers: prev.followers.filter((n) => n._id !== id),
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      toast.success("Izmene su sacuvane.", ToastObjects);
    } else if (productId) {
      dispatch(editProduct(productId));
    }
  }, [dispatch, productId, successUpdate]);

  useEffect(() => {
    if (product) {
      onSetProductData();
    }
  }, [product]);

  useEffect(() => {
    dispatch(listCategories({ filtered: true }));
    dispatch(listBrands({ pageNumber: "1", keyword: "" }));
    dispatch(listMaterials());
  }, [dispatch]);

  useEffect(() => {
    if (categories) {
      findCategory(categories);
    }
  }, [categories, findCategory]);

  useEffect(() => {
    if (similarProducts) {
      setProductData((prevState) => ({
        ...prevState,
        similarProductsLocal: similarProducts,
      }));
    }
  }, [similarProducts]);

  return (
    <>
      <Toast />
      <Section className="position-relative">
        <form onSubmit={submitHandler}>
          <h2 className="content-title text-center mb-4">Izmeni proizvod</h2>
          <div className="d-flex justify-content-between mb-3">
            <Link
              style={{ width: "132px" }}
              to="/dashboard-products"
              className="btn btn-danger text-white"
            >
              Idi na proizvode
            </Link>
            <button
              type="submit"
              className="btn btn-warning"
              onClick={(e) => {
                onSetProductData(e);
                findCategory(categories);
              }}
            >
              <i className="fas fa-redo-alt"></i>
            </button>
            <button
              style={{ width: "132px" }}
              type="submit"
              className="btn btn-primary"
            >
              Sacuvaj
            </button>
          </div>

          <div className="mb-4">
            <div className="p-0 m-auto">
              <Card className="mb-4 shadow-sm">
                <CardBody className="d-flex gx-0 gap-1 flex-column">
                  {error || errorUpdate || errorBrands || errorMaterials ? (
                    <Message variant="alert-danger">
                      {error || errorUpdate || errorBrands || errorMaterials}
                    </Message>
                  ) : (
                    <>
                      <div className="mb-4">
                        <label
                          htmlFor="product_title"
                          className="form-label d-block text-center mb-3"
                        >
                          Izaberi kategoriju
                        </label>
                        <CategoryTree
                          onlyEndChild={true}
                          targetCategory={targetCategory}
                          setTargetCategory={(targetCategory) =>
                            setProductData((prevState) => ({
                              ...prevState,
                              targetCategory,
                            }))
                          }
                        />
                      </div>

                      <div className="d-flex flex-wrap gap-2 mb-3">
                        <Input
                          id="product_seller"
                          className="flex-grow-1"
                          type="text"
                          label="Prodavac"
                          disabled={true}
                          value={seller?.name || ""}
                        />
                        <Input
                          id="name"
                          className="flex-grow-1"
                          type="text"
                          label="Naziv"
                          required={true}
                          value={name || ""}
                          onChange={handleInputChange}
                        />
                        <Input
                          id="secretName"
                          className="flex-grow-1"
                          type="text"
                          label="Tajni Naziv"
                          required={true}
                          value={secretName || ""}
                          onChange={handleInputChange}
                        />
                        <Input
                          id="price"
                          className="flex-grow-1"
                          type="number"
                          label="Cena"
                          required={true}
                          value={price || 0}
                          onChange={handleInputChange}
                        />
                        <Input
                          id="countInStock"
                          className="flex-grow-1 mb-3"
                          type="number"
                          label="Na stanju"
                          required={true}
                          value={countInStock || 0}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="d-flex flex-wrap gap-2 mb-3">
                        <Input
                          id="buildingDaysNum"
                          className="flex-grow-1"
                          type="number"
                          label="Broj dana za izradu"
                          required={true}
                          value={buildingDaysNum}
                          onChange={handleInputChange}
                        />
                        <Select
                          id="brand"
                          label="Brend"
                          className="flex-grow-1"
                          value={brand || ""}
                          onChange={handleInputChange}
                        >
                          <option value=""></option>
                          {brands?.brands.map((brand) => (
                            <option key={brand._id} value={brand.title}>
                              {brand.title}
                            </option>
                          ))}
                        </Select>

                        <Select
                          id="material"
                          label="Materijal"
                          className="flex-grow-1"
                          value={material || ""}
                          onChange={handleInputChange}
                        >
                          <option value=""></option>
                          {materials?.map((material) => (
                            <option key={material._id} value={material.title}>
                              {material.title}
                            </option>
                          ))}
                        </Select>
                        <div className="flex-grow-1 d-flex gap-1 align-items-end mb-3">
                          <Input
                            id="discount"
                            type="number"
                            className="flex-grow-1"
                            label="Popust"
                            value={discount?.value}
                            onChange={(e) => {
                              setProductData((prevState) => ({
                                ...prevState,
                                discount: {
                                  ...prevState.discount,
                                  value: e.target.value,
                                },
                              }));
                            }}
                          />

                          <Select
                            id="discount"
                            value={discount?.type || ""}
                            onChange={(e) => {
                              setProductData((prevState) => ({
                                ...prevState,
                                discount: {
                                  ...prevState.discount,
                                  type: e.target.value,
                                },
                              }));
                            }}
                          >
                            <option>%</option>
                            <option>RSD</option>
                          </Select>
                        </div>
                      </div>

                      <Dimensions
                        dimensions={freeDimensions}
                        setDimensions={(freeDimensions) =>
                          setProductData((prevState) => ({
                            ...prevState,
                            freeDimensions,
                          }))
                        }
                        dimensionsType={productData.dimensionsType}
                        setDimensionsType={(dimensionsType) =>
                          setProductData((prevState) => ({
                            ...prevState,
                            dimensionsType,
                          }))
                        }
                        items={specifiedDimensions}
                        setItems={(specifiedDimensions) =>
                          setProductData((prevState) => ({
                            ...prevState,
                            specifiedDimensions,
                          }))
                        }
                      />

                      <hr className="hr hr-blurry" />

                      <ProductDescription
                        value={description}
                        tableValue={descriptionTable}
                        onTableChange={(descriptionTable) =>
                          setProductData((prevState) => ({
                            ...prevState,
                            descriptionTable,
                          }))
                        }
                        onChange={(description) =>
                          setProductData((prevState) => ({
                            ...prevState,
                            description,
                          }))
                        }
                      />

                      <div className="mb-4">
                        <label className="form-label">Slike</label>
                        <SortableImages
                          images={images}
                          setImages={setImages}
                          setImagesToDelete={setImagesToDelete}
                          multiple={true}
                        />
                      </div>

                      <div className="mb-4">
                        <ChooseProducts
                          title="Slicni proizvodi"
                          choosenProducts={similarProductsLocal}
                          addSimmilarToDelete={(similar) =>
                            setProductData((prevState) => ({
                              ...prevState,
                              similarsToDeleteTheProductFrom: [
                                ...(prevState.similarsToDeleteTheProductFrom ||
                                  []),
                                similar,
                              ],
                            }))
                          }
                          setChoosenProducts={(similarProductsLocal) =>
                            setProductData((prevState) => ({
                              ...prevState,
                              similarProductsLocal,
                            }))
                          }
                          productId={product._id}
                        />
                      </div>

                      <div className="form-check form-switch">
                        <input
                          id="showOnHome"
                          className="form-check-input"
                          type="checkbox"
                          checked={showOnHome}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showOnHome"
                        >
                          Prikazi na pocetnoj strani
                        </label>
                      </div>

                      <div className="form-check form-switch">
                        <input
                          id="isNew"
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          checked={isNew}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label" htmlFor="isNew">
                          Prikaci bedz "NOVO"
                        </label>
                      </div>

                      <div className="form-check form-switch">
                        <input
                          id="isActive"
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label" htmlFor="isActive">
                          {isActive
                            ? "Deaktiviraj proizvod"
                            : "Aktiviraj proizvod"}
                        </label>
                      </div>

                      <div className="form-floating mt-4">
                        <textarea
                          id="meta"
                          className={`${`form-control ${
                            theme === "dark"
                              ? "dark-theme-section text-light"
                              : ""
                          }`}`}
                          placeholder="Kucaj ovde"
                          value={meta}
                          onChange={handleInputChange}
                        ></textarea>
                        <label htmlFor="meta">Meta</label>
                      </div>

                      <FollowersTable
                        followers={followers}
                        onDeleteFollower={onDeleteFollower}
                      />
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </form>

        {(loading ||
          loadingUpdate ||
          loadingBrands ||
          loadingMaterials ||
          loadingSimilarProducts) && (
          <Loading position="position-absolute" width="100%" height="100%" />
        )}
      </Section>
    </>
  );
};

export default EditProductMain;
