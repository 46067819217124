import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import DoneProductsTable from "./DoneProductsTable";
import DoneProductsFilters from "./DoneProductsFilters";
import Pagination from "../common/Pagination";
import { listOrders } from "../../../Redux/dashboard/Actions/OrderActions";
import { Section, Card, CardHeader, CardBody } from "../common";
import TodoProducts from "./TodoProducts";
import { listDoneTodo } from "../../../Redux/dashboard/Actions/TodoActions";

export default function TodoMain() {
  const [todoProducts, setTodoProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [toShow, setToShow] = useState(20);

  const todoListDone = useSelector((state) => state.todoListDone);
  const { todos, loading, error } = todoListDone;

  const dispatch = useDispatch();

  const submitHandler = ({ pageNumber, keyword, toShow }) => {
    dispatch(listDoneTodo({ pageNumber, keyword, toShow }));
  };

  useEffect(() => {
    submitHandler({ pageNumber: 1, keyword: "", toShow: 20 });
  }, [dispatch]);

  useEffect(() => {
    if (todos?.length) {
      const { page, pages, prevKeyword, pageSize } = todos;
      setPage(page);
      setPages(pages);
      setKeyword(prevKeyword);
      setToShow(pageSize);
    }
  }, [todos, dispatch]);

  return (
    <Section className="position-relative">
      <div className="content-header">
        <h2 className="content-title">Todo</h2>
      </div>

      <Card section className="mb-4 shadow-sm">
        <TodoProducts
          title="Proizvodi za izradu"
          todoProducts={todoProducts}
          setTodoProducts={setTodoProducts}
        />

        <CardHeader>
          <DoneProductsFilters submitHandler={submitHandler} />
        </CardHeader>

        <CardBody className="card-body">
          <div className="table-responsive">
            {loading ? (
              <Loading
                position="position-absolute"
                width="100%"
                height="100%"
              />
            ) : error ? (
              <Message variant="alert-danger">{error}</Message>
            ) : (
              <>
                <DoneProductsTable todos={todos} />
              </>
            )}
          </div>

          <Pagination
            page={page}
            pages={pages}
            keyword={keyword}
            toShow={toShow}
            submitHandler={submitHandler}
          />
        </CardBody>
      </Card>
    </Section>
  );
}
