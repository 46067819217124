import {
  EMAIL_LIST_REQUEST,
  EMAIL_LIST_SUCCESS,
  EMAIL_LIST_FAIL,
} from "../Constants/NewsletterConstants";

// LIST EMAIL
export const listEmailReducer = (state = [], action) => {
  switch (action.type) {
    case EMAIL_LIST_REQUEST:
      return { loading: true };
    case EMAIL_LIST_SUCCESS:
      return { loading: false, emails: action.payload };
    case EMAIL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
