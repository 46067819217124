export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_DELIVERED_REQUEST = "ORDER_DELIVERED_REQUEST";
export const ORDER_DELIVERED_SUCCESS = "ORDER_DELIVERED_SUCCESS";
export const ORDER_DELIVERED_FAIL = "ORDER_DELIVERED_FAIL";
export const ORDER_DELIVERED_RESET = "ORDER_DELIVERED_RESET";

export const ORDER_STATUS_REQUEST = "ORDER_STATUS_REQUEST";
export const ORDER_STATUS_SUCCESS = "ORDER_STATUS_SUCCESS";
export const ORDER_STATUS_FAIL = "ORDER_STATUS_FAIL";

export const NEW_ORDER_REQUEST = "NEW_ORDER_REQUEST";
export const NEW_ORDER_SUCCESS = "NEW_ORDER_SUCCESS";
export const NEW_ORDER_FAIL = "NEW_ORDER_FAIL";

export const ORDER_COMPLAINT_REQUEST = "ORDER_COMPLAINT_REQUEST";
export const ORDER_COMPLAINT_SUCCESS = "ORDER_COMPLAINT_SUCCESS";
export const ORDER_COMPLAINT_FAIL = "ORDER_COMPLAINT_FAIL";
