import { useSelector } from "react-redux";
import parse from "html-react-parser";
import CalltoActionSection from "../home-components/CalltoActionSection";

export default function ContactMain() {
  const settingsData = useSelector((state) => state.settings);
  const { theme, contactPage } = settingsData;

  return (
    <>
      <div className="container gap-2 py-4">
        <div className="d-block d-md-flex d-lg-flex contact-main">
          <iframe
            className="w-75"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d93290.02186811354!2d21.927424!3d43.05588029999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2srs!4v1719170870989!5m2!1sen!2srs"
            // width="75%"
            height="500"
            loading="lazy"
          ></iframe>
          <div className={`contact-data w-25 bg-${theme}-100 p-3`}>
            <div className="mb-4">
              <h4 className={`text-${theme}-500 px-2`}>
                <i className="fal fa-map-marker-alt"></i>
              </h4>
              {parse(contactPage?.contactAddress || "")}
            </div>
            <div className="mb-4">
              <h4 className={`text-${theme}-500 px-2`}>
                <i className="fal fa-phone"></i>
              </h4>
              {parse(contactPage?.contactPhone || "")}
            </div>
            <div className="mb-4">
              <h4 className={`text-${theme}-500 px-2`}>
                <i className="fal fa-envelope"></i>
              </h4>
              {parse(contactPage?.email || "")}
            </div>
            <div className="mb-4">
              <h4 className={`text-${theme}-500 px-2`}>
                <i className="fal fa-clock"></i>
              </h4>
              {parse(contactPage?.workingHours || "")}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <CalltoActionSection />
      </div>
    </>
  );
}
