import React from "react";
import BlogCard from "./BlogCard";
import { useSelector } from "react-redux";

export default function BlogGrid({ blogs }) {
  const blogPreviewState = useSelector((state) => state.blogPreview);
  const { preview } = blogPreviewState;

  return (
    <div
      className={`product-grid-wrapper mb-4 ${
        preview === "card" ? "full-width" : ""
      }`}
    >
      {blogs && blogs.map((blog) => <BlogCard key={blog._id} blog={blog} />)}
    </div>
  );
}
