import {
  TODO_ADD_FAIL,
  TODO_ADD_REQUEST,
  TODO_ADD_SUCCESS,
  TODO_DELETE_FAIL,
  TODO_DELETE_REQUEST,
  TODO_DELETE_SUCCESS,
  TODO_DONE_FAIL,
  TODO_DONE_REQUEST,
  TODO_DONE_SUCCESS,
  TODO_LIST_PENDING_FAIL,
  TODO_LIST_PENDING_REQUEST,
  TODO_LIST_PENDING_SUCCESS,
  TODO_LIST_DONE_FAIL,
  TODO_LIST_DONE_REQUEST,
  TODO_LIST_DONE_SUCCESS,
} from "../Constants/ToDoConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET PENDING TODOS
export const listPendingTodo =
  ({ pageNumber, keyword }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: TODO_LIST_PENDING_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `todo/all?pageNumber=${pageNumber}&keyword=${keyword}&done=${false}`,
        config
      );

      dispatch({ type: TODO_LIST_PENDING_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: TODO_LIST_PENDING_FAIL,
        payload: message,
      });
    }
  };

// GET TODOS
export const listDoneTodo =
  ({ pageNumber, keyword, toShow }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: TODO_LIST_DONE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `todo/all?pageNumber=${pageNumber}&keyword=${keyword}&done=${true}&toShow=${toShow}`,
        config
      );

      dispatch({ type: TODO_LIST_DONE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: TODO_LIST_DONE_FAIL,
        payload: message,
      });
    }
  };

// ADD TODO
export const addTodo = (product) => async (dispatch, getState) => {
  try {
    dispatch({ type: TODO_ADD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await _axios.post(`todo/add`, { ...product }, config);

    dispatch({ type: TODO_ADD_SUCCESS, payload: data });
    dispatch(listPendingTodo({ pageNumber: 1, keyword: "", toShow: 12 }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: TODO_ADD_FAIL,
      payload: message,
    });
  }
};

// REPLACE TODO
export const replaceTodos = (products) => async (dispatch, getState) => {
  try {
    dispatch({ type: TODO_ADD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await _axios.post(`todo/replace`, { products }, config);

    dispatch({ type: TODO_ADD_SUCCESS, payload: data });
    dispatch(listPendingTodo({ pageNumber: 1, keyword: "", toShow: 12 }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: TODO_ADD_FAIL,
      payload: message,
    });
  }
};

// DELETE TODO
export const deleteTodo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TODO_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`todo/${id}`, config);

    dispatch({ type: TODO_DELETE_SUCCESS });
    dispatch(listPendingTodo({ pageNumber: 1, keyword: "", toShow: 12 }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: TODO_DELETE_FAIL,
      payload: message,
    });
  }
};

// DONE TODO
export const doneTodo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TODO_DONE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    await _axios.put(`todo/${id}/done`, config);

    dispatch({ type: TODO_DONE_SUCCESS });
    dispatch(listPendingTodo({ pageNumber: 1, keyword: "", toShow: 12 }));
    dispatch(listDoneTodo({ pageNumber: 1, keyword: "", toShow: 12 }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: TODO_DONE_FAIL,
      payload: message,
    });
  }
};

// UPDATE TODO BUILDING DAYS
export const updateTodo =
  ({ id, daysLeft }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: TODO_ADD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      await _axios.post(`todo/${id}`, { daysLeft }, config);

      dispatch({ type: TODO_ADD_SUCCESS });
      dispatch(listPendingTodo({ pageNumber: 1, keyword: "", toShow: 12 }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: TODO_ADD_FAIL,
        payload: message,
      });
    }
  };
