import React, { useEffect, useState, useRef, useMemo } from "react";
import { io } from "socket.io-client";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import ChatContainer from "../../components/dashboard/chat/ChatContainer";
import Welcome from "../../components/dashboard/chat/Welcome";
import { listUser } from "../../Redux/dashboard/Actions/UserActions";
import Header from "../../components/dashboard/Header";
import Sidebar from "../../components/dashboard/Sidebar";
import Contacts from "../../components/dashboard/chat/Contacts";
import Loading from "../../components/dashboard/loading-error/Loading";
import {
  addChatUser,
  listOnlineUsers,
  removeChatUser,
} from "../../Redux/dashboard/Actions/ChatActions";
import PageWrapper from "../../components/dashboard/common/PageWapper";

export default function Chat() {
  const socket = useRef();
  const [currentChat, setCurrentChat] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const messageData = useSelector((state) => state.messageList);
  // const { loading } = messageData;

  // const newMessagesData = useSelector((state) => state.newMessages);
  // const { loading: newMessagesLoading } = newMessagesData;

  // const newMainMessagesData = useSelector((state) => state.newMainMessages);
  // const { loading: newMainMessagesLoading } = newMainMessagesData;

  // const themeData = useSelector((state) => state.theme);
  // const { theme } = themeData;

  const userListData = useSelector((state) => state.userList);
  const { loading: usersLoading, users } = userListData;

  const onlineUserListData = useSelector((state) => state.onlineUserList);
  const { onlineUsers } = onlineUserListData;

  const onlineUsersIdArray = useMemo(() => {
    const idArray = onlineUsers?.map((user) => user.userId);
    return idArray;
  }, [onlineUsers]);

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  };

  useEffect(() => {
    if (userInfo) {
      socket.current = io("http://localhost:5000");
      dispatch(addChatUser(socket));

      socket.current.on("user-connect-recieve", () => {
        dispatch(listOnlineUsers());
      });

      socket.current.on("user-disconect-recieve", () => {
        dispatch(listOnlineUsers());
      });
    }

    return () => {
      dispatch(removeChatUser(socket));
    };
  }, [userInfo]);

  useEffect(() => {
    if (dispatch) {
      dispatch(listUser({ pageNumber: "all", keyword: "", status: "all" }));
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("ONLINE USERS:::", onlineUsers);
  }, [onlineUsers]);

  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <Container>
          <div className="chat-container-msg px-0 w-100 position-relative">
            <Contacts
              contacts={users}
              changeChat={handleChatChange}
              socket={socket}
              onlineUsers={onlineUsersIdArray}
            />
            {!currentChat && !usersLoading ? (
              <Welcome />
            ) : (
              <ChatContainer
                currentChat={currentChat}
                socket={socket}
                isOnline={onlineUsersIdArray?.includes(currentChat._id)}
              />
            )}
          </div>
          {usersLoading && (
            <Loading position="absolute" width="100%" height="100%" />
          )}
        </Container>
      </PageWrapper>
    </>
  );
}

const Container = styled.div`
  height: calc(100vh - 72px);
  position: relative;
  .chat-container-msg {
    position: relative;
    overflow: hidden;
    height: 100%;
    background-color: #2e2f31;
    display: flex;
  }
`;
