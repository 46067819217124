export const TODO_LIST_PENDING_REQUEST = "TODO_LIST_PENDING_REQUEST";
export const TODO_LIST_PENDING_SUCCESS = "TODO_LIST_PENDING_SUCCESS";
export const TODO_LIST_PENDING_FAIL = "TODO_LIST_PENDING_FAIL";

export const TODO_LIST_DONE_REQUEST = "TODO_LIST_DONE_REQUEST";
export const TODO_LIST_DONE_SUCCESS = "TODO_LIST_DONE_SUCCESS";
export const TODO_LIST_DONE_FAIL = "TODO_LIST_DONE_FAIL";

export const TODO_ADD_REQUEST = "TODO_ADD_REQUEST";
export const TODO_ADD_SUCCESS = "TODO_ADD_SUCCESS";
export const TODO_ADD_FAIL = "TODO_ADD_FAIL";

export const TODO_DELETE_REQUEST = "TODO_DELETE_REQUEST";
export const TODO_DELETE_SUCCESS = "TODO_DELETE_SUCCESS";
export const TODO_DELETE_FAIL = "TODO_DELETE_FAIL";

export const TODO_DONE_REQUEST = "TODO_DONE_REQUEST";
export const TODO_DONE_SUCCESS = "TODO_DONE_SUCCESS";
export const TODO_DONE_FAIL = "TODO_DONE_FAIL";