import {
  TODO_CHECK_REQUEST,
  TODO_CHECK_SUCCESS,
  TODO_CHECK_FAIL,
} from "../Constants/ToDoConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET PENDING TODOS
export const checkTodo =
  ({ numOfDays }) =>
  async (dispatch) => {
    try {
      dispatch({ type: TODO_CHECK_REQUEST });

      const { data } = await _axios.get(`todo/check?numOfDays=${numOfDays}`);

      dispatch({ type: TODO_CHECK_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: TODO_CHECK_FAIL,
        payload: message,
      });
    }
  };
