import React, { useMemo } from "react";
import { useSelector } from "react-redux";

export default function TopTotal({ orders, countProducts, byEmail }) {
  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const cloudinaryUsageData = useSelector((state) => state.cloudinaryUsage);
  const { credits } = cloudinaryUsageData;

  const countSales = () => {
    let totalSale = 0;
    // single seler case
    if (byEmail) {
      orders?.map((order) =>
        order.isPaid === true && order.seller.email === byEmail
          ? (totalSale = totalSale + order.totalPrice)
          : null
      );
      return totalSale.toFixed(0);
    }
    if (userInfo.isAdmin || userInfo.isSuperAdmin) {
      orders?.map((order) =>
        order.status === "Dostavljeno"
          ? (totalSale = totalSale + order.totalPrice)
          : null
      );
      return totalSale.toFixed(0);
    } else if (userInfo.isSuperAdmin) {
      orders?.map((order) =>
        order.isPaid === true
          ? (totalSale = totalSale + order.totalPrice)
          : null
      );
      return totalSale.toFixed(0);
    }
  };

  const countOrders = () => {
    if (byEmail) {
      return orders.filter((order) => order.seller.email === byEmail).length;
    }
    if (userInfo.isAdmin) {
      return orders.filter((order) => order.seller.email === userInfo.email)
        .length;
    } else if (userInfo.isSuperAdmin) {
      return orders.length;
    }
  };

  const usedCreditColor = useMemo(() => {
    if (credits?.used_percent > 50) {
      return "alert-warning";
    } else if (credits?.used_percent > 90) {
      return "alert-info";
    } else {
      return "alert-success";
    }
  }, [credits]);

  // const countProducts = () => {
  //   // products of the user
  //   if (byEmail) {
  //     return products.filter((product) => product.seller.email === byEmail)
  //       .length;
  //   }
  //   if (userInfo.isAdmin) {
  //     return products.filter(
  //       (product) => product.seller.email === userInfo.email
  //     ).length;
  //   } else if (userInfo.isSuperAdmin) {
  //     return products.length;
  //   }
  // };

  return (
    <div className="row justify-content-center">
      {userInfo.isSuperAdmin && (
        <div className="col-lg-3 col-md-6 col-12">
          <div
            className={`card card-body mb-4 shadow-sm ${
              theme === "dark" ? "bg-dark text-light" : ""
            }`}
          >
            <article className="icontext">
              <span className={`icon icon-sm rounded-circle ${usedCreditColor}`}>
                <i className="fal fa-credit-card-front"></i>
              </span>
              <div className="text">
                <h6 className="mb-1">Uskoriscenost kredita na cloud-u</h6>
                <span>{credits?.used_percent} %</span>
              </div>
            </article>
          </div>
        </div>
      )}
      {userInfo.isSuperAdmin && (
        <div className="col-lg-3 col-md-6">
          <div
            className={`card card-body mb-4 shadow-sm ${
              theme === "dark" ? "bg-dark text-light" : ""
            }`}
          >
            <article className="icontext">
              <span className="icon icon-sm rounded-circle alert-primary">
                <i className="text-primary fal fa-usd-circle"></i>
              </span>
              <div className="text">
                <h6 className="mb-1">Ukupna suma porudzbina</h6>{" "}
                <span>{countSales()} RSD</span>
              </div>
            </article>
          </div>
        </div>
      )}
      {userInfo.isSuperAdmin && (
        <div className="col-lg-3 col-md-6">
          <div
            className={`card card-body mb-4 shadow-sm ${
              theme === "dark" ? "bg-dark text-light" : ""
            }`}
          >
            <article className="icontext">
              <span className="icon icon-sm rounded-circle alert-success">
                <i className="text-success fal fa-bags-shopping"></i>
              </span>
              <div className="text">
                <h6 className="mb-1">Ukupan broj porudzbina</h6>
                {orders ? <span>{countOrders()}</span> : <span>0</span>}
              </div>
            </article>
          </div>
        </div>
      )}
      <div className="col-lg-3 col-md-6">
        <div
          className={`card card-body mb-4 shadow-sm ${
            theme === "dark" ? "bg-dark text-light" : ""
          }`}
        >
          <article
            className={`icontext ${
              userInfo.isAdmin ? "justify-content-center" : ""
            }`}
          >
            <span className="icon icon-sm rounded-circle alert-warning">
              <i className="text-warning fas fa-shopping-basket"></i>
            </span>
            <div className="text">
              <h6 className="mb-1">Ukupan broj proizvoda</h6>
              {/* {products ? <span>{countProducts()}</span> : <span>0</span>} */}
              <span>{countProducts}</span>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}
