import React, { useState, useEffect } from "react";
import SortableTree from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import { Resizable } from "re-resizable";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import { useDispatch, useSelector } from "react-redux";
import { listCategories } from "../../../Redux/dashboard/Actions/CategoryActions";

export default function CategoryTree({
  targetCategory,
  setTargetCategory,
}) {
  const [tree, setTree] = useState([]);

  const categoriesData = useSelector((state) => state.categoriesList);
  const { categories } = categoriesData;

  const categoriesUpdate = useSelector((state) => state.categoryEdit);
  const { loading, error } = categoriesUpdate;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  const handleNodeSelect = (e, selectedNode) => {
    e.preventDefault();
    setTargetCategory(selectedNode);
  };

  useEffect(() => {
    if (categories) {
      setTree(categories);
    }
  }, [categories]);

  useEffect(() => {
    dispatch(listCategories({ filtered: true }));
  }, [dispatch]);

  useEffect(() => {
    if (targetCategory) {
      const expandTreeToTargetCategory = (nodes) => {
        return nodes.map(node => {
          if (targetCategory.path.includes(node.path)) {
            node.expanded = true;
          }
          if (node.children) {
            return { ...node, children: expandTreeToTargetCategory(node.children) };
          }
          return node;
        });
      };

      setTree(prevTree => expandTreeToTargetCategory(prevTree));
    }
  }, [targetCategory]);


  return (
    <>
      <div
        className={`flex justify-content-center py-4 ${
          theme === "dark" ? "bg-dark dark-tree" : ""
        }`}
      >
        <div className="relative">
          <Resizable
            defaultSize={{
              width: 450,
              height: 250,
            }}
            maxWidth={"90vw"}
            style={{ border: "1px solid black" }}
            className="p-3"
          >
            <div className="w-100 h-100">
              <SortableTree
                treeData={tree}
                onChange={(treeData) => {
                  setTree(treeData);
                }}
                canDrag={false}
                generateNodeProps={({ node }) => ({
                  buttons: [
                    <input
                      id="select_category"
                      className="form-check-input check-category"
                      type="checkbox"
                      checked={targetCategory?._id === node._id}
                      onChange={() => {}}
                      onInput={(e) => handleNodeSelect(e, node)}
                    />,
                  ],
                })}
              />

              {error && <Message variant="alert-danger">{error}</Message>}
            </div>
          </Resizable>
        </div>
      </div>

      {loading && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
    </>
  );
}
