import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "../common";

export default function BlogCard({ blog, setDeleteId, setShow, setType, setShowEdit }) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <Card key={blog._id} className="relative card-product-grid shadow-sm pt-4">
      <Link
        to="#"
        className={`img-wrap d-flex align-items-center justify-content-center overflow-hidden position-relative  px-3 ${
          theme === "dark" ? "bg-dark" : ""
        }`}
      >
        <img src={blog.image?.src} className="w-100" alt="Blog" />
      </Link>
      <div className="info-wrap">
        <div className="d-flex justify-content-between">
          <Link
            to="#"
            className={`title text-truncate mb-3 ${
              theme === "dark" ? "text-light" : ""
            }`}
          >
            {blog.title}
          </Link>
        </div>
        <div className="row">
          <Link
            to="#"
            className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
            onClick={(e) => {
              e.preventDefault();
              setDeleteId(blog._id);
              setShowEdit(true);
              setType("edit");
            }}
          >
            <i className="fas fa-pen"></i>
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setDeleteId(blog._id);
              setShow(true);
            }}
            className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
          >
            <i className="fas fa-trash-alt"></i>
          </Link>
        </div>
      </div>
    </Card>
  );
}
