import React from "react";
import { useSelector } from "react-redux";

const OrderDetailInfo = (props) => {
  const { order } = props;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className="row mb-5 order-info-wrap">
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-user"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Korisnik</h6>
            <p className="mb-1">
              {order.user?.name} <br />
              <a className={theme === "dark" ? "text-white" : ""} href={`mailto:${order.user?.email || order.shippingAddress.email}`}>{order.user?.email || order.shippingAddress.email}</a>
            </p>
          </div>
        </article>
      </div>
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-truck-moving"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Informacije o porudzbini</h6>
            <p className="mb-1">
              <strong>Puno Ime:</strong> {order.shippingAddress.fullName}
            </p>
            <p className="mb-1">
            <strong>Email:</strong> {order.shippingAddress.email}
            </p>
            <p className="mb-1">
            <strong>Broj telefona:</strong> {order.shippingAddress.phoneNumber}
            </p>
            <p className="mb-1">
            <strong>Dostava:</strong> {order.shippingAddress.country}
            </p>
            <p className="mb-1">
            <strong>Metod placanja:</strong> {order.paymentMethod}
            </p>
          </div>
        </article>
      </div>
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-map-marker-alt"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Dostaviti na</h6>
            <p className="mb-1">
              Adresa: {order.shippingAddress.city}
              <br />
              {order.shippingAddress.address}
              <br /> {order.shippingAddress.postalCode}
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default OrderDetailInfo;
