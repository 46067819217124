import React, { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import {
  addToCart,
  removefromcart,
} from "../../Redux/client/Actions/cartActions";
import { Cart } from "./common/BootstrapIcons";
import Select from "./ui/form/Select";
import Button from "./ui/Button";

export default function CartPopover() {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const dropdownRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const total = cartItems
    .reduce((a, i) => a + i.qty * i.discountPrice || i.price, 0)
    .toFixed(2);

  const history = useHistory();

  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const checkOutHandler = () => {
    document.body.classList.remove("no-scroll");
    history.push("/shipping");
  };

  const removeFromCartHandle = (id) => {
    dispatch(removefromcart(id));
  };

  const handleWindowResize = () => {
    let dropdownElem = document.querySelector(".dropdown-cart-popover");
    if (dropdownElem) {
      const dropdownRect = dropdownElem.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const newLeft =
        dropdownRect.right + 50 > windowWidth
          ? `${-170 - (dropdownRect.right - windowWidth)}px`
          : "-150px";
      dropdownElem.style.left = newLeft;
    }
  };

  const mouseOver = () => {
    const rootHeight = document.getElementById("root").offsetHeight;
    if (rootHeight > window.innerHeight) {
      document.body.classList.add("no-scroll");
    }

    let timeout;
    dropdownRef.current.classList.remove("overflow-hidden");
    clearTimeout(timeout);
    dropdownMenuRef.current.classList.add("cart-popover-show");
    timeout = setTimeout(() => {
      handleWindowResize();
      dropdownMenuRef.current.classList.add("cart-popover-visible");
    }, 0);
  };

  const mouseLeave = () => {
    document.body.classList.remove("no-scroll");
    let timeout;
    handleWindowResize();
    dropdownRef.current.classList.add("overflow-hidden");
    clearTimeout(timeout);
    dropdownMenuRef.current.classList.remove("cart-popover-show");
    timeout = setTimeout(() => {
      dropdownMenuRef.current.classList.remove("cart-popover-visible");
    }, 100);
  };

  return (
    <div
      style={{ minWidth: "50px", minHeight: "70px" }}
      ref={dropdownRef}
      className="dropdown overflow-hidden cart-popover-trigger"
      onMouseEnter={mouseOver}
      onMouseLeave={mouseLeave}
    >
      <div
        className="pointer pt-3 h5 far position-relative m-auto"
        onClick={(e) => history.push("/cart")}
      >
        {/* <i className="h5 mt-4 far position-relative m-auto fa-shopping-cart">
        </i> */}
        <div className="position-relative">
          <Cart width={30} height={30} className={`text-${theme}-500 mt-1`} />
          <span className={`badge bg-${theme}-500`}>{cartItems?.length}</span>
        </div>
      </div>

      <div
        ref={dropdownMenuRef}
        style={{ maxHeight: "702px", minWidth: "300px", left: "-150px" }}
        className="dropdown-menu dropdown-cart-popover rounded-0 shadow bg-white"
      >
        <Scrollbars
          style={{ height: cartItems?.length === 0 ? 160 : 702, width: 300 }}
          autoHide
        >
          {cartItems?.length === 0 ? (
            <div className={`alert bg-${theme}-100 text-center mt-3`}>
              Vasa korpa je prazna
              <Link
                className={`btn bg-${theme} text-white rounded-0 mx-5 px-5 py-3`}
                to="#"
                style={{
                  fontSize: "12px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                IDI NAZAD
              </Link>
            </div>
          ) : (
            <>
              <div
                className={`bg-${theme}-100 alert text-center mt-3`}
              >
                Ukupno artikala u korpi
                <Link className="text-success mx-2 text-gray" to="/cart">
                  ({cartItems?.length})
                </Link>
              </div>
              {/* cartiterm */}
              {cartItems.map((item, index) => (
                <div className="cart-iterm row" key={index}>
                  <div>
                    <button
                      type="button"
                      className={`btn-close shadow-focus-${theme}-100`}
                      aria-label="Close"
                      onClick={() => removeFromCartHandle(item.product)}
                    ></button>
                  </div>
                  <div className="cart-image col-md-12">
                    <img src={item.images[0].src} alt={item.name} />
                  </div>
                  <div className="cart-text flex content-center col-md-12 d-flex align-items-center mt-3">
                    <Link to={`/products/${item.product}`}>
                      <h4 className="text-gray align-center">{item.name}</h4>
                    </Link>
                  </div>
                  <div className="cart-qty col-md-12 mt-md-3 mt-1 mt-md-0 d-flex flex-column justify-content-center">
                    <h6>KOLICINA</h6>
                    <Select
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart({
                            productId: item.product,
                            qty: Number(e.target.value),
                          })
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="mt-3 cart-price col-md-12 d-flex items-center space-between col-sm-12">
                    <h6 className="mb-0">CENA</h6>
                    <h4>{item.discountPrice || item.price} RSD</h4>
                  </div>
                </div>
              ))}

              {/* End of cart iterms */}
              <div className="total">
                <span className="sub">ukupno:</span>
                <span className="total-price">{total} RSD</span>
              </div>
              <hr />
              <div className="cart-buttons my-4 d-flex content-end align-items-center row">
                {total > 0 && (
                  <div className="col-md-6 d-flex justify-content-md-end mt-3 mt-md-0">
                    <Button onClick={(value) => checkOutHandler(value)}>
                      Dalje
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </Scrollbars>
      </div>
    </div>
  );
}
