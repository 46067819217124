import React from "react";
import styled from "styled-components";
import Robot from "../../../assets/robot.gif";
import { useSelector } from "react-redux";

export default function Welcome() {
  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;
  
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <Container className={theme === "dark" ? "bg-dark" : "bg-white"}>
      <img src={Robot} alt="" />
      <h1 className={`text-center px-2 ${theme === "light" ? "text-dark" : ""}`}>
        Dobrodosao-la, <span>{userInfo?.name}!</span>
      </h1>
      <h3 className={`text-center px-2 ${theme === "light" ? "text-dark" : ""}`}>Izaberi sobu i pocni sa dopisivanjem.</h3>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  flex: 1;
  img {
    // height: 20rem;
    width: 30%;
    @media screen and (max-width: 1200px) {
      width: 40%;
    }
    @media screen and (max-width: 720px) {
      width: 90%;
    }
  }
  span {
    color: #4e0eff;
  }
`;
