import {
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  MESSAGE_LIST_FAIL,
  NEW_MESSAGE_LIST_REQUEST,
  NEW_MESSAGE_LIST_SUCCESS,
  NEW_MESSAGE_LIST_FAIL,
  NEW_MAIN_MESSAGE_LIST_REQUEST,
  NEW_MAIN_MESSAGE_LIST_SUCCESS,
  NEW_MAIN_MESSAGE_LIST_FAIL,
  MESSAGE_CREATE_REQUEST,
  MESSAGE_CREATE_SUCCESS,
  MESSAGE_CREATE_FAIL,
  REMOVE_MESSAGE_REQUEST,
  REMOVE_MESSAGE_SUCCESS,
  REMOVE_MESSAGE_FAIL,
  EDIT_MESSAGE_REQUEST,
  EDIT_MESSAGE_SUCCESS,
  EDIT_MESSAGE_FAIL,
  ONLINE_USER_LIST_REQUEST,
  ONLINE_USER_LIST_SUCCESS,
  ONLINE_USER_LIST_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
} from "../Constants/ChatConstants";

// MESSAGE LIST
export const messageListReducer = (state = {}, action) => {
  switch (action.type) {
    case MESSAGE_LIST_REQUEST:
      return { loading: true };
    case MESSAGE_LIST_SUCCESS:
      return { loading: false, success: true, messages: action.payload };
    case MESSAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// NEW MESSAGE LIST
export const newMessageListReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_MESSAGE_LIST_REQUEST:
      return { loading: true };
    case NEW_MESSAGE_LIST_SUCCESS:
      return { loading: false, success: true, messages: action.payload };
    case NEW_MESSAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newMainMessageListReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_MAIN_MESSAGE_LIST_REQUEST:
      return { loading: true };
    case NEW_MAIN_MESSAGE_LIST_SUCCESS:
      return { loading: false, success: true, messages: action.payload };
    case NEW_MAIN_MESSAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE MESSAGE
export const messageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MESSAGE_CREATE_REQUEST:
      return { loading: true };
    case MESSAGE_CREATE_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case MESSAGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// EDIT MESSAGE
export const editMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_MESSAGE_REQUEST:
      return { loading: true };
    case EDIT_MESSAGE_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case EDIT_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE MESSAGE
export const deleteMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_MESSAGE_REQUEST:
      return { loading: true };
    case REMOVE_MESSAGE_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case REMOVE_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ONLINE USERS LIST
export const onlineUsersListReducer = (state = {}, action) => {
  switch (action.type) {
    case ONLINE_USER_LIST_REQUEST:
      return { loading: true };
    case ONLINE_USER_LIST_SUCCESS:
      return { loading: false, success: true, onlineUsers: action.payload };
    case ONLINE_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ADD USER
export const addChatUserReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return { loading: true };
    case ADD_USER_SUCCESS:
      return { loading: false, success: true, onlineUsers: action.payload };
    case ADD_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// REMOVE USER
export const removeChatUserReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_USER_REQUEST:
      return { loading: true };
    case REMOVE_USER_SUCCESS:
      return { loading: false, success: true, onlineUsers: action.payload };
    case REMOVE_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
