import React from "react";
import { useSelector } from "react-redux";

const ProductsStatistics = ({ title }) => {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className="col-xl-6 col-lg-12">
      <div
        className={`card overflow-auto mb-4 shadow-sm ${
          theme === "dark" ? "bg-dark text-light" : ""
        }`}
      >
        <article className="card-body">
          <h5 className="card-title">Najprodavaniji Proizvodi</h5>
          {theme === "dark" ? (
            <iframe
              style={{ minWidth: "700px" }}
              title={title}
              height="353"
              src="https://charts.mongodb.com/charts-mywoodshop-hqcwv/embed/charts?id=64e0bc28-4c49-4cf1-890d-1d31cb9dd885&maxDataAge=3600&theme=dark&autoRefresh=true"
            ></iframe>
          ) : (
            <iframe
              style={{ minWidth: "700px", color: "white" }}
              title={title}
              height="353"
              color="white"
              src="https://charts.mongodb.com/charts-mywoodshop-hqcwv/embed/charts?id=64e0bc28-4c49-4cf1-890d-1d31cb9dd885&maxDataAge=3600&theme=light&autoRefresh=true"
            ></iframe>
          )}
        </article>
      </div>
    </div>
  );
};

export default ProductsStatistics;
