export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_HIGHEST_PRICE_REQUEST = "PRODUCT_HIGHEST_PRICE_REQUEST";
export const PRODUCT_HIGHEST_PRICE_SUCCESS = "PRODUCT_HIGHEST_PRICE_SUCCESS";
export const PRODUCT_HIGHEST_PRICE_FAIL = "PRODUCT_HIGHEST_PRICE_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_FOLLOWER_REQUEST = "PRODUCT_FOLLOWER_REQUEST";
export const PRODUCT_FOLLOWER_SUCCESS = "PRODUCT_FOLLOWER_SUCCESS";
export const PRODUCT_FOLLOWER_FAIL = "PRODUCT_FOLLOWER_FAIL";
export const PRODUCT_FOLLOWER_RESET = "PRODUCT_FOLLOWER_RESET";

export const PRODUCT_LIKE_REQUEST = "PRODUCT_LIKE_REQUEST";
export const PRODUCT_LIKE_SUCCESS = "PRODUCT_LIKE_SUCCESS";
export const PRODUCT_LIKE_FAIL = "PRODUCT_LIKE_FAIL";

export const FAVORITE_LIST_REQUEST = "FAVORITE_LIST_REQUEST";
export const FAVORITE_LIST_SUCCESS = "FAVORITE_LIST_SUCCESS";
export const FAVORITE_LIST_FAIL = "FAVORITE_LIST_FAIL";

export const PRODUCT_PREVIEW = "PRODUCT_PREVIEW";

export const PRODUCT_LIST_HOME_REQUEST = "PRODUCT_LIST_HOME_REQUEST";
export const PRODUCT_LIST_HOME_SUCCESS = "PRODUCT_LIST_HOME_SUCCESS";
export const PRODUCT_LIST_HOME_FAIL = "PRODUCT_LIST_HOME_FAIL";

export const PRODUCT_SEARCH_REQUEST = "PRODUCT_SEARCH_REQUEST";
export const PRODUCT_SEARCH_SUCCESS = "PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_FAIL = "PRODUCT_SEARCH_FAIL";
