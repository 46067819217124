import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import { Section, Card, CardHeader, CardBody } from "../common";
import { editUser } from "../../../Redux/dashboard/Actions/UserActions";
import AvatarComponent from "../common/Avatar";
import Input from "../ui/form/Input";

export default function ProfileMain() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const editUserData = useSelector((state) => state.editUser);
  const {
    loading: userEditLoading,
    error: userEditError,
    userEditInfo,
  } = editUserData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(editUser({ name, email, phone, address, image }));
  };

  const resetHandler = (e) => {
    e.preventDefault();

    setName(userInfo.name);
    setEmail(userInfo.email);
    setPhone(userInfo.phone);
    setAddress(userInfo.address);
    setImage(userInfo.avatarImage.url);
  };

  useEffect(() => {
    if (userInfo || userEditInfo) {
      setName(userEditInfo?.name || userInfo.name);
      setEmail(userEditInfo?.email || userInfo.email);
      setPhone(userEditInfo?.phone || userInfo.phone);
      setAddress(userEditInfo?.address || userInfo.address);
      setImage(userEditInfo?.avatarImage.url || userInfo.avatarImage.url);
    }
  }, [userInfo, userEditInfo]);

  console.log("userInfo:::", userInfo);

  return (
    <>
      <Section>
        <Card className="card-user shadow-sm">
          <CardHeader className="d-flex justify-content-center">
            <AvatarComponent
              setImage={setImage}
              src={userInfo?.avatarImage?.url}
            />
          </CardHeader>
          <CardBody className="pt-5">
            {error && <Message variant="alert-danger">{error}</Message>}
            {loading || userEditLoading ? (
              <Loading
                position="position-absolute"
                width="100%"
                height="100%"
              />
            ) : error || userEditError ? (
              <Message variant="alert-danger">{error || userEditError}</Message>
            ) : (
              <>
                <h5 className="card-title mt-5 pt-5">{userInfo.name}</h5>
                <div className="card-text text-muted">
                  <p>
                    <a
                      className={theme === "dark" ? "text-white" : ""}
                      href={`mailto:${userInfo?.email}`}
                    >
                      {userInfo?.email}
                    </a>
                  </p>
                </div>

                <form className="mt-4" onSubmit={submitHandler}>
                  <div className="row text-start">
                    <div className="mb-4 px-2 col-12 col-md-6 col-lg-6">
                      <Input
                        id="user_name"
                        type="text"
                        label="Ime"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="mb-4 px-2 col-12 col-md-6 col-lg-6">
                      <Input
                        id="user_email"
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row text-start">
                    <div className="mb-4 px-2 col-12 col-md-6 col-lg-6">
                      <Input
                        id="user_phone"
                        type="number"
                        label="Telefon"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="mb-4 px-2 col-12 col-md-6 col-lg-6">
                      <Input
                        id="user_address"
                        type="text"
                        label="Adresa"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-between px-2">
                    <button className="btn btn-warning" onClick={resetHandler}>
                      Resetuj
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Sacuvaj
                    </button>
                  </div>
                </form>
              </>
            )}
          </CardBody>
        </Card>
      </Section>
    </>
  );
}
