import { useEffect, useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Input from "../ui/form/Input";
import { debounce, isEmpty } from "lodash";
import SortableImages from "../common/sortable/SortableImages";
import {
  checkAvailability,
  createCategory,
  editCategory,
} from "../../../Redux/dashboard/Actions/CategoryActions";
import Message from "../loading-error/Error";
import { CHECK_AVAILABILITY_RESET } from "../../../Redux/dashboard/Constants/CategoryConstants";

export default function AddEditCategoryModal(props) {
  const [titleLocal, setTitle] = useState("");
  const [imagesLocal, setImages] = useState([]);
  const [pathLocal, setPath] = useState("");
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [descriptionLocal, setDescription] = useState("");
  const { node, setshow, type, parentCategoryPath } = props;
  const { _id, categoryId, title, description, images, path } = node;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const checkNameData = useSelector((state) => state.checkAvailability);
  const { loading, error, isAvailable } = checkNameData;

  const dispatch = useDispatch();

  const inputRef = useRef();

  const memoizedCheckName = useMemo(() => {
    const checkName = debounce(async (title) => {
      title && dispatch(checkAvailability(title));
    }, 500);

    return checkName;
  }, []);

  const submitHandler = () => {
    if (type === "edit") {
      dispatch(
        editCategory({
          id: categoryId || _id,
          oldTitle: title,
          oldPath: pathLocal,
          title: titleLocal,
          description: descriptionLocal,
          images: imagesLocal,
          imagesToDelete,
        })
      );
    } else {
      dispatch(
        createCategory({
          title: titleLocal,
          description: descriptionLocal,
          images: imagesLocal,
          path: parentCategoryPath
            ? `${parentCategoryPath}/${titleLocal
                .replace(/ /g, "_")
                .toLowerCase()}`
            : titleLocal.replace(/ /g, "_").toLowerCase(),
          parentCategoryPath,
        })
      );
    }

    resetForm();
    setshow(false);
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setImages([]);
    // inputRef?.current.value = null;
    dispatch({ type: CHECK_AVAILABILITY_RESET });
  };

  useEffect(() => {
    if (isEmpty(node)) {
      resetForm();
      return;
    }

    setTitle(title);
    setDescription(description);
    setImages(images);
    setPath(path);
  }, [node]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
      onHide={() => {
        resetForm();
        setshow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          className="mb-4"
          id="category_name"
          type="text"
          label="Naziv kategorije"
          placeholder="Enter ctegory name"
          required={true}
          value={titleLocal}
          sufix={
            loading ? (
              <div
                className="spinner-border text-success"
                role="status"
                style={{ width: "25px", height: "25px" }}
              ></div>
            ) : (
              ""
            )
          }
          onChange={(e) => {
            setTitle(e.target.value);
            type !== "edit" && memoizedCheckName(e.target.value);
          }}
        />

        {isAvailable && !error && (
          <Message variant="alert-success">Naziv je dostupan</Message>
        )}
        {error && <Message variant="alert-danger">{error}</Message>}

        <div className="mb-4">
          <h6 className="mb-2">Slika</h6>
          <SortableImages
            ref={inputRef}
            images={imagesLocal}
            setImages={setImages}
            setImagesToDelete={setImagesToDelete}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="details" className="form-label">
            Opis
          </label>
          <textarea
            placeholder="Opis kategorije"
            className={`form-control ${
              theme === "dark" ? "dark-theme-section" : ""
            }`}
            value={descriptionLocal}
            id="details"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            !titleLocal ||
            isEmpty(imagesLocal) ||
            imagesLocal.length > 1 ||
            !descriptionLocal ||
            error
          }
          onClick={submitHandler}
        >
          Potvrdi
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            resetForm();
            setshow(false);
          }}
        >
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
