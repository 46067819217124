import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productCreateReviewReducer,
  productDetailsReducer,
  clientProductListDiscountReducer,
  highestPriceReducer,
  productFollowerReducer,
  productPreviewReducer,
  clientProductListReducer,
  productSearchReducer,
  productLikeReducer,
  favoriteReducer,
} from "./client/Reducers/ProductReducers";
import { cartReducer } from "./client/Reducers/CartReducers";
import {
  userDetailsReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
  userSendLinkReducer,
  userResetPasswordReducer,
} from "./client/Reducers/userReducers";
import {
  orderCreateReducer,
  clientOrderDetailsReducer,
  orderListMyReducer,
  orderPayReducer,
} from "./client/Reducers/OrderReducers";
import { clientCategoriesListReducer } from "./client/Reducers/CategoryReducers";
import { addEmailReducer } from "./client/Reducers/NewsletterReducers";
import {
  clientBlogListReducer,
  blogPreviewReducer,
} from "./client/Reducers/BlogReducers";
import { todoCheckReducer } from "./client/Reducers/TodoReducers"; 

// DASHBOARD
import { themeReducer } from "./dashboard/Reducers/ThemeReducers";
import {
  createUserReducer,
  userListReducer,
  singleUserReducer,
  // userLoginReducer,
  blockUserReducer,
  removeUserReducer,
  editUserReducer,
} from "./dashboard/Reducers/userReducers";
import {
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
  productSimilarListReducer,
} from "./dashboard/Reducers/ProductReducers";
import {
  orderDeliveredReducer,
  orderDetailsReducer,
  orderListReducer,
  newOrderReducer,
  orderStatusReducer,
  orderComplaintReducer,
} from "./dashboard/Reducers/OrderReducres";
import {
  categoriesListReducer,
  categoryCreateReducer,
  categoryEditReducer,
  categoryUpdateReducer,
  categoryDeleteReducer,
  checkAvailabilityReducer,
} from "./dashboard/Reducers/CategoryReducers";
import {
  brandListReducer,
  brandCreateReducer,
  brandEditReducer,
  brandUpdateReducer,
  brandDeleteReducer,
} from "./dashboard/Reducers/BrandReducers";
import {
  materialListReducer,
  materialCreateReducer,
  materialEditReducer,
  materialUpdateReducer,
  materialDeleteReducer,
} from "./dashboard/Reducers/MaterialReducers";
import {
  blogListReducer,
  blogSingleReducer,
  blogCreateReducer,
  blogUpdateReducer,
  blogDeleteReducer,
} from "./dashboard/Reducers/BlogReducers";
import {
  messageListReducer,
  newMessageListReducer,
  newMainMessageListReducer,
  messageCreateReducer,
  editMessageReducer,
  deleteMessageReducer,
  addChatUserReducer,
  removeChatUserReducer,
  onlineUsersListReducer,
} from "./dashboard/Reducers/ChatReducers";
import {
  sellersListReducer,
  sellerCreateReducer,
  sellerEditReducer,
  sellerUpdateReducer,
  sellerDeleteReducer,
} from "./dashboard/Reducers/SellerReducers";
import { sendMailReducer } from "./dashboard/Reducers/postReducers";
import {
  commissionListReducer,
  commissionCreateReducer,
  commissionDeleteReducer,
} from "./dashboard/Reducers/CommissionReducers";

import {
  categoryRequestListReducer,
  categoryRequestCreateReducer,
  categoryRequestAproveReducer,
  categoryRequestRejectReducer,
} from "./dashboard/Reducers/CategoryRequestReducers";

import {
  todoListPendingReducer,
  todoListDoneReducer,
  todoAddReducer,
  todoDeleteReducer,
  todoDoneReducer,
} from "./dashboard/Reducers/TodoReducers";
import { listEmailReducer } from "./dashboard/Reducers/NewsletterReducers";
import { settingsReducer } from "./dashboard/Reducers/SettingsReducers";
import { cloudinaryUsageReducer } from "./dashboard/Reducers/CloudinaryReducers";

const reducer = combineReducers({
  clientProductList: clientProductListReducer,
  clientProductListDiscount: clientProductListDiscountReducer,
  productSearch: productSearchReducer,
  productLike: productLikeReducer,
  favorite: favoriteReducer,
  highestPrice: highestPriceReducer,
  clientCategoriesList: clientCategoriesListReducer,
  productDetails: productDetailsReducer,
  productReviewCreate: productCreateReviewReducer,
  productFollower: productFollowerReducer,
  productPreview: productPreviewReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userSendLink: userSendLinkReducer,
  userResetPassword: userResetPasswordReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  orderCreate: orderCreateReducer,
  clientOrderDetails: clientOrderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  settings: settingsReducer,
  addEmail: addEmailReducer,
  clientBlogList: clientBlogListReducer,
  blogPreview: blogPreviewReducer,
  todoCheck: todoCheckReducer,

  // DASHBOARD
  theme: themeReducer,
  // userLogin: userLoginReducer,
  createUser: createUserReducer,
  blockUser: blockUserReducer,
  removeUser: removeUserReducer,
  editUser: editUserReducer,
  userList: userListReducer,
  singleUser: singleUserReducer,
  productList: productListReducer,
  productSimilarList: productSimilarListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  orderDeliver: orderDeliveredReducer,
  orderStatus: orderStatusReducer,
  orderComplaint: orderComplaintReducer,
  categoriesList: categoriesListReducer,
  categoryCreate: categoryCreateReducer,
  categoryEdit: categoryEditReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDelete: categoryDeleteReducer,
  checkAvailability: checkAvailabilityReducer,
  brandList: brandListReducer,
  brandCreate: brandCreateReducer,
  brandEdit: brandEditReducer,
  brandUpdate: brandUpdateReducer,
  brandDelete: brandDeleteReducer,
  materialList: materialListReducer,
  materialCreate: materialCreateReducer,
  materialEdit: materialEditReducer,
  materialUpdate: materialUpdateReducer,
  materialDelete: materialDeleteReducer,
  blogList: blogListReducer,
  blogSingle: blogSingleReducer,
  blogDelete: blogDeleteReducer,
  blogCreate: blogCreateReducer,
  blogUpdate: blogUpdateReducer,
  sellersList: sellersListReducer,
  commissionList: commissionListReducer,
  commissionCreate: commissionCreateReducer,
  commissionDelete: commissionDeleteReducer,
  sellerCreate: sellerCreateReducer,
  sellerEdit: sellerEditReducer,
  sellerUpdate: sellerUpdateReducer,
  sellerDelete: sellerDeleteReducer,
  sendMail: sendMailReducer,
  newOrders: newOrderReducer,
  messageList: messageListReducer,
  newMessages: newMessageListReducer,
  newMainMessages: newMainMessageListReducer,
  messageCreate: messageCreateReducer,
  editMessage: editMessageReducer,
  deleteMessage: deleteMessageReducer,
  onlineUserList: onlineUsersListReducer,
  addChatUser: addChatUserReducer,
  removeChatUser: removeChatUserReducer,
  categoryRequestList: categoryRequestListReducer,
  categoryRequestCreate: categoryRequestCreateReducer,
  categoryRequestAprove: categoryRequestAproveReducer,
  categoryRequestReject: categoryRequestRejectReducer,
  todoListPending: todoListPendingReducer,
  todoListDone: todoListDoneReducer,
  todoAdd: todoAddReducer,
  todoDelete: todoDeleteReducer,
  todoDone: todoDoneReducer,
  listEmail: listEmailReducer,
  cloudinaryUsage: cloudinaryUsageReducer,
});

const cartItemsFromLocalStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

// login
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

// shippingAddress
const shippingAddressFromLocalStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

// product preview
const productPreviewFromLocalStorage = localStorage.getItem("productPreview")
  ? localStorage.getItem("productPreview")
  : "grid";

// product preview
const blogPreviewFromLocalStorage = localStorage.getItem("blogPreview")
  ? localStorage.getItem("blogPreview")
  : "grid";

// settings
const settingsFromLocalStorage = localStorage.getItem("settings")
  ? localStorage.getItem("settings")
  : { theme: "orange" };

// dashboard theme
const themeFromLocalStorage = localStorage.getItem("theme")
  ? JSON.parse(localStorage.getItem("theme"))
  : "dark";

const initialState = {
  cart: {
    cartItems: cartItemsFromLocalStorage,
    shippingAddress: shippingAddressFromLocalStorage,
  },
  userLogin: { userInfo: userInfoFromLocalStorage },
  productPreview: { preview: productPreviewFromLocalStorage },
  blogPreview: { preview: blogPreviewFromLocalStorage },
  settings: settingsFromLocalStorage,
  theme: { theme: themeFromLocalStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
