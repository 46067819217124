export const CATEGORY_REQUEST_LIST_REQUEST = "CATEGORY_REQUEST_LIST_REQUEST";
export const CATEGORY_REQUEST_LIST_SUCCESS = "CATEGORY_REQUEST_LIST_SUCCESS";
export const CATEGORY_REQUEST_LIST_FAIL = "CATEGORY_REQUEST_LIST_FAIL";

export const CATEGORY_REQUEST_CREATE_REQUEST = "CATEGORY_REQUEST_CREATE_REQUEST";
export const CATEGORY_REQUEST_CREATE_SUCCESS = "CATEGORY_REQUEST_CREATE_SUCCESS";
export const CATEGORY_REQUEST_CREATE_FAIL = "CATEGORY_REQUEST_CREATE_FAIL";
export const CATEGORY_REQUEST_CREATE_RESET = "CATEGORY_REQUEST_CREATE_RESET";

export const CATEGORY_REQUEST_APROVE_REQUEST = "CATEGORY_REQUEST_APROVE_REQUEST";
export const CATEGORY_REQUEST_APROVE_SUCCESS = "CATEGORY_REQUEST_APROVE_SUCCESS";
export const CATEGORY_REQUEST_APROVE_FAIL = "CATEGORY_REQUEST_APROVE_FAIL";

export const CATEGORY_REQUEST_REJECT_REQUEST = "CATEGORY_REQUEST_REJECT_REQUEST";
export const CATEGORY_REQUEST_REJECT_SUCCESS = "CATEGORY_REQUEST_REJECT_SUCCESS";
export const CATEGORY_REQUEST_REJECT_FAIL = "CATEGORY_REQUEST_REJECT_FAIL";