import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import MainMaterials from "../../components/dashboard/materials/MainMaterials";
import PageWrapper from "../../components/dashboard/common/PageWapper";

export default function MaterialScreen() {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <MainMaterials />
      </PageWrapper>
    </>
  );
}
