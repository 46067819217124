import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const FollowersTable = ({ followers, onDeleteFollower }) => {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <>
      <h4 className="text-center mt-5">PRATIOCI</h4>
      <table
        className={`table ${theme === "dark" ? "bg-dark text-light" : ""}`}
      >
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Zeljena cena</th>
            <th scope="col">Datum</th>
            <th scope="col" className="text-end">
              Akcije
            </th>
          </tr>
        </thead>
        <tbody>
          {followers &&
            followers
              .sort((a, b) => b.price - a.price)
              .map((follower) => (
                <tr
                  className={`${theme === "dark" ? "dark-theme-hover" : ""}`}
                  key={follower._id}
                >
                  <td>
                    <b>{follower.email}</b>
                  </td>
                  <td>{follower.price} RSD</td>
                  <td>{moment(follower.createdAt).format("MMM Do YY")}</td>
                  <td className="d-flex justify-content-end align-item-center">
                    <button
                      className="btn btn-outline text-danger"
                      onClick={() => onDeleteFollower(follower._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default FollowersTable;
