import {
  EMAIL_ADD_REQUEST,
  EMAIL_ADD_SUCCESS,
  EMAIL_ADD_FAIL,
} from "../Constants/NewsletterConstants";
import _axios from "_axios";
import { logout } from "./UserActions";

// CREATE ORDER
export const addEmail =
  ({ email }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: EMAIL_ADD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.post(`/post/newsletter/add`, { email }, config);

      dispatch({ type: EMAIL_ADD_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      
      dispatch({
        type: EMAIL_ADD_FAIL,
        payload: message,
      });
    }
  };
