import React from "react";
import Header from "../../components/dashboard/Header";
import Main from "../../components/dashboard/home/Main";
import Sidebar from "../../components/dashboard/Sidebar";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const HomeScreen = () => {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <Main />
      </PageWrapper>
    </>
  );
};

export default HomeScreen;
