import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import CategoryRequests from "../../components/dashboard/category-requests/CategoryRequests";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const CategoryRequestsScreen = () => {

  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <CategoryRequests />
      </PageWrapper>
    </>
  );
};

export default CategoryRequestsScreen;
