import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function NotSigninedModal({ show, setShow }) {
  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>Trebate biti prijavljeni za ovu aktivnost.</p>
        <div className="d-flex justify-content-center">
          <a href="/login" className="text-primary">Prijava</a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
