import React from "react";
import { Card, CardBody } from "../common";
import { Section } from "../common";
import CreateMaterial from "./CreateMaterial";
import MaterialsTable from "./MaterialsTable";
import { useSelector } from "react-redux";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";

export default function MainMaterials() {
  const materialData = useSelector((state) => state.materialList);
  const { loading, error } = materialData;

  const materialAddData = useSelector((state) => state.materialCreate);
  const { loading: loadingAdd, error: errorAdd } = materialAddData;

  const materialEditData = useSelector((state) => state.materialEdit);
  const { loading: loadingEdit, error: errorEdit } = materialEditData;

  const brandsDeleteData = useSelector((state) => state.materialDelete);
  const { loading: loadingDelete, errorDelete } = brandsDeleteData;

  return (
    <Section className="position-relative">
      <div className="content-header">
        <h2 className="content-title">Materijali</h2>
      </div>

      <Card section className="shadow-sm">
        <CardBody>
          <CreateMaterial />
          {error || errorAdd || errorEdit || errorDelete ? (
            <Message variant="alert-danger">
              {error || errorAdd || errorEdit || errorDelete}
            </Message>
          ) : (
            <MaterialsTable />
          )}
        </CardBody>
      </Card>
      {(loading || loadingAdd || loadingEdit || loadingDelete) && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
    </Section>
  );
}
