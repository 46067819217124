import React from "react";
import Header from "../../components/client/Header";
import ShopMain from "../../components/client/shop-components/ShopMain";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/client/Footer";

const SellerShopScreen = () => {
  const location = useLocation();
  let { sellerId } = useParams();
  
  window.scrollTo(0, 0);
  
  return (
    <>
      <Header />
      <ShopMain sellerId={sellerId} keywordProp={location.keywordProp} />
      <Footer />
    </>
  );
};

export default SellerShopScreen;
