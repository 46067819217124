import {
  EMAIL_ADD_REQUEST,
  EMAIL_ADD_SUCCESS,
  EMAIL_ADD_FAIL,
} from "../Constants/NewsletterConstants";


// ADD EMAIL
export const addEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_ADD_REQUEST:
      return { loading: true };
    case EMAIL_ADD_SUCCESS:
      return { loading: false, success: action.payload };
    case EMAIL_ADD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
