import React from "react";

const Loading = ({ position, width, height, w, h }) => {
  return (
    <div
      style={{ backgroundColor: "rgb(0 0 0 / 10%)", width: width || '100vw', height: height || '100vh', zIndex: '999' }}
      className={`d-flex ${position ? position : "position-fixed"} top-0 left-0 justify-content-center align-items-center`}
    >
      <div
        className="spinner-border text-success"
        role="status"
        style={{ width: `${w || 50}px`, height: `${h || 50}px` }}
      >
        <span className="sr-only">Ucitavanje...</span>
      </div>
    </div>
  );
};

export default Loading;
