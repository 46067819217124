import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "react-css-styled";
import Toast from "../loading-error/Toast";
import { toast } from "react-toastify";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import {
  editSeller,
  updateSeller,
} from "../../../Redux/dashboard/Actions/SellerActions";
import { SELLER_UPDATE_RESET } from "../../../Redux/dashboard/Constants/SellerConstants";

const EditSellerWrapper = styled(
  "div",
  `
  {
    margin: 3%;
  }
  `
);

const EditSeller = ({ sellerId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const sellerEdit = useSelector((state) => state.sellerEdit);
  const { loading, error, seller } = sellerEdit;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    theme,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateSeller({ _id: sellerId, name, email, description })
    );
  };

  const sellerUpdate = useSelector((state) => state.sellerUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = sellerUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SELLER_UPDATE_RESET });
      toast.success("Prodavac je izmenjen", ToastObjects);
    } else {
      if (!seller?.name || seller?._id !== sellerId) {
        dispatch(editSeller(sellerId));
      } else {
        setName(seller.name);
        setEmail(seller.email);
        setDescription(seller.description);
      }
    }
  }, [seller, dispatch, sellerId, successUpdate]);


  return (
    <>
      <Toast />

      {errorUpdate && <Message variant="alert-danger">{errorUpdate}</Message>}
      {loadingUpdate && <Loading />}
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <EditSellerWrapper>
          <form onSubmit={submitHandler}>
            <div className="mb-4">
              <label htmlFor="seller_name" className="form-label">
                Ime
              </label>
              <input
                type="text"
                placeholder="Kucaj ovde"
                className="form-control py-3"
                id="seller_name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="seller_email" className="form-label">
                Email
              </label>
              <input
                type="text"
                placeholder="Kucaj ovde"
                className="form-control py-3"
                id="seller_email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">Description</label>
              <textarea
                placeholder="Kucaj ovde"
                className="form-control"
                rows="4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="d-grid mt-3">
              <button className="btn btn-primary py-3">Update seller</button>
            </div>
          </form>
        </EditSellerWrapper>
      )}
    </>
  );
};

export default EditSeller;
