import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  listSellers,
  deleteSeller,
} from "../../../Redux/dashboard/Actions/SellerActions";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import Pagination from "../common/Pagination";
import { isEmpty } from "lodash";

const SellersTable = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const keyword = '';
  
  const sellersData = useSelector((state) => state.sellersList);
  const { loading, error, sellers } = sellersData;

  const dispatch = useDispatch();

  const deletehandler = (id) => {
    if (window.confirm("Are you sure??")) {
      dispatch(deleteSeller(id));
    }
  };

  const submitHandler = (pageNumber, keyword) => {
    dispatch(listSellers({ pageNumber, keyword }));
  };

  useEffect(() => {
    if (sellers) {
      const { page, pages } = sellers;
      setPage(page);
      setPages(pages);
    }
  }, [sellers]);
  
  useEffect(() => {
    submitHandler(1, "");
  }, [dispatch]);

  return (
    <div style={{overflow: 'auto'}} className="col-md-12 col-lg-12 mt-3">
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <table style={{minWidth: '600px'}} className="table">
          <thead>
            <tr>
              <th>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </th>
              <th>Ime</th>
              <th>Email</th>
              <th>Telefon</th>
              <th>Adresa</th>
              <th>Procenat</th>
              <th className="text-end">Akcije</th>
            </tr>
          </thead>
          {/* Table Data */}
          <tbody>
            {sellers &&
              !isEmpty(sellers.sellers) &&
              sellers.sellers.map((seller, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                      />
                    </div>
                  </td>
                  <td>
                    <b>{seller.name}</b>
                  </td>
                  <td>
                    <b>{seller.email}</b>
                  </td>
                  <td>{seller.phone}</td>
                  <td>{seller.address}</td>
                  <td>{seller.amount}</td>
                  <td className="text-end">
                    <div className="dropdown">
                      <Link
                        to="#"
                        data-bs-toggle="dropdown"
                        className="btn btn-light"
                      >
                        <i className="fas fa-ellipsis-h"></i>
                      </Link>
                      <div className="dropdown-menu">
                        <Link
                          className="btn"
                          to={`/seller-edit-info/${seller._id}`}
                        >
                          Izmeni
                        </Link>
                        <Link
                          className="dropdown-item text-danger"
                          to="#"
                          onClick={() => deletehandler(seller._id)}
                        >
                          Obrisi
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <Pagination 
        page={page} 
        pages={pages} 
        keyword={keyword}
        submitHandler={submitHandler} 
      />
    </div>
  );
};

export default SellersTable;
