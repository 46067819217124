import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import CenteredModal from "./Modal";
import PopoverDetails from "./Popover";
import { useSelector } from "react-redux";

const RequestsTable = ({ requests, aproveHandler, rejectHandler }) => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [submithandler, setSubmithandler] = useState(false);
  const [reqid, setReqid] = useState(false);

  const themeData = useSelector(state => state.theme);
  const { theme } = themeData;

  // const ToastObjects = {
  //   pauseOnFocusLoss: false,
  //   draggable: false,
  //   pauseOnHover: false,
  //   autoClose: 2000,
  // };

  return (
    <div className="requests-table">
      <table className={`table ${theme === "dark" ? "bg-dark text-white" : ""}`}>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">In Category</th>
            <th scope="col">Path</th>
            <th scope="col">New Category</th>
            <th scope="col">Products number</th>
            <th scope="col">Additional</th>
            <th scope="col">Date</th>
            <th scope="col" className="text-end">
              Status
            </th>
            {aproveHandler && rejectHandler && (
              <th scope="col" className="text-end">
                Actions
              </th>
            )}
            {!aproveHandler && !rejectHandler && (
              <th scope="col" className="text-end">
                Details
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {requests?.map((request) => (
            <tr
              className={`${
                request.aproved
                  ? "bg-success bg-opacity-50"
                  : request.rejected
                  ? "bg-danger bg-opacity-50"
                  : ""
              } ${theme === "dark" ? "dark-theme-hover" : ""}`}
              key={request._id}
            >
              <td>{request.seller.name}</td>
              <td>{request.seller.email}</td>
              <td>{request.targetCategory.title}</td>
              <td>{request.targetCategory.path.replace("-", " > ")}</td>
              <td>{request.newCategoryName}</td>
              <td>{request.productsNumber}</td>
              <td>{request.additionalRequests}</td>
              <td>{moment(request.createdAt).format("MMM Do YY")}</td>
              <td>
                {request.aproved
                  ? "Approved"
                  : request.rejected
                  ? "Rejected"
                  : <span className="text-warning">Pending</span>}
              </td>
              {aproveHandler && rejectHandler && (
                <td className="">
                  <div className="dropdown">
                    <Link
                      to="#"
                      data-bs-toggle="dropdown"
                      className={`btn ${theme === "dark" ? "btn-dark" : "btn-light"}`}
                    >
                      <i className="fas fa-ellipsis-h"></i>
                    </Link>
                    <div className={`dropdown-menu ${theme === "dark" ? "bg-dark shadow text-white" : ""}`}>
                      <Link
                        className={`dropdown-item ${theme === "dark" ? "dark-theme-hover text-white" : ""}`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setModalTitle('Approve Request');
                          setModalShow(true);
                          setReqid(request._id);
                          setSubmithandler({ call: aproveHandler });
                        }}
                      >
                        Approve
                      </Link>
                      <Link
                        className={`dropdown-item text-danger ${theme === "dark" ? "dark-theme-hover" : ""}`}
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setModalTitle('Reject Request');
                          setModalShow(true);
                          setReqid(request._id);
                          setSubmithandler({ call: rejectHandler });
                        }}
                      >
                        Reject
                      </Link>
                    </div>
                  </div>
                </td>
              )}
              {!aproveHandler && !rejectHandler && (
                <td>
                  <PopoverDetails title={"Details"} content={request.details} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <CenteredModal
        title={modalTitle}
        submithandler={submithandler}
        showDetails={true}
        reqid={reqid}
        setshow={setModalShow}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default RequestsTable;
