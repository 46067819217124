import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, CardList } from "../common/BootstrapIcons";
import { blogPreview } from "../../../Redux/client/Actions/BlogActions";
import Select from "../ui/form/Select";

export default function BlogFilters({ submitHandler }) {
  const dispatch = useDispatch();
  const blogList = useSelector((state) => state.blogList);
  const { page, prevKeyword, toShow, sortBy } = blogList;

  const blogPreviewState = useSelector((state) => state.blogPreview);
  const { preview } = blogPreviewState;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const blogPreviewFunc = (value) => {
    dispatch(blogPreview(value));
  };

  return (
    <header className="py-0 bg-white mb-3 site-bg">
      <div
        className={`py-3 site-bg
          d-flex flex-lg-row flex-md-row flex-column`}
      >
        <div className="col-lg-4 col-md-6 p-0 me-auto mb-md-0 mb-lg-0 mb-3 d-flex align-content-center">
          <div className="d-flex align-items-center">
            <div
              style={{ paddingRight: "5px" }}
              className="card-display"
              onClick={() => blogPreviewFunc("grid")}
            >
              <Grid
                className={`${preview === "grid" ? `text-${theme}-500` : ""}`}
                width={20}
                height={20}
              />
            </div>
            <div
              style={{ borderLeft: "1px solid #ccc", paddingLeft: "5px" }}
              className="card-display"
              onClick={() => blogPreviewFunc("card")}
            >
              <CardList
                className={`${preview === "card" ? `text-${theme}-500` : ""}`}
                width={25}
                height={25}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12 d-flex gap-1">
          <div className="flex-grow-1">
            <Select
              inputClass={`form-select p-2`}
              onChange={(e) =>
                submitHandler({
                  pageNumber: page,
                  keyword: prevKeyword,
                  toShow: Number.parseInt(e.target.value),
                  sortBy,
                })
              }
              >
              <option>6</option>
              <option>12</option>
              <option>24</option>
              <option>36</option>
            </Select>
          </div>
          <div className="flex-grow-1">
            <Select
              inputClass={`form-select p-2`}
              onChange={(e) =>
                submitHandler({
                  pageNumber: page,
                  keywortd: prevKeyword,
                  toShow,
                  sortBy: e.target.value,
                })
              }
            >
              <option value="Latest added">Sortiraj od najnovijeg</option>
              <option value="Previous added">Sortiraj od najstarijeg</option>
            </Select>
          </div>
        </div>
      </div>
    </header>
  );
}
