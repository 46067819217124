export function Grid({ color, width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color ? color : "currentColor"}
      className={`"bi bi-grid-3x3-gap-fill" ${className}`}
      viewBox="0 0 16 16"
    >
      <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
    </svg>
  );
}

export function CardList({ color, width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color ? color : "currentColor"}
      style={{ marginTop: "3px" }}
      className={`"bi bi-list-ul" ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
}

export function Menu({ color, width, height }) {
  return (
    <svg
      style={{ marginBottom: "5px", marginRight: "3px" }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color ? color : "currentColor"}
      className="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
      />
    </svg>
  );
}

export function Home({ color, width, height, className }) {
  return (
    <svg
      style={{ marginBottom: "7px", marginRight: "3px" }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={`${color ? color : "currentColor"}`}
      className={`bi bi-house-fill ${className}`}
      viewBox="0 0 16 16"
    >
      <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z" />
      <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z" />
    </svg>
  );
}

export function Phone({ color, width, height, className }) {
  return (
    <svg
      style={{ marginTop: "2px", marginRight: "3px" }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={`${color ? color : "currentColor"}`}
      className={`bi bi-telephone-fill ${className}`}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
      />
    </svg>
  );
}

export function Email({ color, width, height, className }) {
  return (
    <svg
      style={{ marginTop: "3px", marginRight: "5px" }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={`${color ? color : "currentColor"}`}
      className={`bi bi-envelope-fill ${className}`}
      viewBox="0 0 16 16"
    >
      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
    </svg>
  );
}

export function Corner({ color, width, height, style }) {
  return (
    <svg
      style={{ ...style }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3750 3751"
      width={width}
      height={height}
      fill={color}
    >
      <path
        id="Layer"
        fillRule="evenodd"
        d="m129.4 238.9c-13.8 0-25-11.2-25-25 0-13.8 11.2-25 25-25h2790.1c13.8 0 25 11.2 25 25v239.8h164.8v-319.5c0-13.8 11.2-25 25-25h486.3c13.8 0 25 11.2 25 25v486.3c0 13.8-11.2 25-25 25h-321.8v159.2h236.5c13.8 0 25 11.2 25 25v2790.1c0 13.8-11.2 25-25 25-13.8 0-25-11.2-25-25v-2765.1h-211.5v2421.1c0 8.7-7 15.7-15.6 15.7-8.6 0-15.6-7-15.6-15.7v-2421.1h-347.3c-7.2 0-13.8-3.1-18.3-8-4.7-4.5-7.5-10.9-7.5-17.8v-344h-2417.2c-8.6 0-15.6-6.9-15.6-15.6 0-8.6 7-15.6 15.6-15.6h2417.2v-214.8zm2815 246v319.8h323.2v-159.2h-133.3c-13.8 0-25-11.2-25-25v-135.6zm214.8-31.2h124.3c8.6 0 15.6 7 15.6 15.6q0 1.8-0.3 3.4v122.8h296.7v-436.3h-436.3zm108.3 31.2h-108.3v110.6h108.3z"
      />
    </svg>
  );
}

export function Avatar({ color, width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={`${color ? color : "currentColor"}`}
      className={`bi bi-envelope-fill ${className}`}
      viewBox="0 0 512 512"
    >
      <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM256 272c39.8 0 72-32.2 72-72s-32.2-72-72-72s-72 32.2-72 72s32.2 72 72 72z" />
    </svg>
  );
}

export function Cart({ color = "currentColor", width, height, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      id="cart"
      viewBox="30 35 150 150"
      className={className}
    >
      <path d="M75 71.09v-10c0-14.28 11.15-25.93 25-25.93s25 11.65 25 25.93v10h17.28A3.13 3.13 0 0 1 145.4 74l5.94 87.5a3.14 3.14 0 0 1-2.91 3.33H51.78a3.12 3.12 0 0 1-3.12-3.12v-.21L54.6 74a3.13 3.13 0 0 1 3.12-2.92Zm9.38 0h31.25v-10c0-9.19-7-16.56-15.63-16.56S84.38 51.9 84.38 61.09Zm-25.91 84.38h83.06l-5.08-75H63.56Z"></path>
    </svg>
  );
}
