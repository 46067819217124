import React from "react";
import { Link } from "react-router-dom";

const Commissions = ({ commissions, forwardHandler, deleteHandler }) => {
  
  console.log("commissions:::", commissions);


  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Seller Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
          <th scope="col">Total</th>
          <th>Status</th>
          <th scope="col" className="text-end">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {commissions?.map((commission) => (
          <tr key={commission._id}>
            <td>{commission.name}</td>
            <td>{commission.email}</td>
            <td>{commission.phone}</td>
            <td>{commission.commission?.total} RSD</td>
            <td>
              {commission.commission?.status === "Paid" ? (
                <span className="badge btn-success bg-green">Paid</span>
              ) : commission.commission?.status === "pending" ? (
                <span className="badge btn bg-yellow text-dark">Pending</span>
              ) : (
                <span className="badge btn-red bg-red">Not Paid</span>
              )}
            </td>
            <td className="text-end">
              <div className="dropdown">
                <Link
                  to="#"
                  data-bs-toggle="dropdown"
                  className="btn btn-light"
                >
                  <i className="fas fa-ellipsis-h"></i>
                </Link>
                <div className="dropdown-menu">
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => forwardHandler(commission)}
                  >
                    Forward
                  </Link>
                  <Link
                    className="dropdown-item text-danger"
                    to="#"
                    onClick={() => deleteHandler(commission._id)}
                  >
                    Delete
                  </Link>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Commissions;
