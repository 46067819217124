import _axios from "_axios";
import {
  BLOG_PREVIEW,
} from "../Constants/BlogConstants";

// BLOG PREVIEW
export const blogPreview = (preview) => async (dispatch) => {
  dispatch({ type: BLOG_PREVIEW, payload: preview });
  localStorage.setItem("blogPreview", preview);
};
