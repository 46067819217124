import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import EditSeller from "../../components/dashboard/sellers/EditSeller";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const SellerEditScreen = ({ match }) => {
  const sellerId = match.params.id;
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <EditSeller sellerId={sellerId} />
      </PageWrapper>
    </>
  );
};
export default SellerEditScreen;
