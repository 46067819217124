import { useDispatch, useSelector } from "react-redux";
import SelectWithSearch from "../ui/form/SelectWithSearch";
import { useEffect, useState } from "react";
import { listProducts } from "../../../Redux/dashboard/Actions/ProductActions";
import Select from "../ui/form/Select";

export default function ChooseProducts({
  setChoosenProducts,
  choosenProducts,
  productId,
  title,
  complaintMode,
  addSimmilarToDelete,
}) {
  const [inputValue, setInputValue] = useState("");

  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listProducts({ pageNumber: "all" }));
  }, [dispatch]);

  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <div className="similar-products border-1 rounded p-2">
        <ul style={{ maxHeight: "264px" }} className="list-group overflow-auto">
          {choosenProducts?.map((product) => (
            <li
              style={{ height: "88px" }}
              key={product._id}
              className={`list-group-item d-flex gap-3 align-items-center position-relative ${
                theme === "dark" ? "bg-dark text-white border-bottom-1" : ""
              }`}
            >
              <img src={product.imageFiles[0].src} alt="Product" height={70} />
              <span>{product.name}</span>
              {complaintMode && (
                <Select
                  onChange={(e) => {
                    setChoosenProducts((prev) =>
                      prev.map((n) => {
                        if (n._id === product._id) {
                          return { ...n, qty: e.target.value };
                        }
                        return n;
                      })
                    );
                  }}
                >
                  {Array.from(
                    { length: product.countInStock },
                    (_, index) => index + 1
                  ).map((opt) => (
                    <option key={opt}>{opt}</option>
                  ))}
                </Select>
              )}
              <button
                className={`position-absolute right-2 btn btn-close ${
                  theme === "dark" ? "btn-light" : ""
                }`}
                onClick={() => {
                  const prev = choosenProducts.filter(
                    (n) => n._id !== product._id
                  );
                  setChoosenProducts(prev);
                  addSimmilarToDelete && addSimmilarToDelete(product._id);
                }}
              ></button>
            </li>
          ))}
        </ul>
        <SelectWithSearch
          options={
            products && !complaintMode
              ? [...products.filter((n) => n._id !== productId)]
              : products
          }
          keyForLabel="name"
          showThumbnails={true}
          addSimmilarToDelete
          keyForValue={null}
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          onChooseOption={(product) => {
            if (!choosenProducts.find((n) => n._id === product._id)) {
              const addProduct = [...choosenProducts, product];
              setChoosenProducts(addProduct);
            }
          }}
        />
      </div>
    </>
  );
}
