import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditMaterialModal from "./EditMaterialModal";
import DeleteMaterialModal from "./DeleteMaterialModal";
import { listMaterials } from "../../../Redux/dashboard/Actions/MaterialActions";

export default function MaterialsTable() {
  const [material, setMaterial] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const materialsData = useSelector((state) => state.materialList);
  const { materials } = materialsData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  const onEditClick = (material) => {
    setMaterial(material);
    setShowEditModal(true);
  };

  const onDeleteClick = (material) => {
    setMaterial(material);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (dispatch) {
      dispatch(listMaterials());
    }
  }, [dispatch]);

  return (
    <>
      <table
        className={`table orders-table ${theme === "dark" ? "bg-dark" : ""}`}
      >
        <thead>
          <tr
            className={`${
              theme === "dark" ? "text-light dark-theme-hover" : ""
            }`}
          >
            <th scope="col">Naziv</th>
            <th scope="col">Slika</th>
            <th scope="col" className="text-end">
              Akcije
            </th>
          </tr>
        </thead>
        <tbody>
          {materials?.map((material) => (
            <tr
              className={`${
                theme === "dark" ? "text-light dark-theme-hover" : ""
              }`}
              key={material?._id}
            >
              <td>{material?.title}</td>
              <td>
                <img src={material?.image?.src} width={100} alt="material" />
              </td>
              <td className="d-flex justify-content-end align-item-center gap-1">
                <button
                  className={`btn text-success ${
                    theme === "dark" ? "btn-dark" : ""
                  }`}
                  onClick={() => onEditClick(material)}
                >
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  className={`btn text-danger ${
                    theme === "dark" ? "btn-dark" : ""
                  }`}
                  onClick={() => onDeleteClick(material)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <EditMaterialModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        material={material}
      />
      <DeleteMaterialModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        id={material._id}
      />
    </>
  );
}
