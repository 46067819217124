import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { listCategories } from "../../../Redux/client/Actions/CategoryActions";

export default function MenuAccordiion() {
  const [slug, setSlug] = useState("");
  const dispatch = useDispatch();

  const categoriesList = useSelector((state) => state.categoriesList);
  const { categories } = categoriesList;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const history = useHistory();

  const menuGenerator = (categories) => {
    return categories.map((category) => {
      const path = category.path.replaceAll("/", "-");
      return category.children.length > 0 ? (
        <div
          className="accordion mobile-menu-accordion"
          id={`acc-${path}`}
          key={category.path}
        >
          <div className="accordion-item border-0">
            <h2 className="accordion-header" id={`heading-${path}`}>
              <button
                className={`accordion-button px-0 py-2 bg-transparent text-gray shadow-none text-accordion-hover-${theme}-500 ${
                  !slug.includes(category.path)
                    ? "collapsed"
                    : category.path === slug
                    ? `text-active-${theme}`
                    : ""
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${path}`}
                aria-expanded="false"
                aria-controls={`collapse-${path}`}
              >
                <div className="d-flex flex-grow-1 justify-content-between">
                  <a href={`/shop/${category.path}`}>{category.title}</a>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/shop/${category.path}`);
                      window.location.reload();
                    }}
                    className="mx-2 px-2 d-flex align-items-center"
                  >
                    {category.numOfProducts}
                  </span>
                </div>
              </button>
            </h2>
            <div
              id={`collapse-${path}`}
              className={`accordion-collapse collapse ${
                slug.includes(category.path) ? "show" : ""
              }`}
              aria-labelledby={`heading-${path}`}
              data-bs-parent={`#acc-${path}`}
            >
              <div className="accordion-body p-2">
                {menuGenerator(category.children)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <a
          style={{ fontSize: "14px" }}
          className={`${
            category.path === slug ? `text-active-${theme}` : ""
          } d-flex mobile-menu-link py-1 border-0 align-items-center text-accordion-hover-${theme}-500 justify-content-between`}
          href={`/shop/${category.path}`}
          key={category._id}
        >
          <span>{category.title}</span>
          <span className="mx-2 px-2 d-flex align-items-center">
            {category.numOfProducts}
          </span>
        </a>
      );
    });
  };

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    if (history?.location.pathname !== "/") {
      const pathname = history.location.pathname.split("/").slice(2).join("/");
      console.log("pathname:::", pathname);
      setSlug(pathname);
    }
  }, [history]);

  return <>{categories && menuGenerator(categories)}</>;
}
