import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import Message from "../../components/client/loading-error/Error";
import Loading from "../../components/client/loading-error/Loading";
import { register } from "../../Redux/client/Actions/UserActions";
import Header from "../../components/client/Header";
import AvatarComponent from "../../components/client/register-components/Avatar";
import Button from "../../components/client/ui/Button";
import Input from "../../components/client/ui/form/Input";

const Register = ({ location, history }) => {
  const [image, setImage] = useState("");
  const [userData, setUserData] = useState({
    userType: "user",
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
  });

  const { userType, name, email, password, phone, address } = userData;

  const dispatch = useDispatch();
  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Redirect when userInfo changes
    if (userInfo) {
      history.push(redirect);
    }
  }, [userInfo, history, redirect]);

  const handleInputChange = (e) => {
    // Update the state for the corresponding input field
    const { name, value } = e.target;
    // console.log("value:::", value);
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!image) {
      toast.warn("Please upload your avatar image.");
      return;
    }
    dispatch(register({ image, userType, name, email, password, phone, address }));
  };

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}

        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <AvatarComponent setImage={setImage} />
          {/* <div className="d-flex justify-content-center mt-4 gap-3">
            <div className="form-check d-flex gap-1">
              <input
                className="form-check-input"
                type="radio"
                name="userType"
                id="flexRadioDefault1"
                value="user"
                checked={userData.userType === 'user'}
                onChange={handleInputChange}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Korisnik
              </label>
            </div>
            <div className="form-check d-flex gap-1">
              <input
                className="form-check-input"
                type="radio"
                name="userType"
                id="flexRadioDefault2"
                value="seller"
                checked={userData.userType === 'seller'}
                onChange={handleInputChange}
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Prodavac
              </label>
            </div>
          </div> */}
          <Input
            id="name"
            inputClass="login-input"
            type="text"
            placeholder="Korisnicko ime"
            name="name"
            required
            value={name}
            onChange={handleInputChange}
          />
          <Input
            inputClass="login-input"
            type="email"
            placeholder="Email"
            name="email"
            required
            value={email}
            onChange={handleInputChange}
          />
          <Input
            inputClass="login-input"
            type="password"
            placeholder="Lozinka"
            name="password"
            value={password}
            onChange={handleInputChange}
          />
          <Input
            inputClass="login-input"
            type="number"
            placeholder="Telefon"
            name="phone"
            required
            value={phone}
            onChange={handleInputChange}
          />
          <Input
            inputClass="login-input"
            type="text"
            placeholder="Adresa"
            name="address"
            required
            value={address}
            onChange={handleInputChange}
          />

          <Button type="submit">Registracija</Button>
          <p>
            <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
              Vec imam nalog <strong>Prijavi se</strong>
            </Link>
          </p>
        </form>
      </div>

      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default Register;
