import { toLength } from "lodash";
import {
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  MESSAGE_LIST_FAIL,
  NEW_MESSAGE_LIST_REQUEST,
  NEW_MESSAGE_LIST_SUCCESS,
  NEW_MESSAGE_LIST_FAIL,
  NEW_MAIN_MESSAGE_LIST_REQUEST,
  NEW_MAIN_MESSAGE_LIST_SUCCESS,
  NEW_MAIN_MESSAGE_LIST_FAIL,
  MESSAGE_CREATE_REQUEST,
  MESSAGE_CREATE_SUCCESS,
  MESSAGE_CREATE_FAIL,
  EDIT_MESSAGE_REQUEST,
  EDIT_MESSAGE_SUCCESS,
  EDIT_MESSAGE_FAIL,
  REMOVE_MESSAGE_REQUEST,
  REMOVE_MESSAGE_SUCCESS,
  REMOVE_MESSAGE_FAIL,
  ONLINE_USER_LIST_REQUEST,
  ONLINE_USER_LIST_SUCCESS,
  ONLINE_USER_LIST_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
} from "../Constants/ChatConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET MESSAGES
export const listMessages =
  ({ from, to }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MESSAGE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.post(`messages/`, { from, to }, config);

      dispatch({ type: MESSAGE_LIST_SUCCESS, payload: data });
      if (to === "main") {
        dispatch(listNewMainMessages("main", userInfo._id));
      } else {
        dispatch(listNewMessages(to, userInfo._id));
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MESSAGE_LIST_FAIL,
        payload: message,
      });
    }
  };

export const listNewMessages =
  (currentChatId, userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEW_MESSAGE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `messages/new-messages?currentChatId=${currentChatId || ""}&userId=${
          userId || ""
        }`,
        config
      );

      dispatch({ type: NEW_MESSAGE_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: NEW_MESSAGE_LIST_FAIL,
        payload: message,
      });
    }
  };

export const listNewMainMessages =
  (currentChatId, userId) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEW_MAIN_MESSAGE_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `messages/new-main-messages?currentChatId=${
          currentChatId || ""
        }&userId=${userId || ""}`,
        config
      );

      dispatch({ type: NEW_MAIN_MESSAGE_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: NEW_MAIN_MESSAGE_LIST_FAIL,
        payload: message,
      });
    }
  };

// ADD MESSAGE
export const addMessage =
  ({ id, senderData, to, message, image, socket, replyToMessage }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MESSAGE_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `messages/add`,
        { id, senderData, to, message, image, replyToMessage },
        config
      );

      socket.current.emit("new-msg");
      if (image) {
        socket.current.emit("send-msg", {
          senderData,
          to,
          id,
          msg: message,
          isMain: to === "main",
          image: data.message.image,
        });

        dispatch(listMessages({ from: userInfo._id, to }));
      }
      dispatch({ type: MESSAGE_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MESSAGE_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT MESSAGE
export const editMessage =
  ({ messageId, msg }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: EDIT_MESSAGE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `messages/edit/${messageId}`,
        { id: userInfo._id, msg },
        config
      );

      dispatch({ type: EDIT_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: EDIT_MESSAGE_FAIL,
        payload: message,
      });
    }
  };

// DELETE MESSAGE
export const deleteMessage =
  ({ messageId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REMOVE_MESSAGE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `messages/delete/${messageId}`,
        { id: userInfo._id },
        config
      );

      dispatch({ type: REMOVE_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: REMOVE_MESSAGE_FAIL,
        payload: message,
      });
    }
  };

// ONLINE USERS LIST
export const listOnlineUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ONLINE_USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await _axios.get(`messages/online-users`, config);

    dispatch({ type: ONLINE_USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: ONLINE_USER_LIST_FAIL,
      payload: message,
    });
  }
};

// ADD USER
export const addChatUser = (socket) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await _axios.post(
      `messages/add-user`,
      { id: userInfo._id, name: userInfo.name, email: userInfo.email },
      config
    );

    dispatch({ type: ADD_USER_SUCCESS, payload: data });
    dispatch(listOnlineUsers());
    socket.current.emit("user-connect", userInfo._id);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: ADD_USER_FAIL,
      payload: message,
    });
  }
};

// REMOVE USER
export const removeChatUser = (socket) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };

    const { data } = await _axios.post(
      `messages/remove-user`,
      { id: userInfo._id },
      config
    );

    dispatch({ type: REMOVE_USER_SUCCESS, payload: data });
    socket.current.emit("user-disconect", userInfo._id);
    dispatch(listOnlineUsers());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: REMOVE_USER_FAIL,
      payload: message,
    });
  }
};
