import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../loading-error/Toast";
import { toast } from "react-toastify";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import { createSeller } from "../../../Redux/dashboard/Actions/SellerActions";

const CreateSeller = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const sellerCreate = useSelector((state) => state.sellerCreate);
  const { loading, error, seller } = sellerCreate;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    theme,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createSeller({ name, email, phone, address, amount, description }));
    if(error) {
      toast.error("Prodavac vec postoji.", ToastObjects);
    } else {
      toast.success("Prodavac je doddat", ToastObjects);
    }
  };

  useEffect(() => {
    if (seller) {
      setName('');
      setEmail('');
      setDescription('');
    }
  }, [seller, dispatch]);

  return (
    <>
      <Toast />
      <div className="col-md-12 col-lg-12">
        <form className="row" onSubmit={submitHandler}>
          <div className="mb-4 col-md-12 col-lg-6">
            <label htmlFor="seller_name" className="form-label">
              Ime
            </label>
            <input
              type="text"
              placeholder="Kicaj ovde"
              className="form-control py-3"
              id="seller_name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-4 col-md-12 col-lg-6">
            <label htmlFor="seller_email" className="form-label">
              Email
            </label>
            <input
              type="email"
              placeholder="Kicaj ovde"
              className="form-control py-3"
              id="seller_email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4 col-md-12 col-lg-6">
            <label htmlFor="seller_phone" className="form-label">
              Telefon
            </label>
            <input
              type="number"
              placeholder="Kicaj ovde"
              className="form-control py-3"
              id="seller_phone"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mb-4 col-md-12 col-lg-6">
            <label htmlFor="seller_address" className="form-label">
              Adresa
            </label>
            <input
              type="text"
              placeholder="Kicaj ovde"
              className="form-control py-3"
              id="seller_address"
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="mb-4 col-md-12 col-lg-6">
            <label htmlFor="seller_amount" className="form-label">
              Provizija
            </label>
            <input
              type="number"
              placeholder="Kicaj ovde"
              className="form-control py-3"
              id="seller_amount"
              required
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className="mb-4 col-sm-6 col-lg-6">
            <label className="form-label">Opis</label>
            <textarea
              placeholder="Kicaj ovde"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>

          {error && <Message variant="alert-danger">{error}</Message>}
          {loading && <Loading />}

          <div className="d-grid mt-3 flex content-center">
            <button style={{minWidth: '350px'}} className="btn btn-primary py-3">Kreiraj prodavca</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateSeller;
