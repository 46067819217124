import { useEffect, useState } from "react";
import { Section, Card, CardHeader, CardBody } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettings,
  saveSettings,
} from "../../../Redux/dashboard/Actions/SettingsActions";
import SortableImages from "../common/sortable/SortableImages";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import TextEditor from "../ui/TextEditor";

export default function SettingsMain() {
  const [saveEnabled, setBtnsEnabled] = useState(false);
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState([]);
  const [breadcrumbBg, setBreadcrumbBg] = useState([]);
  const [homeBg, setHomeBg] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [settingsForm, setSettingsForm] = useState({});
  const {
    title,
    theme,
    leftText,
    rightText,
    callToText,
    contact,
    address,
    contactAddress,
    contactPhone,
    email,
    workingHours,
    fax,
    disableShop,
  } = settingsForm;

  const handleInputChange = (key, value) => {
    setBtnsEnabled(true);
    setSettingsForm((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const colors = [
    "orange",
    "yellow",
    "indigo",
    "purple",
    "pink",
    "green",
    "blue",
    "brown",
  ];

  const settingsData = useSelector((state) => state.settings);
  const {
    loading,
    error,
    main_slideshow,
    leftRightSection,
    callTo,
    contactSection,
    theme: settingsTheme,
    contactPage,
    logo: logoFromBase,
    breadcrumbBg: breadcrumbBgFromBase,
    homeBg: homeBgFromBase,
    disableShop: disableShopFromBase,
  } = settingsData;

  const themeData = useSelector((state) => state.theme);
  const { theme: darkOrLightTheme } = themeData;

  const dispatch = useDispatch();

  const themeGenerator = (colors) => {
    return colors.map((color) => {
      return (() => {
        let nianse = 0;
        let divs = [
          <input
            key={`check-${color}`}
            type="checkbox"
            className="m-2"
            value={color}
            checked={theme === color}
            onChange={(e) => handleInputChange("theme", e.target.value || "")}
          />,
        ];
        for (let index = 0; index < 9; index++) {
          nianse += 100;
          divs.push(
            <div
              key={`bg-${color}-${nianse}`}
              style={{ height: "80px" }}
              className={`bg-${color}-${nianse} flex-grow-1`}
            ></div>
          );
        }

        return (
          <div className="flex mb-3" key={color}>
            {divs}
          </div>
        );
      })();
    });
  };

  const onSaveClicked = () => {
    dispatch(
      saveSettings(
        {
          theme,
          mainSlideshow: {
            title,
            images,
          },
          leftRightSection: {
            leftText,
            rightText,
          },
          callTo: {
            callToText,
          },
          contactSection: {
            contact,
            address,
            fax,
          },
          contactPage: {
            contactAddress,
            contactPhone,
            email,
            workingHours,
          },
          logo,
          breadcrumbBg,
          homeBg,
          disableShop,
        },
        imagesToDelete
      )
    );
    setBtnsEnabled(false);
  };

  const onResetClicked = () => {
    dispatch(getSettings());
    setBtnsEnabled(false);
  };

  const removeImage = (id) => {
    setBtnsEnabled(true);
    let _image = logo.find((n) => (n.id || n.public_id) === id);
    let index = 0;

    if (_image.public_id) {
      setImagesToDelete((n) => [...n, id]);
      const newImages = logo.filter((image) => {
        if (image.public_id !== id) {
          image.index = index;
          index++;
          return image;
        }
      });
      setLogo(newImages);
    } else {
      const newImages = logo.filter((image) => {
        if (image.id !== id) {
          image.index = index;
          index++;
          return image;
        }
      });
      setLogo(newImages);
    }
  };

  // this couses an infinite loop
  // useEffect(() => {
  //   dispatch(getSettings());
  // }, [dispatch]);

  useEffect(() => {
    if (settingsData) {
      const settingsData = {
        theme: settingsTheme,
        title: main_slideshow?.title,
        leftText: leftRightSection?.leftText,
        rightText: leftRightSection?.rightText,
        callToText: callTo?.callToText,
        contact: contactSection?.contact,
        address: contactSection?.address,
        fax: contactSection?.fax,
        contactAddress: contactPage?.contactAddress,
        contactPhone: contactPage?.contactPhone,
        email: contactPage?.email,
        workingHours: contactPage?.workingHours,
        logo: logoFromBase,
        breadcrumbBg: breadcrumbBgFromBase,
        homeBg: homeBgFromBase,
        disableShop: disableShopFromBase
      };

      setSettingsForm(settingsData);
      setImages(main_slideshow?.images || []);
      setLogo(logoFromBase);
      setBreadcrumbBg(breadcrumbBgFromBase);
      setHomeBg(homeBgFromBase);
    }
  }, [settingsData]);

  return (
    <Section className="position-relative">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <button
            className="btn btn-warning"
            disabled={!saveEnabled}
            onClick={onResetClicked}
          >
            <i className="fas fa-redo-alt"></i>
          </button>

          <button
            className="btn btn-primary"
            disabled={!saveEnabled}
            onClick={onSaveClicked}
          >
            Sacuvaj
          </button>
        </CardHeader>
        <CardBody>
          <ul
            className="nav nav-tabs settings-tabs border-0"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 active ${
                  darkOrLightTheme === "dark" ? "dark-tab" : ""
                }`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Pocetna
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 ${
                  darkOrLightTheme === "dark" ? "dark-tab" : ""
                }`}
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Kontakt
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 ${
                  darkOrLightTheme === "dark" ? "dark-tab" : ""
                }`}
                id="theme-tab"
                data-bs-toggle="tab"
                data-bs-target="#theme"
                type="button"
                role="tab"
                aria-controls="theme"
                aria-selected="false"
              >
                Tema
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link border-0 ${
                  darkOrLightTheme === "dark" ? "dark-tab" : ""
                }`}
                id="logo-tab"
                data-bs-toggle="tab"
                data-bs-target="#logo"
                type="button"
                role="tab"
                aria-controls="logo"
                aria-selected="false"
              >
                Dodatna podesavanja
              </button>
            </li>
          </ul>
          <div className="tab-content border-0" id="myTabContent">
            <div
              className={`tab-pane fade show active ${
                darkOrLightTheme === "dark" ? "bg-dark" : "bg-white"
              }`}
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <Card section={true} className="p-2 border-1 my-2">
                <CardHeader section={true}>
                  <h4 className="text-center">Glavni slideshow</h4>
                </CardHeader>

                <CardBody className="py-3" section={true}>
                  <TextEditor
                    label="Klizajuci tekst preko slideshow-a"
                    className="mb-4"
                    value={title}
                    onChange={(value) => handleInputChange("title", value)}
                  />

                  <SortableImages
                    images={images}
                    setImages={setImages}
                    setImagesToDelete={setImagesToDelete}
                    setBtnsEnabled={setBtnsEnabled}
                    multiple={true}
                  />
                </CardBody>
              </Card>
              <Card section={true} className="p-2 border-1 my-2">
                <CardHeader section={true}>
                  <h3 className="text-center">O nama sekcija</h3>
                </CardHeader>

                <CardBody
                  className="d-flex flex-column flex-md-row flex-lg-row gap-1 py-3"
                  section={true}
                >
                  <TextEditor
                    label="Kucaj ovde"
                    className="col-lg-6 col-md-6 col-12"
                    value={leftText}
                    onChange={(value) => handleInputChange("leftText", value)}
                  />
                  <TextEditor
                    label="Kucaj ovde"
                    className="col-lg-6 col-md-6 col-12"
                    value={rightText}
                    onChange={(value) => handleInputChange("rightText", value)}
                  />
                </CardBody>
              </Card>
              <Card section={true} className="p-2 border-1 my-2">
                <CardHeader section={true}>
                  <h3 className="text-center ">Registracija na NewsLetter</h3>
                </CardHeader>

                <CardBody className="py-3" section={true}>
                  <TextEditor
                    label="Kucaj ovde"
                    value={callToText}
                    onChange={(value) => handleInputChange("callToText", value)}
                  />
                </CardBody>
              </Card>
              <Card section={true} className="p-2 border-1 my-2">
                <CardHeader section={true}>
                  <h3 className="text-center ">Kontakt sekcija</h3>
                </CardHeader>

                <CardBody
                  className="d-flex flex-column flex-lg-row py-3 gap-1 settings-contact-section"
                  section={true}
                >
                  <TextEditor
                    label="Kontakt"
                    className="mb-3"
                    value={contact}
                    onChange={(value) => handleInputChange("contact", value)}
                  />
                  <TextEditor
                    label="Adresa"
                    className="mb-3"
                    value={address}
                    onChange={(value) => handleInputChange("address", value)}
                  />
                  <TextEditor
                    label="Faks"
                    className="mb-3"
                    value={fax}
                    onChange={(value) => handleInputChange("fax", value)}
                  />
                </CardBody>
              </Card>
            </div>
            <div
              className={`tab-pane fade ${
                darkOrLightTheme === "dark" ? "bg-dark" : "bg-white"
              }`}
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <Card section={true} className="p-2 border-1 my-2">
                <CardHeader section={true}>
                  <h3 className="text-center ">Kontakt stranica</h3>
                </CardHeader>

                <CardBody
                  className="d-flex flex-column flex-lg-row gap-1 py-3"
                  section={true}
                >
                  <TextEditor
                    label="Adresa"
                    className="mb-3"
                    value={contactAddress}
                    onChange={(value) =>
                      handleInputChange("contactAddress", value)
                    }
                  />
                  <TextEditor
                    label="Brojevi telefona"
                    className="mb-3"
                    value={contactPhone}
                    onChange={(value) =>
                      handleInputChange("contactPhone", value)
                    }
                  />
                  <TextEditor
                    label="Email"
                    className="mb-3"
                    value={email}
                    onChange={(value) => handleInputChange("email", value)}
                  />
                  <TextEditor
                    label="Radno vreme"
                    className="mb-3"
                    value={workingHours}
                    onChange={(value) =>
                      handleInputChange("workingHours", value)
                    }
                  />
                </CardBody>
              </Card>
            </div>
            <div
              className={`tab-pane fade p-3 ${
                darkOrLightTheme === "dark" ? "bg-dark" : "bg-white"
              }`}
              id="theme"
              role="tabpanel"
              aria-labelledby="theme-tab"
            >
              {themeGenerator(colors)}
            </div>
            <div
              className="tab-pane fade p-3"
              id="logo"
              role="tabpanel"
              aria-labelledby="logo-tab"
            >
              <div className="mb-4">
                <h5 className="mb-3">Logo</h5>
                <SortableImages
                  images={logo}
                  setImages={setLogo}
                  removeImage={removeImage}
                  setImagesToDelete={setImagesToDelete}
                  setBtnsEnabled={setBtnsEnabled}
                />
              </div>

              <div className="mb-4">
                <h5 className="mb-3">Pozadinska slika za breadcrumb</h5>
                <SortableImages
                  images={breadcrumbBg}
                  setImages={setBreadcrumbBg}
                  removeImage={removeImage}
                  setImagesToDelete={setImagesToDelete}
                  setBtnsEnabled={setBtnsEnabled}
                />
              </div>

              <div className="mb-4">
                <h5 className="mb-3">Pozadinska slika na pocetnoj stranici</h5>
                <SortableImages
                  images={homeBg}
                  setImages={setHomeBg}
                  removeImage={removeImage}
                  setImagesToDelete={setImagesToDelete}
                  setBtnsEnabled={setBtnsEnabled}
                />
              </div>

              <div className="mb-4">
                <input
                  id="disableShop"
                  type="checkbox"
                  className="m-2"
                  checked={disableShop}
                  onChange={(e) =>
                    handleInputChange("disableShop", e.target.checked)
                  }
                />
                <label htmlFor="disableShop">Iskljuci prodavnicu</label>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {loading && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
      {error && <Message variant="alert-danger">{error}</Message>}
    </Section>
  );
}
