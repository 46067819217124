import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";

const CommissionsFilters = ({
  submitHandler,
  keyword,
  setKeyword,
  toShow,
  setToShow,
  commissionStatus,
  setCommissionStatus,
}) => {
  const [firstRender, setFirstRender] = useState(true);

  const memoizedSearch = useMemo(() => {
    const debounceSearch = debounce(async (query) => {
      // Perform your search logic here
      console.log("Searching for:", query);
      // ... Additional search code
      setKeyword(query);
      submitHandler(1, query, commissionStatus, toShow);
    }, 500);

    return debounceSearch;
  }, [commissionStatus]);

  const search = (query) => {
    memoizedSearch(query);
  };

  useEffect(() => {
    setFirstRender(false);
    if (firstRender) return;

    submitHandler("all", keyword, commissionStatus, toShow);
  }, [commissionStatus, toShow]);

  return (
    <div className="row gx-3 py-3">
      <div className="col-lg-4 col-md-6 me-auto ">
        <input
          type="search"
          placeholder="Search..."
          className="form-control p-2"
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="col-lg-2 col-6 col-md-3">
        <select
          className="form-select"
          onChange={(e) => setCommissionStatus(e.target.value)}
        >
          <option value={""}>All</option>
          <option>Not Paid</option>
          <option>Paid</option>
          <option>Pending</option>
        </select>
      </div>
      <div className="col-lg-2 col-6 col-md-3">
        <select
          className="form-select"
          onChange={(e) => setToShow(e.target.value)}
        >
          <option value={20}>Show 20</option>
          <option value={30}>Show 30</option>
          <option value={40}>Show 40</option>
        </select>
      </div>
    </div>
  );
};

export default CommissionsFilters;
