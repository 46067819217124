import { useDispatch, useSelector } from "react-redux";
import SelectWithSearch from "../ui/form/SelectWithSearch";
import { useEffect, useState } from "react";
import { listProducts } from "../../../Redux/dashboard/Actions/ProductActions";
import {
  addTodo,
  deleteTodo,
  doneTodo,
  listPendingTodo,
  replaceTodos,
  updateTodo,
} from "../../../Redux/dashboard/Actions/TodoActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Select from "../ui/form/Select";

export default function TodoProducts({ title }) {
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = useSelector((state) => state.productList);

  const {
    todos,
    loading: loadingTodos,
    error: errorTodos,
  } = useSelector((state) => state.todoListPending);

  const { loading: loadingAddTodo, error: errorAddTodo } = useSelector(
    (state) => state.todoAdd
  );

  const { loading: loadingDeleteTodo, error: errorDeleteTodo } = useSelector(
    (state) => state.todoDelete
  );

  const { loading: loadingDoneTodo, error: errorDoneTodo } = useSelector(
    (state) => state.todoDone
  );

  const { theme } = useSelector((state) => state.theme);

  const onChooseProduct = (product) => {
    const { name, price, buildingDaysNum, _id } = product;
    dispatch(
      addTodo({
        name,
        price,
        buildingDaysNum: buildingDaysNum || 1,
        image: product.imageFiles[0],
        product: _id,
        done: false,
      })
    );
  };

  const onSortEnd = (oldIndex, newIndex) => {
    const newTodosData = arrayMoveImmutable(todos, oldIndex, newIndex);
    newTodosData.forEach((todo, index) => (todo.index = index));
    dispatch(replaceTodos(newTodosData));
  };

  const onDoneProduct = (id) => dispatch(doneTodo(id));
  const onRemoveProduct = (id) => dispatch(deleteTodo(id));

  const onChangeDays = ({ id, daysLeft }) => {
    dispatch(updateTodo({ id, daysLeft }));
  };

  useEffect(() => {
    dispatch(listProducts({ pageNumber: "all" }));
    dispatch(listPendingTodo({ pageNumber: 1, keyword: "" }));
  }, [dispatch]);

  const isLoading =
    loadingProducts ||
    loadingTodos ||
    loadingAddTodo ||
    loadingDeleteTodo ||
    loadingDoneTodo;
  const error =
    errorProducts ||
    errorTodos ||
    errorAddTodo ||
    errorDeleteTodo ||
    errorDoneTodo;

  return (
    <div className="pb-4">
      <h5 className="mb-3">{title}</h5>
      {error && <Message variant="error">{error}</Message>}
      <div className="similar-products border-1 rounded p-2">
        <div
          style={{ maxHeight: "264px" }}
          className="list-group overflow-auto"
        >
          <SortableList
            onSortEnd={onSortEnd}
            className="sortable-item-list"
            draggedItemClassName="dragged"
          >
            {todos?.map((todo) => (
              <SortableItem key={todo._id}>
                <div
                  style={{ height: "88px", paddingRight: "150px" }}
                  className={`list-group-item d-flex gap-3 justify-content-between align-items-center position-relative ${
                    theme === "dark" ? "bg-dark text-white border-bottom-1" : ""
                  }`}
                >
                  <img src={todo.image.src} alt="Todo" height={70} />
                  <span>{todo.name}</span>

                  <div>
                    <span>Broj dana: </span>
                    <Select
                      value={todo.buildingDaysNum}
                      onChange={(e) =>
                        onChangeDays({
                          id: todo._id,
                          daysLeft: e.target.value,
                        })
                      }
                    >
                      {[...Array(todo.buildingDaysNum).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="position-absolute right-2 d-flex align-items-center gap-2">
                    <button
                      className="btn btn-outline p-1 text-success"
                      onClick={() => onDoneProduct(todo._id)}
                    >
                      <i className="fas fa-check"></i>
                    </button>
                    <button
                      className={`btn btn-close ${
                        theme === "dark" ? "btn-light" : ""
                      }`}
                      onClick={() => onRemoveProduct(todo._id)}
                    ></button>
                  </div>
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </div>
        <SelectWithSearch
          options={products}
          keyForLabel="name"
          keyForValue={null}
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          onChooseOption={(product) => onChooseProduct(product)}
        />
      </div>

      {isLoading && <Loading position="absolute" width="100%" height="100%" />}
    </div>
  );
}
