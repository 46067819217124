import React, { useEffect } from "react";
import TopTotal from "../common/TopTotal";
import LatestOrder from "./LatestOrder";
import SaleStatistics from "./SalesStatistics";
import ProductsStatistics from "./ProductsStatistics";
import WishListStatistics from "./WishListStatistics";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading-error/Loading";
import { Section } from "../common";
import Message from "../loading-error/Error";
import { listProducts } from "../../../Redux/dashboard/Actions/ProductActions";
import { cloudinaryUsage } from "../../../Redux/dashboard/Actions/CloudinaryActions";
import ProductViewStatistics from "./ProductViewStatistics";

const Main = () => {
  const dispatch = useDispatch();

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const productList = useSelector((state) => state.productList);
  const { loading: productLoading, countProducts } = productList;

  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const cloudinaryUsageData = useSelector((state) => state.cloudinaryUsage);
  const { loading: cloudinaryUsageLoading } = cloudinaryUsageData;

  const { loading: settingsLoading } = useSelector((state) => state.settings);

  console.log("cloudinaryUsageData:::", cloudinaryUsageData);

  useEffect(() => {
    if (dispatch) {
      dispatch(listProducts({ pageNumber: "all", keyword: "" }));
      dispatch(cloudinaryUsage());
    }
  }, [dispatch]);

  return (
    <>
      <Section className="position-relative">
        {error && <Message variant="alert-danger">{error}</Message>}

        <div className="content-header">
          <h2 className="content-title"> Statistika </h2>
        </div>
        {/* Top Total */}
        <TopTotal orders={orders?.orders} countProducts={countProducts} />

        <div className="row">
          {/* STATICS */}
          {userInfo.isSuperAdmin && <ProductsStatistics />}
          {userInfo.isSuperAdmin && <WishListStatistics />}
        </div>

        <div className="row">
          {/* STATICS */}
          {userInfo.isSuperAdmin && <SaleStatistics />}
          {userInfo.isSuperAdmin && <ProductViewStatistics />}
        </div>

        {/* LATEST ORDER */}
        <div className="row shadow-sm">
          <LatestOrder />
        </div>

        {(loading ||
          settingsLoading ||
          productLoading ||
          cloudinaryUsageLoading) && (
          <Loading position="position-absolute" width="100%" height="100%" />
        )}
      </Section>
    </>
  );
};

export default Main;
