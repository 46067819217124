export const COMMISSION_LIST_REQUEST = "COMMISSION_LIST_REQUEST";
export const COMMISSION_LIST_SUCCESS = "COMMISSION_LIST_SUCCESS";
export const COMMISSION_LIST_FAIL = "COMMISSION_LIST_FAIL";

export const COMMISSION_CREATE_REQUEST = "COMMISSION_CREATE_REQUEST";
export const COMMISSION_CREATE_SUCCESS = "COMMISSION_CREATE_SUCCESS";
export const COMMISSION_CREATE_FAIL = "COMMISSION_CREATE_FAIL";
export const COMMISSION_CREATE_RESET = "COMMISSION_CREATE_RESET";

export const COMMISSION_DELETE_REQUEST = "COMMISSION_DELETE_REQUEST";
export const COMMISSION_DELETE_SUCCESS = "COMMISSION_DELETE_SUCCESS";
export const COMMISSION_DELETE_FAIL = "COMMISSION_DELETE_FAIL";