import React, { useState } from "react";
import TableGenerator from "./TableGenerator";
import TextEditor from "../../ui/TextEditor";

export default function ProductDescription({ value, onChange, tableValue, onTableChange }) {
  const [descriptionType, setDescriptionType] = useState("text");

  const handleOptionChange = (value) => {
    setDescriptionType(value);
  };

  return (
    <>
      <h3 className="text-center mt-2">Opis</h3>
      <div className="d-flex justify-content-center gap-3 mb-3 mt-2">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="description-type"
            id="exampleRadios1"
            value="text"
            onChange={(e) => handleOptionChange(e.target.value)}
            checked={descriptionType === "text"}
          />
          <label className="form-check-label" htmlFor="exampleRadios1">
            Tekst
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="description-type"
            id="exampleRadios2"
            value="table"
            onChange={(e) => handleOptionChange(e.target.value)}
            checked={descriptionType === "table"}
          />
          <label className="form-check-label" htmlFor="exampleRadios2">
            Tabela
          </label>
        </div>
      </div>

      {descriptionType === "text" && (
        <div className="mb-4 row">
          <TextEditor
            label="Opis"
            className="mb-4 px-0"
            value={value}
            onChange={onChange}
          />
        </div>
      )}
      {descriptionType === "table" && (
        <TableGenerator tableValue={tableValue} onTableChange={onTableChange}  />
      )}
    </>
  );
}
