import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// import Logo from "../../assets/logo.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  listNewMainMessages,
  listNewMessages,
} from "../../../Redux/dashboard/Actions/ChatActions";

export default function Contacts({
  contacts,
  changeChat,
  socket,
  onlineUsers,
}) {
  const [expandContacts, setExpandContacts] = useState(false);
  const [currentSelected, setCurrentSelected] = useState(undefined);

  const dispatch = useDispatch();

  const currentChatIdRef = useRef("");

  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const newMessagesData = useSelector((state) => state.newMessages);
  const { messages } = newMessagesData;

  const newMainMessagesData = useSelector((state) => state.newMainMessages);
  const { messages: newMainMessages } = newMainMessagesData;

  const settingsData = useSelector((state) => state.settings);
  const { logo } = settingsData;

  const changeCurrentChat = (index, contact) => {
    setExpandContacts(false);
    setCurrentSelected(index);
    changeChat(contact);
    currentChatIdRef.current = contact._id;
  };

  const onSetMainChat = () => {
    setExpandContacts(false);
    setCurrentSelected("main");
    changeChat("main");
    currentChatIdRef.current = "main";
  };

  const countMessages = (contact_id) => {
    if (!messages) return;
    let times = messages.filter((message) => message.sender === contact_id);
    if (times?.length > 0) {
      return <div className="count-messages">{times.length}</div>;
    }
  };

  const countMainMessages = () => {
    if (!newMainMessages) return;
    let times = newMainMessages.filter(
      (message) => !message.seen.includes(userInfo._id)
    );
    if (times?.length > 0) {
      return <div className="count-messages">{times.length}</div>;
    }
  };

  useEffect(() => {
    if (dispatch && socket?.current && userInfo && currentChatIdRef) {
      socket.current.off("new-msg-recieve");
      socket.current.on("new-msg-recieve", () => {
        dispatch(listNewMessages(currentChatIdRef.current || "", userInfo._id));
        dispatch(
          listNewMainMessages(currentChatIdRef.current || "", userInfo._id)
        );
      });
    }

    return () => socket?.current.off("new-msg-recieve");
  }, [dispatch, socket?.current, currentChatIdRef.current, userInfo]);

  useEffect(() => {
    if (dispatch && userInfo) {
      dispatch(listNewMessages("", userInfo._id));
      dispatch(listNewMainMessages("", userInfo._id));
    }
  }, [dispatch, userInfo]);

  return (
    <>
      {contacts && (
        <Container
          className={`${theme === "dark" ? "bg-dark" : "bg-white"} ${
            expandContacts ? "contacts-expanded" : ""
          }`}
        >
          <div className="brand">
            <img src={logo && logo[0].src} alt="logo" />
            <h3 className={theme === "light" ? "text-dark" : ""}>chat</h3>
          </div>
          <div className="contacts pt-3 overflow-y-auto">
            <div
              key="main"
              className={`contact ${
                currentSelected === "main"
                  ? "selected"
                  : "border-1 border-gray text-dark"
              }`}
              onClick={() => onSetMainChat()}
            >
              <div className="avatar">
                <img src={logo && logo[0].src} alt="logo" />
              </div>
              <div className="username px-1">
                <h5 className={theme === "light" ? "text-dark" : ""}>
                  Zajednicka soba
                </h5>
              </div>
              {countMainMessages()}
            </div>
            {contacts.map((contact, index) => {
              if (contact._id !== userInfo._id) {
                return (
                  <div
                    key={contact._id}
                    className={`contact ${
                      index === currentSelected
                        ? "selected"
                        : "border-1 border-gray text-dark"
                    }`}
                    onClick={() => {
                      changeCurrentChat(index, contact);
                    }}
                  >
                    <div className="avatar">
                      <img src={contact.avatarImage?.url} alt="logo" />
                    </div>
                    <div className="username d-flex align-items-center gap-2 px-1">
                      <h5
                        className={`m-0 ${
                          theme === "light" ? "text-dark" : ""
                        }`}
                      >
                        {contact.name}
                      </h5>
                      <div
                        className={`user-status ${
                          onlineUsers?.includes(contact._id)
                            ? "online"
                            : "offline"
                        }`}
                      ></div>
                    </div>
                    {countMessages(contact._id)}
                  </div>
                );
              }
            })}
          </div>
          <div
            className={`current-user px-3 ${
              theme === "dark" ? "bg-dark" : "bg-white"
            }`}
          >
            <div className="avatar">
              <img
                src={userInfo?.avatarImage?.url}
                width={70}
                height={70}
                alt="avatar"
              />
            </div>
            <div className="username px-2">
              <h3 className={theme === "light" ? "text-dark" : ""}>
                {userInfo.name}
              </h3>
            </div>
          </div>

          <button
            className={`btn ${
              theme === "dark" ? "btn-dark" : "btn-light border-1 border-gray"
            }  d-flex gap-1 position-absolute rounded-circle align-items-center border-1 border-light text-white`}
            onClick={() => setExpandContacts(!expandContacts)}
          >
            <i className="fas fa-chevron-left"></i>
            <i className="fas fa-chevron-right"></i>
          </button>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 10% 75% 15%;
  // overflow: hidden;
  border-right: 1px solid gray;
  max-width: 300px;
  z-index: 10;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 4rem;
    }
    h3 {
      color: white;
      text-transform: uppercase;
      margin: 0;
    }
  }
  button {
    top: 12px;
    right: -23px;
    height: 46px;
    @media screen and (min-width: 1080px) {
      display: none !important;
    }
  }
  @media screen and (max-width: 1080px) {
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    border-right: none;
    max-width: 100%;
  }
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.8rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      position: relative;
      background-color: #ffffff34;
      min-height: 2rem;
      border: 1px solid transparent;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h5 {
          color: white;
        }
        .user-status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        .online {
          background-color: green;
        }
        .offline {
          background-color: red;
        }
      }
      .count-messages {
        position: absolute;
        right: -10px;
        top: -10px;
        padding: 0 5px;
        color: white;
        background-color: red;
        border-radius: 50%;
      }
    }
    .selected {
      background-color: #4fa607;
    }
  }

  .current-user {
    border-top: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .username {
      h3 {
        color: white;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h3 {
          font-size: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 720px) {
    .current-user {
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
`;
