import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../ui/form/Input";

export default function ProcurementTable({ products }) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  return (
    <>
      <table
        className={`table orders-table ${theme === "dark" ? "bg-dark" : ""}`}
      >
        <thead>
          <tr
            className={`${
              theme === "dark" ? "text-light dark-theme-hover" : ""
            }`}
          >
            <th scope="col">Slika</th>
            <th scope="col">Naziv</th>
            <th scope="col">Kolicina</th>
            <th scope="col" className="text-end">
              Izaberi
            </th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => (
            <tr
              className={`${
                theme === "dark" ? "text-light dark-theme-hover" : ""
              }`}
              key={product?._id}
            >
              <td className="align-middle">
                <img
                  src={product?.imageFiles[0].src}
                  width={100}
                  alt="product logo"
                />
              </td>
              <td className="align-middle">{product?.name}</td>
              <td className="align-middle">
                <Input className="w-50" type="number" />
              </td>
              <td className="text-end align-middle align-item-center gap-1">
                <input
                  key={`check-${product.name}`}
                  type="checkbox"
                  className="m-2"
                  // checked={theme === color}
                  // onChange={(e) =>
                  //   handleInputChange("theme", e.target.value || "")
                  // }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
