import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { addProductFollower } from "../../../Redux/client/Actions/ProductActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import Button from "../ui/Button";
import Input from "../ui/form/Input";
import { PRODUCT_FOLLOWER_RESET } from "../../../Redux/client/Constants/ProductConstants";

const FollowProduct = ({ productId }) => {
  const [price, setPrice] = useState("");
  const [email, setEmail] = useState("");

  const productFollower = useSelector((state) => state.productFollower);
  const { loading, error, success } = productFollower;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    const currentTime = moment();

    dispatch(
      addProductFollower(productId, {
        price,
        email,
        date: currentTime.format("MMM Do YY"),
      })
    );
    setPrice("");
    setEmail("");
  };

  useEffect(() => {
    dispatch({ type: PRODUCT_FOLLOWER_RESET });
  }, [dispatch]);

  return (
    <div className="flex col-md-12 direction-column space-between product-follow">
      {loading && <Loading />}
      {success && (
        <div className="my-4">
          <Message variant="alert-success">{success}</Message>
        </div>
      )}
      {error && (
        <div className="my-4">
          <Message variant="alert-danger">{error}</Message>
        </div>
      )}
      <form className="mt-4" onSubmit={submitHandler}>
        <div className="mb-4">
          <div className="relative">
            <Input
              type="number"
              placeholder="Unesite zeljenu cenu"
              label="Budite obavesteni kada cena ovog proizvoda bude niza od:"
              sufix="RSD"
              value={price}
              required
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-4">
          <Input
            type="email"
            value={email}
            label="Email"
            placeholder="Unesite vasu email adresu"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="">
          <Button
            disabled={loading}
            variant="outline"
            className="p-3 text-uppercase w-100"
          >
            ZAPRATI
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FollowProduct;
