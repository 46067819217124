import React, { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Input from "../ui/form/Input";

export default function SpecifiedDimensions({
  items,
  setItems,
}) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [length, setLength] = useState(0);

  const { theme } = useSelector((state) => state.theme);

  const handleAddItem = (e) => {
    e.preventDefault();
    if (width || length || height) {
      const prev = [...items, { id: uuidv4(), width, length, height }];
      setItems(prev);
      clearInputs();
    }
  };

  const clearInputs = () => {
    setWidth("");
    setHeight("");
    setLength("");
  };

  const handleRemoveItem = (e, id) => {
    e.preventDefault();
    const prev = items.filter((item) => item.id !== id);
    setItems(prev);
  };

  return (
    <div className="mb-4">
      <div className="product-dimensions mb-4 d-flex gap-2 flex-wrap g-0">
        <Input
          id="width"
          className="flex-grow-1 position-relative"
          type="number"
          label="Širina"
          value={width}
          sufix="cm"
          onChange={(e) => setWidth(e.target.value)}
        />
        <Input
          id="length"
          className="flex-grow-1 position-relative"
          type="number"
          label="Dužina"
          value={length}
          sufix="cm"
          onChange={(e) => setLength(e.target.value)}
        />
        <Input
          id="height"
          className="flex-grow-1 position-relative"
          type="number"
          label="Visina"
          value={height}
          sufix="cm"
          onChange={(e) => setHeight(e.target.value)}
        />
        <div className="mt-2 d-flex flex-grow-1 align-items-end">
          <button className="btn btn-primary w-100" onClick={handleAddItem}>
            Dodaj
          </button>
        </div>
      </div>
      <div className="dimension-items d-flex flex-wrap gap-2 mt-3">
        {items.map((item) => (
          <div key={item.id}>
            <div
              className={`border-1 rounded ${
                theme === "light" ? "border-dark" : "shadow-none"
              } p-2 d-inline-block position-relative`}
            >
              <div className="d-flex gap-2 align-items-center h-100">
                <p className="m-0">
                  {item.width && `W:${item.width} `}
                  {item.length && `L:${item.length} `}
                  {item.height && `H:${item.height}`}
                </p>
                <button
                  className={`remove-item btn btn-close ${
                    theme === "dark" ? "btn-light" : ""
                  }`}
                  onClick={(e) => handleRemoveItem(e, item.id)}
                ></button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
