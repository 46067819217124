import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const Orders = ({ orders }) => {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  console.log("ORDERS:::", orders);

  return (
    <table
      className={`table orders-table ${theme === "dark" ? "bg-dark" : ""}`}
    >
      <thead>
        <tr
          className={`${theme === "dark" ? "text-light dark-theme-hover" : ""}`}
        >
          <th scope="col">Korisnik</th>
          <th scope="col">Puno Ime</th>
          <th scope="col">Email</th>
          <th scope="col">Ukupno</th>
          {/* <th scope="col">Paid</th> */}
          <th scope="col">Datum</th>
          <th>Status</th>
          <th scope="col" className="text-end">
            Akcije
          </th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order) => (
          <tr
            className={`${
              theme === "dark"
                ? order.isNewOrder
                  ? "text-danger dark-theme-hover"
                  : "text-light dark-theme-hover"
                : ""
            }`}
            key={order._id}
          >
            <td>
              <b>{order.user?.name}</b>
            </td>
            <td>{order.shippingAddress.fullName}</td>
            <td>{order.user?.email || order.shippingAddress.email}</td>
            <td>{order.totalPrice} RSD</td>
            {/* <td>
              {order.isPaid ? (
                <span className="badge rounded-pill alert-success">
                  Delivered At {moment(order.paidAt).format("MMM Do YY")}
                </span>
              ) : (
                <span className="badge rounded-pill alert-danger">
                  Not Delivered
                </span>
              )}
            </td> */}
            <td>{moment(order.createdAt).format("MMM Do YY")}</td>
            <td>
              <span
                className={`badge ${
                  order.status === "Dostavljeno"
                    ? "btn-success"
                    : order.status === "Predato brzoj posti"
                    ? "btn-warning text-dark"
                    : "btn-dark"
                }`}
              >
                {order.status}
              </span>
            </td>
            <td className="d-flex justify-content-end align-item-center">
              <Link
                to={`/dashboard-order/${order._id}`}
                className="text-success"
              >
                <i className="fas fa-eye"></i>
              </Link>
            </td>
          </tr>
        ))}

        {/* Not paid Not delivered */}
        {/* <tr>
          <td>
            <b>Velcro Sneakers For Boys & Girls (Blue)</b>
          </td>
          <td>user@example.com</td>
          <td>$45,789</td>
          <td>
            <span className="badge rounded-pill alert-danger">Not paid</span>
          </td>
          <td>Dec 12 2021</td>
          <td>
            <span className="badge btn-dark">Not Delivered</span>
          </td>
          <td className="d-flex justify-content-end align-item-center">
            <Link to={`/order`} className="text-success">
              <i className="fas fa-eye"></i>
            </Link>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default Orders;
