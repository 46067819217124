import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listProduct,
  productPreview,
} from "../../../Redux/client/Actions/ProductActions";
import { Grid, CardList } from "../common/BootstrapIcons";
import Select from "../ui/form/Select";
import MobileFilters from "./MobileFilters";

export default function ProductFilters({ sellerId, keywordProp }) {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.clientProductList);
  const { page, prevKeyword, prevSlug, toShow, sort } = productList;

  const productPreviewState = useSelector((state) => state.productPreview);
  const { preview } = productPreviewState;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const productPreviewFunc = (value) => {
    dispatch(productPreview(value));
  };

  return (
    <header className="py-0 bg-white mb-3 site-bg">
      <div
        className={`py-3 site-bg
          d-flex flex-lg-row flex-md-row flex-column`}
      >
        <div className="col-lg-4 col-md-6 p-0 me-auto mb-md-0 mb-lg-0 mb-3 d-flex align-content-center">
          <div className="d-flex align-items-center">
            <div style={{marginRight: "10px"}} className="d-block d-lg-none d-md-none">
              <MobileFilters keywordProp={keywordProp} />
            </div>
            <div
              style={{ paddingRight: "5px" }}
              className="card-display"
              onClick={() => productPreviewFunc("grid")}
            >
              <Grid
                className={`${
                  preview === "grid" ? `text-${theme}-500` : "text-gray"
                }`}
                width={20}
                height={20}
              />
            </div>
            <div
              style={{ borderLeft: "1px solid #ccc", paddingLeft: "5px" }}
              className="card-display"
              onClick={() => productPreviewFunc("card")}
            >
              <CardList
                className={`${
                  preview === "card" ? `text-${theme}-500` : "text-gray"
                }`}
                width={25}
                height={25}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12 d-flex gap-1">
          <div className="flex-grow-1">
            <Select
              inputClass={`form-select p-2`}
              onChange={(e) =>
                dispatch(
                  listProduct({
                    pageNumber: page,
                    keyword: prevKeyword,
                    slug: prevSlug,
                    sellerId,
                    toShow: e.target.value,
                    sortBy: sort,
                  })
                )
              }
            >
              <option>6</option>
              <option>12</option>
              <option>24</option>
              <option>36</option>
            </Select>
          </div>
          <div className="flex-grow-1">
            <Select
              inputClass={`form-select p-2`}
              onChange={(e) =>
                dispatch(
                  listProduct({
                    pageNumber: page,
                    keyword: prevKeyword,
                    slug: prevSlug,
                    sellerId,
                    toShow,
                    sortBy: e.target.value,
                  })
                )
              }
            >
              <option value="Latest added">Sortiraj od najnovijeg</option>
              <option value="Previous added">Sortiraj od najstarijeg</option>
              <option value="Cheap first">Sortiraj po ceni rastuca</option>
              <option value="Expensive first">Sortiraj po ceni opadajuca</option>
              <option value="Most viewed">Sortiraj od najpopularnijeg</option>
            </Select>
          </div>
        </div>
      </div>
    </header>
  );
}
