import { useSelector } from "react-redux";

export default function Checkbox({ id, value, label, onInput, checked }) {
  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  return (
    <div className="form-check">
      <input
        id={id}
        className={`form-check-input bg-checked-${theme}-500 shadow-focus-${theme}-200`}
        type="checkbox"
        checked={checked}
        value={value}
        onChange={() => {}}
        onInput={(e) => onInput && onInput(e)}
      />
      <label className="form-check-label" htmlFor="flexCheckDefault">
        {label}
      </label>
    </div>
  );
}
