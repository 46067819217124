import {
  CATEGORY_REQUEST_LIST_REQUEST,
  CATEGORY_REQUEST_LIST_SUCCESS,
  CATEGORY_REQUEST_LIST_FAIL,
  CATEGORY_REQUEST_CREATE_REQUEST,
  CATEGORY_REQUEST_CREATE_SUCCESS,
  CATEGORY_REQUEST_CREATE_FAIL,
  CATEGORY_REQUEST_CREATE_RESET,
  CATEGORY_REQUEST_APROVE_REQUEST,
  CATEGORY_REQUEST_APROVE_SUCCESS,
  CATEGORY_REQUEST_APROVE_FAIL,
  CATEGORY_REQUEST_REJECT_REQUEST,
  CATEGORY_REQUEST_REJECT_SUCCESS,
  CATEGORY_REQUEST_REJECT_FAIL,
} from "../Constants/CategoryRequestConstants";

// CATEGORY REQUEST LIST
export const categoryRequestListReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_REQUEST_LIST_REQUEST:
      return { loading: true };
    case CATEGORY_REQUEST_LIST_SUCCESS:
      return { loading: false, success: true, requests: action.payload };
    case CATEGORY_REQUEST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE CATEGORY REQUEST
export const categoryRequestCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_REQUEST_CREATE_REQUEST:
      return { loading: true };
    case CATEGORY_REQUEST_CREATE_SUCCESS:
      return { loading: false, success: true, seller: action.payload };
    case CATEGORY_REQUEST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CATEGORY_REQUEST_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// APROVE CATEGORY REQUEST
export const categoryRequestAproveReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_REQUEST_APROVE_REQUEST:
      return { loading: true };
    case CATEGORY_REQUEST_APROVE_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_REQUEST_APROVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// APROVE CATEGORY REQUEST
export const categoryRequestRejectReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_REQUEST_REJECT_REQUEST:
      return { loading: true };
    case CATEGORY_REQUEST_REJECT_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_REQUEST_REJECT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};