import React from "react";
import Header from "../../components/client/Header";
import Footer from "../../components/client/Footer";
import BlogMain from "../../components/client/blog-components/BlogMain"

export default function BlogClientScreen() {
  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <BlogMain />
      <Footer />
    </>
  );
}
