export const MESSAGE_LIST_REQUEST = "MESSAGE_LIST_REQUEST";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_FAIL = "MESSAGE_LIST_FAIL";

export const NEW_MESSAGE_LIST_REQUEST = "NEW_MESSAGE_LIST_REQUEST";
export const NEW_MESSAGE_LIST_SUCCESS = "NEW_MESSAGE_LIST_SUCCESS";
export const NEW_MESSAGE_LIST_FAIL = "NEW_MESSAGE_LIST_FAIL";

export const NEW_MAIN_MESSAGE_LIST_REQUEST = "NEW_MAIN_MESSAGE_LIST_REQUEST";
export const NEW_MAIN_MESSAGE_LIST_SUCCESS = "NEW_MAIN_MESSAGE_LIST_SUCCESS";
export const NEW_MAIN_MESSAGE_LIST_FAIL = "NEW_MAIN_MESSAGE_LIST_FAIL";

export const MESSAGE_CREATE_REQUEST = "MESSAGE_CREATE_REQUEST";
export const MESSAGE_CREATE_SUCCESS = "MESSAGE_CREATE_SUCCESS";
export const MESSAGE_CREATE_FAIL = "MESSAGE_CREATE_FAIL";

export const REMOVE_MESSAGE_REQUEST = "REMOVE_MESSAGE_REQUEST";
export const REMOVE_MESSAGE_SUCCESS = "REMOVE_MESSAGE_SUCCESS";
export const REMOVE_MESSAGE_FAIL = "REMOVE_MESSAGE_FAIL";

export const EDIT_MESSAGE_REQUEST = "EDIT_MESSAGE_REQUEST";
export const EDIT_MESSAGE_SUCCESS = "EDIT_MESSAGE_SUCCESS";
export const EDIT_MESSAGE_FAIL = "EDIT_MESSAGE_FAIL";

export const ONLINE_USER_LIST_REQUEST = "ONLINE_USER_LIST_REQUEST";
export const ONLINE_USER_LIST_SUCCESS = "ONLINE_USER_LIST_SUCCESS";
export const ONLINE_USER_LIST_FAIL = "MESSAGE_LIST_FAIL";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const REMOVE_USER_REQUEST = "REMOVE_USER_REQUEST";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAIL = "REMOVE_USER_FAIL";
