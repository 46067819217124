import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import EditCategory from "../../components/dashboard/categories/EditCategory";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const CategoryEditScreen = ({ match }) => {
  const categoryId = match.params.id;
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <EditCategory categoryId={categoryId} />
      </PageWrapper>
    </>
  );
};
export default CategoryEditScreen;
