export const MATERIAL_LIST_REQUEST = "MATERIAL_LIST_REQUEST";
export const MATERIAL_LIST_SUCCESS = "MATERIAL_LIST_SUCCESS";
export const MATERIAL_LIST_FAIL = "MATERIAL_LIST_FAIL";

export const MATERIAL_CREATE_REQUEST = "MATERIAL_CREATE_REQUEST";
export const MATERIAL_CREATE_SUCCESS = "MATERIAL_CREATE_SUCCESS";
export const MATERIAL_CREATE_FAIL = "MATERIAL_CREATE_FAIL";
export const MATERIAL_CREATE_RESET = "MATERIAL_CREATE_RESET";

export const MATERIAL_EDIT_REQUEST = "MATERIAL_EDIT_REQUEST";
export const MATERIAL_EDIT_SUCCESS = "MATERIAL_EDIT_SUCCESS";
export const MATERIAL_EDIT_FAIL = "MATERIAL_EDIT_FAIL";
export const MATERIAL_EDIT_RESET = "MATERIAL_UPDATE_RESET";

export const MATERIAL_UPDATE_REQUEST = "MATERIAL_UPDATE_REQUEST";
export const MATERIAL_UPDATE_SUCCESS = "MATERIAL_UPDATE_SUCCESS";
export const MATERIAL_UPDATE_FAIL = "MATERIAL_EDIT_FAIL";
export const MATERIAL_UPDATE_RESET = "MATERIAL_UPDATE_RESET";

export const MATERIAL_DELETE_REQUEST = "MATERIAL_DELETE_REQUEST";
export const MATERIAL_DELETE_SUCCESS = "MATERIAL_DELETE_SUCCESS";
export const MATERIAL_DELETE_FAIL = "MATERIAL_DELETE_FAIL";