import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useImage } from "react-image";

export default function GridCards({ preview }) {
  const categoriesList = useSelector((state) => state.categoriesList);
  const { categories } = categoriesList;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const CategoryImage = React.memo(({ imgSrc, alt, preview }) => {
    const { src } = useImage({
      srcList: imgSrc,
    });

    return (
      <img
        style={{ height: preview === "card" ? "100%" : "auto" }}
        className="w-75"
        src={src}
        alt={alt}
      />
    );
  });

  return (
    <div className="w-100 site-bg py-5 ">
      <h3 className="text-center text-gray">KATEGORIJE</h3>
      <div
        style={{ height: "fit-content" }}
        className="category-grid-wrapper container mt-4 position-relative"
      >
        {categories?.map((category) => (
          <div
            key={category._id}
            className={`category-card position-relative border-1 overflow-hidden border-transition-hover-${theme}-500`}
          >
            <div className="category-overlay"></div>
            <Link
              style={{ width: preview === "card" ? "40%" : "auto" }}
              to={`/shop/${category.path}`}
            >
              <div
                style={{ height: preview === "card" ? "100%" : "auto" }}
                className="category-card-content shopBack position-relative"
              >
                <Suspense fallback={<span>Učitavanje...</span>}>
                  <CategoryImage
                    imgSrc={category.images[0]?.src}
                    alt={category.name}
                    preview={preview}
                  />
                </Suspense>
                <div className={`top-slide bg-${theme}-500`}></div>
              </div>
            </Link>

            <div
              className={`shoptext p-3 ${
                preview === "card" ? "d-flex px-1 flex-column flex-grow-1" : ""
              }`}
            >
              <p className="text-center text-uppercase">
                <Link
                  className={`text-${theme}-500`}
                  to={`/shop/${category.path}`}
                >
                  {category.title}
                </Link>
              </p>

              {/* {preview === "card" && (
              <div className="flex-grow-1">{parse(category.description)}</div>
            )} */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
