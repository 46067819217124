import {
  MATERIAL_LIST_REQUEST,
  MATERIAL_LIST_SUCCESS,
  MATERIAL_LIST_FAIL,
  MATERIAL_CREATE_REQUEST,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_CREATE_FAIL,
  MATERIAL_CREATE_RESET,
  MATERIAL_EDIT_REQUEST,
  MATERIAL_EDIT_SUCCESS,
  MATERIAL_EDIT_FAIL,
  MATERIAL_UPDATE_REQUEST,
  MATERIAL_UPDATE_SUCCESS,
  MATERIAL_UPDATE_FAIL,
  MATERIAL_UPDATE_RESET,
  MATERIAL_DELETE_REQUEST,
  MATERIAL_DELETE_SUCCESS,
  MATERIAL_DELETE_FAIL,
} from "../Constants/MaterialConstants";

// MATERIAL LIST
export const materialListReducer = (state = {}, action) => {
  switch (action.type) {
    case MATERIAL_LIST_REQUEST:
      return { loading: true };
    case MATERIAL_LIST_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case MATERIAL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE MATERIAL
export const materialCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MATERIAL_CREATE_REQUEST:
      return { loading: true };
    case MATERIAL_CREATE_SUCCESS:
      return { loading: false, success: true, material: action.payload };
    case MATERIAL_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MATERIAL_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// EDIT MATERIAL
export const materialEditReducer = (state = {}, action) => {
  switch (action.type) {
    case MATERIAL_EDIT_REQUEST:
      return { ...state, loading: true };
    case MATERIAL_EDIT_SUCCESS:
      return { loading: false, material: action.payload };
    case MATERIAL_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE MATERIAL
export const materialUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MATERIAL_UPDATE_REQUEST:
      return { loading: true };
    case MATERIAL_UPDATE_SUCCESS:
      return { loading: false, success: true, material: action.payload };
    case MATERIAL_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MATERIAL_UPDATE_RESET:
      return { category: {} };
    default:
      return state;
  }
};

// DELETE MATERIAL
export const materialDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MATERIAL_DELETE_REQUEST:
      return { loading: true };
    case MATERIAL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MATERIAL_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
