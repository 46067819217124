import { useMemo } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { isEmpty } from "lodash";

export default function ProductDescription({ description, tableData, productId }) {
  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const tableGenerator = useMemo(() => {
    if (tableData) {
      return (
        <table className="table table-hover table-bordered mb-0">
          <tbody>
            {tableData.map((row) => (
              <tr key={row.id}>
                {Object.values(row.columns).map((cel) => (
                  <td key={cel}>{cel}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  }, [tableData]);

  // Generate unique IDs
  const descriptionTabId = `nav-description-tab-${productId}`;
  const specificationsTabId = `nav-specifications-tab-${productId}`;
  const descriptionContentId = `nav-description-${productId}`;
  const specificationsContentId = `nav-specifications-${productId}`;

  return (
    <>
      {(description || !isEmpty(tableData)) && (
        <>
          <nav id={productId} className="mt-4">
            <div className="nav nav-tabs" id={`nav-tab-${productId}`} role="tablist">
              {description && (
                <button
                  className={`text-${theme} nav-link active`}
                  id={descriptionTabId}
                  data-bs-toggle="tab"
                  data-bs-target={`#${descriptionContentId}`}
                  type="button"
                  role="tab"
                  aria-controls={descriptionContentId}
                  aria-selected="false"
                >
                  Opis
                </button>
              )}
              {!isEmpty(tableData) && (
                <button
                  className={`text-${theme} nav-link ${!description ? "active" : ""}`}
                  id={specificationsTabId}
                  data-bs-toggle="tab"
                  data-bs-target={`#${specificationsContentId}`}
                  type="button"
                  role="tab"
                  aria-controls={specificationsContentId}
                  aria-selected="false"
                >
                  Specifikacije
                </button>
              )}
            </div>
          </nav>
          <div className="tab-content" id={`nav-tabContent-${productId}`}>
            {description && (
              <div
                style={{ borderColor: "#fff #dee2e6 #dee2e6" }}
                className="tab-pane p-4 border-1 text-gray bg-white fade show active"
                id={descriptionContentId}
                role="tabpanel"
                aria-labelledby={descriptionTabId}
              >
                {parse(description || "")}
              </div>
            )}
            {!isEmpty(tableData) && (
              <div
                style={{ borderColor: "#fff #dee2e6 #dee2e6" }}
                className={`tab-pane p-4 border-1 bg-white fade ${!description ? "active show" : ""}`}
                id={specificationsContentId}
                role="tabpanel"
                aria-labelledby={specificationsTabId}
              >
                {tableGenerator}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
