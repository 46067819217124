import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "react-css-styled";
// import ImagesReview from "../common/ImagesPreview";
import Toast from "../loading-error/Toast";
import { toast } from "react-toastify";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import {
  editCategory,
  updateCategory,
} from "../../../Redux/dashboard/Actions/CategoryActions";
import { CATEGORY_UPDATE_RESET } from "../../../Redux/dashboard/Constants/CategoryConstants";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const EditCategoryWrapper = styled(
  "div",
  `
  {
    margin: 3%;
  }
  `
);

const EditCategory = ({ categoryId }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const dispatch = useDispatch();

  const categoryEdit = useSelector((state) => state.categoryEdit);
  const { loading, error, category } = categoryEdit;

  const removeImage = (id) => {
    let _image = images.find((n) => (n.id || n.public_id) === id);

    console.log("REMOVE IMAGE:::", _image);
    if (_image.public_id) {
      setImagesToDelete((n) => [...n, id]);
      setImages((prevImages) =>
        prevImages.filter((img) => img.public_id !== id)
      );
    } else {
      setImages((prevImages) => prevImages.filter((img) => img.id !== id));
      setImageFiles((prevFiles) =>
        Array.from(prevFiles).filter((img) => img.id !== id)
      );
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateCategory({ _id: categoryId, title, description, images, imageFiles, imagesToDelete })
    );
  };

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = categoryUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CATEGORY_UPDATE_RESET });
      toast.success("Category Updated", ToastObjects);
    } else {
      if (!category?.title || category?._id !== categoryId) {
        dispatch(editCategory(categoryId));
      } else {
        setTitle(category.title);
        setDescription(category.description);
        setImages(category.images);
      }
    }
  }, [category, dispatch, categoryId, successUpdate]);

  useEffect(() => {
    console.log("IMAGES::::::", images);
  }, [images]);

  return (
    <>
      <Toast />

      {errorUpdate && <Message variant="alert-danger">{errorUpdate}</Message>}
      {loadingUpdate && <Loading position="position-absolute" width="100%" height="100%" />}
      {loading ? (
        <Loading position="position-absolute" width="100%" height="100%" />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <EditCategoryWrapper>
          <form onSubmit={submitHandler}>
            <div className="mb-4">
              <label htmlFor="product_name" className="form-label">
                Title
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="form-control py-3"
                id="product_name"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {/* <div className="mb-4">
              <label className="form-label">Images</label>
              <ImagesReview
                images={images}
                imageFiles={imageFiles}
                setImages={setImages}
                setImageFiles={setImageFiles}
                removeImage={removeImage}
              />
            </div> */}
            <div className="mb-4">
              <label className="form-label">Description</label>
              <textarea
                placeholder="Type here"
                className="form-control"
                rows="4"
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="d-grid mt-3">
              <button className="btn btn-primary py-3">Update category</button>
            </div>
          </form>
        </EditCategoryWrapper>
      )}
    </>
  );
};

export default EditCategory;
