import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  return (
    <div className={`footer bg-${theme}-100`}>
      <div className="justify-content-center d-flex">
        <div className="card-name">
          <img
            alt="mastercard"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="visa"
            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="paypal"
            src="https://image.similarpng.com/very-thumbnail/2020/06/Logo-PayPal-transparent-PNG.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="express"
            src="https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/American-Express-icon.png"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
