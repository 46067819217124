import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PRODUCT_CREATE_RESET } from "../../../Redux/dashboard/Constants/ProductConstants";
import {
  createProduct,
  listProducts,
} from "../../../Redux/dashboard/Actions/ProductActions";
import { listCategories } from "../../../Redux/dashboard/Actions/CategoryActions";
// import { listSellers } from "../../../Redux/dashboard/Actions/SellerActions";
import Toast from "../loading-error/Toast";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import CategoryTree from "../common/CategoryTree";
import { Section, Card, CardBody } from "../common";
import Dimensions from "./Dimensions";
import SortableImages from "../common/sortable/SortableImages";
import ProductDescription from "./description/ProductDescription";
import { listBrands } from "../../../Redux/dashboard/Actions/BrandActions";
import Input from "../ui/form/Input";
import Select from "../ui/form/Select";
import { listMaterials } from "../../../Redux/dashboard/Actions/MaterialActions";
import ChooseProducts from "../common/ChooseProducts";

const AddProductMain = () => {
  const [images, setImages] = useState([]);
  const [productData, setProductData] = useState({
    targetCategory: "",
    name: "",
    secretName: "",
    buildingDaysNum: 1,
    price: "",
    brand: "",
    material: "",
    discount: { value: "", type: "%" },
    dimensionsType: "none",
    specifiedDimensions: [],
    freeDimensions: {
      minWidth: "",
      maxWidth: "",
      minLength: "",
      maxLength: "",
      minHeight: "",
      maxHeight: "",
    },
    similarProducts: [],
    countInStock: "",
    description: "",
    descriptionTable: [],
    showOnHome: false,
    isNew: false,
    isActive: true,
    meta: "",
  });

  const {
    targetCategory,
    name,
    secretName,
    buildingDaysNum,
    price,
    brand,
    material,
    discount,
    dimensionsType,
    freeDimensions,
    specifiedDimensions,
    description,
    descriptionTable,
    countInStock,
    showOnHome,
    isActive,
    isNew,
    meta,
    similarProducts,
  } = productData;

  const dispatch = useDispatch();

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const brandData = useSelector((state) => state.brandList);
  const { loading: loadingBrands, error: errorBrands, brands } = brandData;

  const materialData = useSelector((state) => state.materialList);
  const {
    loading: loadingMaterials,
    error: errorMaterials,
    materials,
  } = materialData;

  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product } = productCreate;

  const categoriesListObj = useSelector((state) => state.categoriesList);
  const { loading: loadingCategories, error: errorCategories } =
    categoriesListObj;

  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    theme,
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!productData.targetCategory) {
      toast.error("Izaberite kategoriju.", ToastObjects);
      return;
    }

    if (images.length === 0) {
      toast.error("Dodajte slike.", ToastObjects);
      return;
    }

    dispatch(
      createProduct({
        ...productData,
        images,
        path: productData.targetCategory.path,
        similarProducts: similarProducts.map((n) => n._id),
      })
    );
  };

  useEffect(() => {
    if (product) {
      toast.success("Proizvod je sacuvan.", ToastObjects);
      dispatch({ type: PRODUCT_CREATE_RESET });
      setProductData({
        targetCategory: "",
        name: "",
        secretName: "",
        buildingDaysNum: 1,
        price: "",
        brand: "",
        material: "",
        discount: { value: 0, type: "%" },
        dimensionsType: "none",
        freeDimensions: {
          minWidth: "",
          maxWidth: "",
          minLength: "",
          maxLength: "",
          minHeight: "",
          maxHeight: "",
        },
        specifiedDimensions: [],
        similarProducts: [],
        countInStock: "",
        description: "",
        descriptionTable: [],
        showOnHome: false,
        isNew: false,
        isActive: true,
        meta: "",
      });
      setImages([]);

      dispatch(listProducts({ pageNumber: "all" }));
    }
  }, [product, dispatch]);

  useEffect(() => {
    dispatch(listCategories({ filtered: true }));
    dispatch(listBrands({ pageNumber: "1", keyword: "" }));
    dispatch(listMaterials());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  return (
    <>
      <Toast />
      <Section className="position-relative">
        <form onSubmit={submitHandler}>
          <h2 className="content-title flex-grow-1 m-0 mb-4 text-center">
            Kreiraj proizvod
          </h2>
          <div className="content-header d-flex flex-row gap-2 mb-4">
            <Link
              style={{ width: "132px" }}
              to="/dashboard-products"
              className="btn btn-danger text-white"
            >
              Idi na proizvode
            </Link>
            <div>
              <button
                style={{ width: "132px" }}
                type="submit"
                className="btn btn-primary"
              >
                Sacuvaj
              </button>
            </div>
          </div>

          <div className="mb-4">
            <div className="p-0 m-auto">
              <Card className="mb-4 shadow-sm">
                <CardBody className="row gx-0 p-3">
                  {(error ||
                    errorCategories ||
                    errorBrands ||
                    errorMaterials) && (
                    <Message variant="alert-danger">
                      {error ||
                        errorCategories ||
                        errorBrands ||
                        errorMaterials}
                    </Message>
                  )}
                  {(loading ||
                    loadingCategories ||
                    loadingBrands ||
                    loadingMaterials) && (
                    <Loading
                      position="position-absolute"
                      width="100%"
                      height="100%"
                    />
                  )}

                  <div className="mb-4">
                    <h5 className="d-block text-center mb-3">
                      Izaberi kategoriju
                    </h5>
                    <CategoryTree
                      onlyEndChild={true}
                      targetCategory={targetCategory}
                      setTargetCategory={(targetCategory) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          targetCategory,
                        }))
                      }
                    />
                  </div>

                  <div className="d-flex mb-4 flex-wrap gap-2">
                    <Input
                      id="product_seller"
                      className="flex-grow-1"
                      type="text"
                      label="Prodavac"
                      disabled={true}
                      value={userInfo?.name}
                    />

                    <Input
                      id="name"
                      className="flex-grow-1"
                      type="text"
                      label="Naziv"
                      required={true}
                      value={name}
                      onChange={handleInputChange}
                    />

                    <Input
                      id="secretName"
                      className="flex-grow-1"
                      type="text"
                      label="Tajni Naziv"
                      required={true}
                      value={secretName}
                      onChange={handleInputChange}
                    />

                    <Input
                      id="price"
                      className="flex-grow-1"
                      type="number"
                      label="Cena"
                      required={true}
                      value={price}
                      onChange={handleInputChange}
                    />

                    <Input
                      id="countInStock"
                      className="flex-grow-1"
                      type="number"
                      label="Na stanju"
                      required={true}
                      value={countInStock}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-4 d-flex flex-wrap gap-2">
                    <Input
                      id="buildingDaysNum"
                      className="flex-grow-1"
                      type="number"
                      label="Broj dana za izradu"
                      required={true}
                      value={buildingDaysNum}
                      onChange={handleInputChange}
                    />
                    <Select
                      id="brand"
                      label="Brend"
                      className="flex-grow-1"
                      value={brand}
                      onChange={handleInputChange}
                    >
                      <option value=""></option>
                      {brands?.brands.map((brand) => (
                        <option key={brand._id} value={brand.title}>
                          {brand.title}
                        </option>
                      ))}
                    </Select>
                    <Select
                      id="material"
                      label="Materijal"
                      className="flex-grow-1"
                      value={material}
                      onChange={handleInputChange}
                    >
                      <option value=""></option>
                      {materials?.map((material) => (
                        <option key={material._id} value={material.title}>
                          {material.title}
                        </option>
                      ))}
                    </Select>
                    <div className="flex-grow-1 d-flex gap-1 align-items-end">
                      <Input
                        id="discount"
                        type="number"
                        className="flex-grow-1"
                        label="Popust"
                        value={discount?.value}
                        onChange={(e) => {
                          setProductData((prevState) => ({
                            ...prevState,
                            discount: {
                              ...prevState.discount,
                              value: e.target.value,
                            },
                          }));
                        }}
                      />

                      <Select
                        id="discount_type"
                        value={discount?.type || ""}
                        onChange={(e) => {
                          setProductData((prevState) => ({
                            ...prevState,
                            discount: {
                              ...prevState.discount,
                              type: e.target.value,
                            },
                          }));
                        }}
                      >
                        <option>%</option>
                        <option>RSD</option>
                      </Select>
                    </div>
                  </div>

                  <Dimensions
                    dimensions={freeDimensions}
                    setDimensions={(freeDimensions) =>
                      setProductData((prevState) => ({
                        ...prevState,
                        freeDimensions,
                      }))
                    }
                    dimensionsType={dimensionsType}
                    setDimensionsType={(dimensionsType) =>
                      setProductData((prevState) => ({
                        ...prevState,
                        dimensionsType,
                      }))
                    }
                    items={specifiedDimensions}
                    setItems={(specifiedDimensions) =>
                      setProductData((prevState) => ({
                        ...prevState,
                        specifiedDimensions,
                      }))
                    }
                  />

                  <hr className="hr hr-blurry" />

                  <ProductDescription
                    value={description}
                    tableValue={descriptionTable}
                    onTableChange={(descriptionTable) =>
                      setProductData((prevState) => ({
                        ...prevState,
                        descriptionTable,
                      }))
                    }
                    onChange={(description) =>
                      setProductData((prevState) => ({
                        ...prevState,
                        description,
                      }))
                    }
                  />

                  <div className="mb-4">
                    <label className="form-label">Slike</label>
                    <SortableImages
                      images={images}
                      setImages={setImages}
                      multiple={true}
                    />
                  </div>

                  <div className="mb-4">
                    <ChooseProducts
                      title="Slicni proizvodi"
                      choosenProducts={similarProducts}
                      setChoosenProducts={(similarProducts) =>
                        setProductData((prevState) => ({
                          ...prevState,
                          similarProducts,
                        }))
                      }
                    />
                  </div>

                  <div className="form-check form-switch">
                    <input
                      id="showOnHome"
                      className="form-check-input"
                      type="checkbox"
                      checked={showOnHome}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="showOnHome">
                      Prikazi na pocetnoj strani
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      id="isNew"
                      className="form-check-input"
                      type="checkbox"
                      checked={isNew}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="isNew">
                      Prikaci bedz "NOVO"
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      id="isActive"
                      className="form-check-input"
                      type="checkbox"
                      checked={isActive}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="isActive">
                      {isActive ? "Deaktiviraj proizvod" : "Aktiviraj proizvod"}
                    </label>
                  </div>

                  <div className="form-floating mt-4">
                    <textarea
                      id="meta"
                      className={`${`form-control ${
                        theme === "dark" ? "dark-theme-section text-light" : ""
                      }`}`}
                      placeholder="Leave a comment here"
                      value={meta}
                      onChange={handleInputChange}
                    ></textarea>
                    <label htmlFor="meta">Meta</label>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </form>
      </Section>
    </>
  );
};

export default AddProductMain;
