import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/client/Header";
import ProfileTabs from "../../components/client/profile-components/ProfileTabs";
import { getUserDetails } from "../../Redux/client/Actions/UserActions";
import Orders from "../../components/client/profile-components/Orders";
import moment from "moment";
import { listMyOrders } from "../../Redux/client/Actions/OrderActions";
import Loading from "../../components/client/loading-error/Loading";

const ProfileScreen = () => {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders } = orderListMy;

  const { theme } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(listMyOrders());
    dispatch(getUserDetails("profile"));
  }, [dispatch]);

  return (
    <>
      <Header />
      <div className="container mt-lg-5 mt-3">
        <div className="row align-items-start">
          <div className="col-lg-4 p-0 border border-1">
            <div className="author-card pb-0 pb-md-3">
              <div className={`author-card-cover bg-${theme}-200`}></div>
              <div className="author-card-profile row">
                <div className="author-card-avatar site-bg col-md-5 bg-white">
                  <div className="img-holder">
                    <img src={userInfo?.avatarImage.url} alt="userprofileimage" />
                  </div>
                </div>
                <div className="author-card-details col-md-7">
                  <h5 className="author-card-name mb-2">
                    <strong>{userInfo.name}</strong>
                  </h5>
                  <span className="author-card-position">
                    <>
                      Pridruzio-la se {moment(userInfo.createdAt).format("LL")}
                    </>
                  </span>
                </div>
              </div>
            </div>
            <div className="wizard pt-3">
              <div className="d-flex align-items-start">
                <div
                  className="nav align-items-start flex-column col-12 nav-pills me-3 "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Podesavanja Profila
                  </button>
                  <button
                    className="nav-link d-flex justify-content-between"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Porudzbine
                    <span className="badge2">{orders ? orders.length : 0}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* panels */}
          <div
            className="tab-content col-lg-8 pb-md-0 pb-sm-5 pt-lg-0 pt-3 px-md-1 px-sm-0"
            id="v-pills-tabContent"
          >
            <div
              className="tab-pane px-lg-2 px-0 site-bg  pt-0 fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <ProfileTabs />
            </div>
            <div
              className="tab-pane site-bg fade pt-0"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <Orders orders={orders} loading={loading} error={error} />
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ProfileScreen;
