import React from "react";
import Sidebar from "./../../components/dashboard/Sidebar";
import Header from "./../../components/dashboard/Header";
import OrderMain from "../../components/dashboard/orders/OrderMain";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const OrderScreen = () => {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <OrderMain />
      </PageWrapper>
    </>
  );
};

export default OrderScreen;
