import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "../common";
import { Section } from "../common";
import CreateEditBlogModal from "./CreateEditBlogModal";
import CenteredModal from "../common/Modal";
import { useDispatch, useSelector } from "react-redux";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import BlogFilters from "./BlogFilters";
import BlogCard from "./BlogCard";
import {
  deleteBlog,
  listBlogs,
} from "../../../Redux/dashboard/Actions/BlogActions";
import Pagination from "../common/Pagination";

export default function MainBlogs() {
  const [showEdit, setShowEdit] = useState(false);
  const [type, setType] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [blogId, setBlogId] = useState("");

  const dispatch = useDispatch();

  const blogData = useSelector((state) => state.blogList);
  const { loading, error, page, pages, prevKeyword, prevSortBy, blogs } =
    blogData;

  const blogAddData = useSelector((state) => state.blogCreate);
  const { loading: loadingAdd, error: errorAdd } = blogAddData;

  const blogUpdateData = useSelector((state) => state.blogUpdate);
  const { loading: loadingUpdate, error: errorUpdate } = blogUpdateData;

  const blogDeleteData = useSelector((state) => state.blogDelete);
  const { loading: loadingDelete, errorDelete } = blogDeleteData;

  const submitHandler = ({ pageNumber, keyword, sort }) => {
    dispatch(listBlogs({ pageNumber, keyword, sortBy: sort }));
  };
  const deleteHandler = (id) => {
    dispatch(deleteBlog(id));
    setBlogId("");
  };

  useEffect(() => {
    if (dispatch) {
      submitHandler({ pageNumber: 1, keyword: "" });
    }
  }, [dispatch]);

  return (
    <>
      <Section className="position-relative">
        <div className="content-header flex-row">
          <h2 className="content-title">Blogs</h2>
          <button
            className="btn btn-primary"
            onClick={() => {
              setType("add");
              setShowEdit(true);
            }}
          >
            Kreiraj novi
          </button>
        </div>

        <Card section className="shadow-sm">
          <CardHeader section>
            <BlogFilters submitHandler={submitHandler} />
          </CardHeader>
          <CardBody section>
            {(error || errorAdd || errorUpdate || errorDelete) && (
              <Message variant="alert-danger">
                {error || errorAdd || errorUpdate || errorDelete}
              </Message>
            )}
            <div className="dashboard-product-grid-wrapper">
              {blogs?.map((blog) => (
                <BlogCard
                  key={blog._id}
                  blog={blog}
                  setShow={setShowDeleteModal}
                  setType={setType}
                  setShowEdit={setShowEdit}
                  setDeleteId={setBlogId}
                />
              ))}
            </div>
          </CardBody>

          <div className="d-flex justify-content-end">
            <Pagination
              page={page}
              pages={pages}
              keyword={prevKeyword}
              sort={prevSortBy}
              submitHandler={submitHandler}
            />
          </div>
        </Card>
        {(loading || loadingAdd || loadingUpdate || loadingDelete) && (
          <Loading position="position-absolute" width="100%" height="100%" />
        )}
      </Section>
      <CreateEditBlogModal
        blogid={blogId}
        show={showEdit}
        type={type}
        onHide={() => {
          setBlogId("");
          setShowEdit(false);
        }}
      />
      <CenteredModal
        title="Da li sigurno zelis da obrises ovaj blog?"
        show={showDeleteModal}
        onHide={() => {
          setBlogId("");
          setShowDeleteModal(false);
        }}
        reqid={blogId}
        submithandler={{ call: deleteHandler }}
      />
    </>
  );
}
