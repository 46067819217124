import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

export default function CenteredModal(props) {
  const [description, setDescription] = useState("");
  const { submithandler, reqid, onHide } = props;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
    >
      <Modal.Header className={!props.showDetails ? "border-0" : ""} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      {props.showDetails && (
        <Modal.Body>
          <div className="mb-4">
            <label htmlFor="details" className="form-label">
              Detalji
            </label>
            <textarea
              placeholder="Detalji o odluci"
              className={`form-control ${
                theme === "dark" ? "dark-theme-section" : ""
              }`}
              id="details"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button
          disabled={props.showDetails && !description}
          onClick={() => {
            submithandler.call(reqid, description);
            onHide();
          }}
        >
          Potvrdi
        </Button>
        <Button variant="danger" onClick={props.onHide}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
