import React from "react";

const Message = ({ variant, className, children }) => {
  return <div className={`alert rounded-0 ${className} ${variant}`}>{children}</div>;
};

Message.defaultProps = {
  variant: "alert-info",
};

export default Message;
