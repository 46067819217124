import React, { forwardRef, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "./sortable.scss";
import { useSelector } from "react-redux";
import { removeBackground } from "@imgly/background-removal";
import ReactProfile from "react-profile";
import "react-profile/themes/dark.min.css";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import Loading from "../../loading-error/Loading";

const SortableImages = forwardRef(
  ({ images, setImages, setImagesToDelete, setBtnsEnabled, multiple }, ref) => {
    const [imageToEdit, setImageToEdit] = useState({});
    let images_length = images?.filter((img) => img.public_id).length || 0;

    const onSortEnd = (oldIndex, newIndex) => {
      setBtnsEnabled && setBtnsEnabled(true);
      setImages((array) => arrayMoveImmutable(array, oldIndex, newIndex));
      setImages((prevImages) =>
        prevImages.map((img, i) => {
          img.index = i;
          return img;
        })
      );
    };

    const addActionRemoveImage = (id) => {
      let index = 0;
      const newImages = images.filter((image) => {
        if (image.id !== id) {
          image.index = index;
          index++;
          return image;
        }
      });
      setImages(newImages);
    };

    const editActionRemoveImage = (id) => {
      setBtnsEnabled && setBtnsEnabled(true);
      let _image = images.find((n) => (n.id || n.public_id) === id);
      let index = 0;

      if (_image.public_id) {
        setImagesToDelete((n) => [...n, id]);
        const newImages = images.filter((image) => {
          if (image.public_id !== id) {
            image.index = index;
            index++;
            return image;
          }
        });
        setImages(newImages);
      } else {
        const newImages = images.filter((image) => {
          if (image.id !== id) {
            image.index = index;
            index++;
            return image;
          }
        });
        setImages(newImages);
      }
    };

    const themeData = useSelector((state) => state.theme);
    const { theme } = themeData;

    const readImage = (file, id) => {
      if (!file.type || !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        const imageObj = {
          index: images_length,
          src: event.target.result,
          id: id,
          progress: 0,
        };
        images_length++;
        setImages((prevImages) => [...prevImages, imageObj]);
      });
      reader.readAsDataURL(file);
    };

    const onProgress = ({ current, total, id }) => {
      const progressPercentage = (current / total) * 100;
      setImages((prevImages) => {
        return prevImages.map((image) =>
          image.id === id ? { ...image, progress: progressPercentage } : image
        );
      });
    };

    const readAndRemoveBgImage = (image, id) => {
      const config = {
        progress: (key, current, total) => onProgress({ current, total, id }),
        output: {
          format: "image/png",
          quality: 0.8,
        },
      };

      // Set initial state for the image (e.g., setting `removingBg` to true)
      setImages((prevImages) =>
        prevImages.map((img) =>
          img.id === id ? { ...img, removingBg: true, progress: 0 } : img
        )
      );

      // Function to handle background removal and cleanup
      const processBackgroundRemoval = async () => {
        try {
          const blob = await removeBackground(image.src, config);

          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result;

            const updatedImage = {
              ...image,
              src: base64String,
              removingBg: false,
              bgRemoved: true,
              progress: 100,
            };

            setImages((prevImages) =>
              prevImages.map((img) => (img.id === id ? updatedImage : img))
            );
          };

          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error removing background:", error);

          // Reset the state in case of error
          setImages((prevImages) =>
            prevImages.map((img) =>
              img.id === id ? { ...img, removingBg: false, progress: 0 } : img
            )
          );
        } finally {
          // Clean up resources or handle any final steps if needed
          console.log(
            `Background removal process for image ${id} is complete.`
          );
        }
      };

      // Call the process function
      return processBackgroundRemoval();
    };

    const pickFiles = async (files) => {
      setBtnsEnabled && setBtnsEnabled(true);
      setImages((prevImages) => prevImages.filter((img) => img.public_id));

      for (const [key, value] of Object.entries(files)) {
        const _id = uuidv4();
        const imageFile = value;
        imageFile.id = _id;

        try {
          readImage(imageFile, _id);
          // readAndRemoveBgImage(imageFile, _id);
        } catch (error) {
          console.error("Error processing image:", error);
        }
      }
    };

    return (
      <>
        <input
          ref={ref}
          className={`form-control ${
            theme === "dark" ? "dark-theme-section text-light" : ""
          }`}
          type="file"
          value=""
          multiple={multiple || false}
          onChange={(e) => pickFiles(e.target.files)}
        />
        {!isEmpty(images) && (
          <SortableList
            onSortEnd={onSortEnd}
            className="sortable-image-list mt-2"
            draggedItemClassName="dragged"
          >
            {images?.map((image) => (
              <SortableItem key={image.id || image._id}>
                <div
                  style={{ backgroundImage: `url(${image.src})` }}
                  className="sortable-image"
                >
                  <div className="img-actions">
                    {!image.public_id && (
                      <button
                        title="Edit"
                        className="btn img-action remove-img-bg text-success"
                        onClick={(e) => {
                          e.preventDefault();
                          setImageToEdit(image);
                        }}
                      >
                        <i className="fa fa-pen"></i>
                      </button>
                    )}

                    {!image.public_id && (
                      <button
                        title="Remove background"
                        className="btn img-action remove-img-bg text-success"
                        disabled={image.bgRemoved}
                        onClick={(e) => {
                          e.preventDefault();
                          readAndRemoveBgImage(image, image.id);
                        }}
                      >
                        <i className="fa fa-image"></i>
                      </button>
                    )}
                    <button
                      title="Remove"
                      className="btn img-action remove-img text-danger"
                      onClick={(e) => {
                        e.preventDefault();

                        if (images.length === 1) return;
                        if (!setImagesToDelete) {
                          addActionRemoveImage(image.id || image.public_id);
                        } else {
                          editActionRemoveImage(image.id || image.public_id);
                        }
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                  {image.removingBg && (
                    <div className="loading-overlay position-relative">
                      {/* <div className="loading-text">Removing Bg...</div>
                      <div className="progress-bar">
                        <div
                          className="progress-inner bg-primary"
                          style={{ width: `${image.progress}%` }}
                        ></div>
                      </div> */}
                      <Loading position="absolute" width="100%" height="100%" />
                    </div>
                  )}
                </div>
              </SortableItem>
            ))}
          </SortableList>
        )}
        {imageToEdit.src && (
          <ReactProfile
            src={imageToEdit.src}
            onDone={(src) => {
              setImageToEdit({});
              setImages((prev) =>
                prev.map((img) =>
                  img.id === imageToEdit.id
                    ? { ...img, src: src.getDataURL() }
                    : img
                )
              );
            }}
            onCancel={() => setImageToEdit({})}
          />
        )}
      </>
    );
  }
);

export default SortableImages;
