import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBrand,
  listBrands,
} from "../../../Redux/dashboard/Actions/BrandActions";
import EditBrandModal from "./EditBrandModal";
import CenteredModal from "../common/Modal";

export default function BrandsTable() {
  const [brand, setBrand] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const brandsData = useSelector((state) => state.brandList);
  const { brands } = brandsData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  const onEditClick = (brand) => {
    setBrand(brand);
    setShowEditModal(true);
  };

  const onDeleteClick = (brand) => {
    setBrand(brand);
    setShowDeleteModal(true);
  };

  const submitHandler = (id) => {
    dispatch(deleteBrand(id));
  };

  useEffect(() => {
    if (dispatch) {
      dispatch(listBrands({ pageNumber: 1, keyword: "" }));
    }
  }, [dispatch]);

  return (
    <>
      <table
        className={`table orders-table ${theme === "dark" ? "bg-dark" : ""}`}
      >
        <thead>
          <tr
            className={`${
              theme === "dark" ? "text-light dark-theme-hover" : ""
            }`}
          >
            <th scope="col">Naziv</th>
            <th scope="col">Logo</th>
            <th scope="col" className="text-end">
              Akcije
            </th>
          </tr>
        </thead>
        <tbody>
          {brands?.brands.map((brand) => (
            <tr
              className={`${
                theme === "dark" ? "text-light dark-theme-hover" : ""
              }`}
              key={brand?._id}
            >
              <td>{brand?.title}</td>
              <td>
                <img src={brand?.image.src} width={100} alt="brand logo" />
              </td>
              <td className="d-flex justify-content-end align-item-center gap-1">
                <button
                  className={`btn text-success ${
                    theme === "dark" ? "btn-dark" : ""
                  }`}
                  onClick={() => onEditClick(brand)}
                >
                  <i className="fas fa-pen"></i>
                </button>
                <button
                  className={`btn text-danger ${
                    theme === "dark" ? "btn-dark" : ""
                  }`}
                  onClick={() => onDeleteClick(brand)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <EditBrandModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        brand={brand}
      />
      <CenteredModal
        title="Da li sigurno zelis da obrises ovaj brend??"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        reqid={brand._id}
        submithandler={{ call: submitHandler }}
      />
    </>
  );
}
