import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listUser } from "../../../Redux/dashboard/Actions/UserActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import { Section } from "../common";
import Input from "../ui/form/Input";
import Select from "../ui/form/Select";
import CreateUserModal from "./CreateUserModal";
import { debounce } from "lodash";
import Pagination from "../common/Pagination";

export default function UserComponent() {
  const [modalShow, setModalShow] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    keyword: "",
    status: "all",
    toShow: 12,
  });

  const { pageNumber, keyword, status, toShow } = filters;

  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, page, pages, prevKeyword, prevStatus, show, users } =
    userList;

  const createUser = useSelector((state) => state.createUser);
  const { loading: createLoading, error: createError } = createUser;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleSetFilter = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }));
  };

  const submitHandler = ({ pageNumber, keyword, status, toShow }) => {
    dispatch(listUser({ pageNumber, keyword, status, toShow }));
  };

  const memoizedSearch = useMemo(() => {
    const debounceSearch = debounce(async (query) => {
      handleSetFilter("keyword", query);
      submitHandler({ pageNumber, keyword: query, status, toShow });
    }, 500);

    return debounceSearch;
  }, [dispatch]);

  const search = (query) => {
    memoizedSearch(query);
  };

  useEffect(() => {
    setFirstRender(false);
    if (firstRender) return;

    submitHandler({ pageNumber, keyword, status, toShow });
  }, [status, toShow, dispatch, firstRender]);

  return (
    <>
      <Section>
        <div className="content-header flex-row">
          <h2 className="content-title">Korisnici</h2>
          {userInfo.isSuperAdmin && (
            <div>
              <Link
                to="#"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setModalShow(true);
                }}
              >
                <i className="material-icons md-plus"></i> Kreiraj novog
              </Link>
            </div>
          )}
        </div>

        <div
          className={`card mb-4 ${
            theme === "dark" ? "dark-theme-section border-light" : ""
          }`}
        >
          <header className="card-header">
            <div className="row gx-3">
              <div className="col-lg-4 col-md-6 me-auto px-0">
                <Input
                  id="search"
                  type="search"
                  placeholder="Pretraga..."
                  inputClass={`form-control ${
                    theme === "dark" ? "bg-dark text-light" : ""
                  }`}
                  onChange={(e) => search(e.target.value)}
                />
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <Select
                  id="items_to_show"
                  inputClass={`form-select ${
                    theme === "dark" ? "bg-dark text-light" : ""
                  }`}
                  onChange={(e) => handleSetFilter("toShow", e.target.value)}
                >
                  <option value={12}>Prikazi 12</option>
                  <option value={20}>Prikazi 20</option>
                  <option value={40}>Prikazi 40</option>
                  <option value="all">Prikazi all</option>
                </Select>
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <Select
                  id="filter_by_status"
                  inputClass={`form-select ${
                    theme === "dark" ? "bg-dark text-light" : ""
                  }`}
                  onChange={(e) => handleSetFilter("status", e.target.value)}
                >
                  <option value="all">Status: svi</option>
                  <option value="active">Samo aktivni</option>
                  <option value="disabled">Samo blokirani</option>
                </Select>
              </div>
            </div>
          </header>

          {/* Card */}
          <div className="card-body">
            {loading || createLoading ? (
              <Loading
                position="position-absolute"
                width="100%"
                height="100%"
              />
            ) : error || createError ? (
              <Message variant="alert-danger">{error || createError}</Message>
            ) : (
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
                {users.map(
                  (user) =>
                    user._id !== userInfo._id && (
                      <div className="col px-0" key={user._id}>
                        <div
                          className={`card card-user shadow-sm ${
                            theme === "dark" ? "bg-dark text-white" : ""
                          }`}
                        >
                          <div className="card-header">
                            <img
                              className="img-md img-avatar"
                              src={user.avatarImage?.url}
                              alt="User pic"
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title mt-5">{user.name}</h5>
                            <div className="card-text text-muted">
                              {user.isAdmin === true ? (
                                <p className="m-0">Admin</p>
                              ) : user.isSuperAdmin == true ? (
                                <p className="m-0">Super Admin</p>
                              ) : (
                                <p className="m-0">Korisnik</p>
                              )}

                              <p>
                                <Link
                                  className={
                                    theme === "dark" ? "text-white" : ""
                                  }
                                  to={`/users/${user._id}`}
                                >
                                  {user.email}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end px-3">
            <Pagination
              page={page}
              pages={pages}
              keyword={prevKeyword}
              status={prevStatus}
              toShow={show}
              submitHandler={submitHandler}
            />
          </div>
        </div>
      </Section>

      <CreateUserModal show={modalShow} setShow={setModalShow} />
    </>
  );
}
