import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Pagination({
  sellerId,
  page,
  pages,
  keyword,
  brands,
  slug,
  toShow,
  sortBy,
  submitHandler,
}) {
  const [linkArray, setLinkArray] = useState([]);

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const pageClick = (e, nextPage) => {
    e.preventDefault();
    if (page === nextPage) return;

    window.scrollTo(0, 0);

    submitHandler({
      pageNumber: nextPage,
      keyword,
      slug,
      toShow,
      brands: brands?.toString(),
      sortBy,
      sellerId,
    });
  };

  useEffect(() => {
    const generatePaginationArray = (currentPage, totalPages) => {
      const maxLinks = 4;
      const paginationArray = [];

      if (totalPages <= maxLinks) {
        for (let i = 1; i <= totalPages; i++) {
          paginationArray.push(i);
        }
      } else {
        if (currentPage <= 2) {
          for (let i = 1; i <= 3; i++) {
            paginationArray.push(i);
          }
          paginationArray.push('...', totalPages);
        } else if (currentPage >= totalPages - 1) {
          paginationArray.push(1, '...');
          for (let i = totalPages - 2; i <= totalPages; i++) {
            paginationArray.push(i);
          }
        } else {
          paginationArray.push(1, '...');
          for (let i = currentPage - 1; i <= currentPage + 1; i++) {
            paginationArray.push(i);
          }
          paginationArray.push('...', totalPages);
        }
      }

      return paginationArray;
    };

    setLinkArray(generatePaginationArray(page, pages));
  }, [page, pages]);

  return (
    pages >= 1 && (
      <nav className="py-4">
        <ul className="pagination gap-1 justify-content-center">
          {linkArray.map((n, index) => (
            <li
              className={`page-item rounded ${
                n === page ? `bg-${theme}-200` : `bg-${theme}-500`
              }`}
              key={index}
            >
              {n === '...' ? (
                <span className="d-block text-light position-relative text-decoration-none px-4 py-2">
                  {n}
                </span>
              ) : (
                <Link
                  className="d-block text-light position-relative text-decoration-none px-4 py-2"
                  to="#"
                  onClick={(e) => pageClick(e, n)}
                >
                  {n}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    )
  );
}
