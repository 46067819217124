import React from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

const ContactInfo = () => {
  const settingsData = useSelector((state) => state.settings);
const { theme, contactSection } = settingsData;

  return (
    <div className="site-bg py-5">
      <div className="contactInfo container">
        <div className="contact-info-grid">
          <div>
            <div className={`box-info w-100 border-${theme}`}>
              <div
                className={`info-image border-${theme}-500 text-${theme}-500`}
              >
                <i className="fas fa-phone-alt"></i>
              </div>
              {parse(contactSection?.contact || "")}
            </div>
          </div>
          <div>
            <div className={`box-info w-100 border-${theme}`}>
              <div
                className={`info-image border-${theme}-500 text-${theme}-500`}
              >
                <i className="fas fa-map-marker-alt"></i>
              </div>
              {parse(contactSection?.address || "")}
            </div>
          </div>
          <div>
            <div className={`box-info w-100 border-${theme}`}>
              <div
                className={`info-image border-${theme}-500 text-${theme}-500`}
              >
                <i className="fas fa-fax"></i>
              </div>
              {parse(contactSection?.fax || "")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
