import {
  MAIL_SEND_REQUEST,
  MAIL_SEND_SUCCESS,
  MAIL_SEND_FAIL,
} from "../Constants/PostConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// SEND MAIL TO FOLLOWER
export const sendMail =
  ({ mailData }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MAIL_SEND_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `/post/follower`,
        JSON.stringify(mailData),
        config
      );

      dispatch({ type: MAIL_SEND_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MAIL_SEND_FAIL,
        payload: message,
      });
    }
  };

// SEND ORDER MAIL TO SELLER
export const sendOrderMail =
  ({ orderId, sortOrderItems, shippingAddress }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MAIL_SEND_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      console.log('orderId:::', orderId);
      console.log('sortOrderItems:::', sortOrderItems);
      console.log('shippingAddress:::', shippingAddress);

      const { data } = await _axios.post(
        `/post/order`,
        JSON.stringify({ orderId, sortOrderItems, shippingAddress }),
        config
      );

      dispatch({ type: MAIL_SEND_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MAIL_SEND_FAIL,
        payload: message,
      });
    }
  };

// SEND COMMISSION MAIL TO SELLER
export const sendCommissionMail =
  (sellerData) =>
  async (dispatch, getState) => {
    console.log('sellerData:', sellerData);
    try {
      dispatch({ type: MAIL_SEND_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `/post/commission`,
        JSON.stringify({ sellerData }),
        config
      );

      dispatch({ type: MAIL_SEND_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MAIL_SEND_FAIL,
        payload: message,
      });
    }
  };
