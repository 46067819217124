import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: "rgb(0 0 0 / 10%)",
        width: "100vw",
        height: "100vh",
        zIndex: "999",
      }}
      className="d-flex position-fixed top-0 left-0 justify-content-center items-center"
    >
      <div
        className="spinner-border text-success"
        role="status"
        style={{ width: "40px", height: "40px" }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
