import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import MenuAccordiion from "./common/MenuAccordion";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function MobileMenu() {
  const settingsData = useSelector((state) => state.settings);
  const { theme, logo } = settingsData;

  const history = useHistory();

  let styles = {
    bmBurgerButton: {
      position: "sticky",
      top: "10px",
      width: "26px",
      height: "15px",
    },
    bmBurgerBars: {
      background: "gray",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      width: "80%",
      left: 0,
      top: 0,
      background: "white",
    },
    bmMenu: {
      background: "white",
      padding: "2.5em 0 0",
      fontSize: "1.15em",
      margin: "auto",
    },
    bmMorphShape: {
      fill: "white",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
      width: "100%",
    },
    bmItem: {
      display: "block",
      color: "#916408",
      padding: "10px 15px 10px 0",
      whiteSpace: "nowrap",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      top: 0,
      left: 0,
    },
  };

  return (
    <>
      <Menu styles={styles}>
        <div className="col-md-3 mb-4 d-flex w-100 justify-content-center align-items-center">
          <img alt="logo" src={logo && logo[0].src} width={120} />
        </div>
        <ul className="navbar-nav">
          <li
            className={`nav-item px-1 dropdown dropdown-hover position-static ${
              history?.location.pathname === "/" ? `text-${theme}-500` : ""
            }`}
          >
            <a
              className="nav-link text-dark"
              href="/"
              id="navbarDropdown"
              role="button"
            >
              <i className="fal fa-home"></i>
              <span className="px-1">Pocetna</span>
            </a>
          </li>
          <li
            className={`nav-item px-1 dropdown dropdown-hover position-static ${
              history?.location.pathname === "/contact" ? `text-${theme}-500` : ""
            }`}
          >
            <a
              className="nav-link text-dark"
              href="/contact"
              id="navbarDropdown"
              role="button"
            >
              <i className="fal fa-map-marker-alt"></i>
              <span className="px-1">Kontakt</span>
            </a>
          </li>
          <li
            className={`nav-item px-1 dropdown dropdown-hover position-static ${
              history?.location.pathname === "/client-blogs"
                ? `text-${theme}-500`
                : ""
            }`}
          >
            <a
              className="nav-link text-dark"
              href="/client-blogs"
              id="navbarDropdown"
              role="button"
            >
              <i className="fal fa-newspaper"></i>
              <span className="px-1">Blog</span>
            </a>
          </li>
        </ul>
        <MenuAccordiion />

        <div className="col-12 mt-3 col-lg-6 gap-3 justify-content-center justify-content-lg-end d-flex align-items-center">
          <Link to="">
            <i className={`text-${theme}-500 fab fa-facebook-f`}></i>
          </Link>
          <Link to="">
            <i className={`text-${theme}-500 fab fa-instagram`}></i>
          </Link>
          <Link to="">
            <i className={`text-${theme}-500 fab fa-linkedin-in`}></i>
          </Link>
          <Link to="">
            <i className={`text-${theme}-500 fab fa-youtube`}></i>
          </Link>
          <Link to="">
            <i className={`text-${theme}-500 fab fa-pinterest-p`}></i>
          </Link>
        </div>
      </Menu>
    </>
  );
};
