import {
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL,
} from "../Constants/SettingsConstants";

export const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_REQUEST:
      return { loading: true };
    case SETTINGS_SUCCESS:
      return {
        loading: false,
        ...action.payload,
      };
    case SETTINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
