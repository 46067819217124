import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import SettingsMain from "../../components/dashboard/settings/SettingsMain";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const SettingsScreen = () => {

  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <SettingsMain />
      </PageWrapper>
    </>
  );
};

export default SettingsScreen;
