import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export default function SelectWithSearch({
  removeClear,
  disableSearch,
  allowInputToChangeValue,
  onChange,
  onChooseOption,
  options,
  value,
  keyForLabel,
  keyForValue,
  customLabel,
  className,
  labelClass,
  label,
  inputClass,
  disabled,
  placeholder,
  listStyle,
  liRemoveTruncate,
  showThumbnails,
}) {
  // uuid
  const uniqueId = uuidv4();

  // ref
  const selectListRef = useRef(null);

  // state
  const [query, setQuery] = useState(""); // search value - also selected value
  const [isOpen, setIsOpen] = useState(false); // dropdown open
  const [topBottom, setTopBottom] = useState(false); // dropdown position
  const [selectedIndex, setSelectedIndex] = useState(-1); // for key navigation
  const [filteredOptions, setFilteredOptions] = useState([]);

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const setFilteredOptionsWithClear = (options) => {
    let newOptions = [];

    if (removeClear) {
      newOptions = [...options];
    } else {
      newOptions = ["Clear", ...options];
    }

    options && setFilteredOptions(newOptions);
  };

  const onInput = (event) => {
    // prevent value and visible input change
    if (disableSearch) {
      if (query) {
        event.target.value = query;
      } else {
        event.target.value = "";
      }
      return;
    }

    // if input can change value
    if (allowInputToChangeValue) {
      onChange(event.target.value);
    }

    setIsOpen(true); // after second click options are closed
    setQuery(event.target.value);
  };

  const handleOptionClick = (option) => {
    // change value
    if (option && typeof option === "string") {
      // option is string
      if (option === "Clear") {
        onClear();
      } else {
        onChooseOption(option);
        onChange("");
        setQuery("");
      }
    } else if (option && typeof option === "object" && keyForValue) {
      // take the value of the object's key
      // onChange(option[`${keyForValue}`]);
      // setQuery(option[`${keyForLabel}`]);
      onChooseOption(option[`${keyForValue}`]);
      onChange("");
      setQuery("");
    } else {
      // value is whole object
      // onChange(option);
      // setQuery(option[`${keyForLabel}`]);
      onChooseOption(option);
      onChange("");
      setQuery("");
    }

    // close options
    setIsOpen(false);
  };

  // Handle key down arrows and Enter
  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      const direction = event.key === "ArrowDown" ? 1 : -1;
      const newIndex = Math.max(
        0,
        Math.min(selectedIndex + direction, filteredOptions.length - 1)
      );
      setSelectedIndex(newIndex);
    } else if (event.key === "Enter" && selectedIndex !== -1) {
      event.preventDefault();
      handleOptionClick(filteredOptions[selectedIndex]);
      setSelectedIndex(-1);
    } else if (event.key === "Enter" && selectedIndex === -1) {
      setIsOpen(!isOpen);
    }
  };

  // Set option label
  const setOptionLabel = (option) => {
    if (customLabel) {
      const customLabel = eval(customLabel)(option);
      if (customLabel) return customLabel;
    }

    if (option && typeof option === "string") {
      return option;
    } else {
      return option[`${keyForLabel}`];
    }
  };

  // set 'filteredOptions' when options have value
  useEffect(() => {
    if (options && !isEmpty(options)) {
      setFilteredOptionsWithClear(options);
    }
  }, [options]);

  const setQueryOnValueChangeAndBlur = () => {
    // Options are empty and allowInputToChangeValue is true
    if (isEmpty(options) && allowInputToChangeValue) {
      setQuery(value);
      return;
    }

    // Options are ['', '']
    if (options?.[0] && typeof options[0] === "string") {
      setQuery(value);
      return;
    }

    // Options are [{}, {}]
    // find object in 'options' base on 'value' and set query
    const findObj = options?.find((n) => n[`${keyForValue}`] === value);
    if (findObj && typeof findObj !== "string") {
      setQuery(findObj[`${keyForLabel}`]);
    }
  };

  useEffect(() => {
    // check if option is object
    if (options?.[0] && typeof options[0] === "object") {
      if (!keyForLabel) {
        console.error(
          "Options are array of objects. The 'keyForValue' and 'keyForLabel' are required."
        );
        return;
      }
    }

    // set 'query' on 'value' change
    setQueryOnValueChangeAndBlur();
  }, [value]);

  // Set dropdown position (top or bottom base on element position)
  const topBottomPosition = () => {
    const rect = selectListRef.current?.getBoundingClientRect();
    if (rect && rect.bottom > window.innerHeight) setTopBottom(true);
  };

  useEffect(() => {
    if (isOpen) topBottomPosition();
  }, [isOpen]);

  // On blur eventListener
  const onBlur = (e) => {
    if (
      !e.target.classList.contains("select-search-item") &&
      e.target.id !== uniqueId
    ) {
      options && setFilteredOptionsWithClear(options);
      setIsOpen(false);
      return;
    }

    setQueryOnValueChangeAndBlur();
  };

  const onClear = () => {
    options && setFilteredOptionsWithClear(options);
    onChange("");
    setQuery("");
  };

  useEffect(() => {
    window.addEventListener("click", onBlur);
    return () => {
      window.removeEventListener("click", onBlur);
    };
  }, []);

  useEffect(() => {
    // filter options
    if (query === "") {
      options && setFilteredOptionsWithClear(options);
    } else {
      console.log("AAAAAAAAAAAAAAAA");
      const filteredOps = options?.filter((option) =>
        typeof option === "string"
          ? option.toLowerCase()?.includes(query.toLowerCase())
          : option[`${keyForLabel}`]
              ?.toLowerCase()
              ?.includes(query.toLowerCase())
      );

      filteredOps && setFilteredOptionsWithClear(filteredOps);
    }
  }, [query]);

  return (
    <div className={`form-group ${className}`}>
      {/* label */}
      <label className={`form-label text-muted ${labelClass}`}>
        {label || ""}
      </label>

      <div className="position-relative">
        <input
          id={uniqueId}
          name={label || uniqueId}
          className={`form-control select-search-item bg-light border ${inputClass} ${
            disabled && "text-muted"
          } ${theme === "dark" ? "dark-theme-section text-white" : ""}`}
          placeholder={placeholder}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          value={query}
          disabled={disabled}
          onChange={onInput}
        />

        {/* arrow icon */}
        {!allowInputToChangeValue && (
          <span
            className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted"
            onClick={(e) => {
              e.stopPropagation();
              if (!disabled) setIsOpen(!isOpen);
            }}
          >
            <FaChevronDown />
          </span>
        )}

        {/* dropdown */}
        {isOpen && (
          <ul
            style={{ maxHeight: "250px", zIndex: 10 }}
            ref={selectListRef}
            tabIndex={0}
            className={`position-absolute w-100 max-h-60 mt-1 py-1 px-1 overflow-auto rounded text-dark shadow-sm list-unstyled z-50 ${
              topBottom ? "bottom-100" : ""
            } ${listStyle || ""} ${
              theme === "dark" ? "dark-theme-section" : "bg-light"
            }`}
            onKeyDown={handleKeyDown}
          >
            {/* options */}
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                className={`dropdown-item d-flex align-items-center cursor-pointer ${
                  index === selectedIndex ? "bg-primary" : ""
                } ${theme === "dark" ? "text-light select-hover-dark" : ""}`}
                onClick={() =>
                  option === "Clear" && index === 0
                    ? onClear()
                    : handleOptionClick(option)
                }
              >
                <div className="flex-fill">
                  {showThumbnails && option !== "Clear" && (
                    <img
                      src={option.imageFiles[0].src}
                      width={90}
                      alt="Slika"
                    />
                  )}
                  <span
                    className={`px-3 ${liRemoveTruncate ? "" : "text-truncate"} ${
                      option === "Clear" && index === 0 ? "text-danger" : ""
                    }`}
                  >
                    {setOptionLabel(option)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
