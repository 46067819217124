import { useRef, useEffect, useState } from "react";
import { MovingComponent } from "react-moving-text";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

export default function Jumbotron() {
  const leftComponentRef = useRef(null);
  const rightComponentRef = useRef(null);
  const [isLeftInView, setIsLeftInView] = useState(false);
  const [isRightInView, setIsRightInView] = useState(false);

  const settingsData = useSelector((state) => state.settings || {});
  const { leftRightSection = {} } = settingsData;

  useEffect(() => {
    if (!leftRightSection) return;

    const observerOptions = { threshold: 0.2 }; // Trigger callback when 20% is visible

    const leftObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsLeftInView(true);
          observer.unobserve(entry.target); // Unobserve instead of disconnecting
        }
      });
    }, observerOptions);

    const rightObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsRightInView(true);
          observer.unobserve(entry.target); // Unobserve instead of disconnecting
        }
      });
    }, observerOptions);

    if (leftComponentRef.current) {
      leftObserver.observe(leftComponentRef.current);
    }
    if (rightComponentRef.current) {
      rightObserver.observe(rightComponentRef.current);
    }

    // Clean up observers
    return () => {
      leftObserver.disconnect();
      rightObserver.disconnect();
    };
  }, [leftRightSection]);

  return (
    <>
      {leftRightSection?.leftText && leftRightSection?.rightText && (
        <div className="jumbotron py-5">
          <h3 className="text-gray text-center">O NAMA</h3>
          <div className="container d-flex flex-md-row flex-lg-row flex-column gap-5">
            <div className="jumbotron_half">
              <div ref={leftComponentRef}>
                {isLeftInView && (
                  <MovingComponent
                    type="fadeInFromLeft"
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none"
                  >
                    {parse(leftRightSection?.leftText || "")}
                  </MovingComponent>
                )}
              </div>
            </div>
            <div className="jumbotron_half">
              <div ref={rightComponentRef}>
                {isRightInView && (
                  <MovingComponent
                    type="fadeInFromRight"
                    duration="1000ms"
                    delay="0s"
                    direction="normal"
                    timing="ease"
                    iteration="1"
                    fillMode="none"
                  >
                    {parse(leftRightSection?.rightText || "")}
                  </MovingComponent>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
