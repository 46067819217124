import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Select from "../ui/form/Select";
import Loading from "../loading-error/Loading";
import ChooseProducts from "../common/ChooseProducts";
import { orderComplaint } from "../../../Redux/dashboard/Actions/OrderActions";

export default function ComplaintModal(props) {
  const [productsReplacement, setProductsReplacement] = useState([]);
  const [refundNum, setRefundNum] = useState(0);

  const { onHide, item, orderId } = props;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const productList = useSelector((state) => state.productList);
  const { loading } = productList;

  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(
      orderComplaint({
        orderId,
        itemId: item._id,
        refundNum,
        productsReplacement,
      })
    );

    onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`${
        theme === "dark" ? "dark-theme-modal" : "light-theme-modal"
      }`}
    >
      <Modal.Header
        className={!props.showDetails ? "border-0" : ""}
        closeButton
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {`Reklamacija: "${item.name}"`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <Select
            className="mb-3"
            label="Povracaj novca za broj artikala:"
            onChange={(e) => setRefundNum(Number.parseInt(e.target.value))}
          >
            {Array.from({ length: item?.qty + 1 }, (_, index) =>
              index === 0 ? "" : index
            ).map((opt) => (
              <option key={opt}>{opt}</option>
            ))}
          </Select>
          <ChooseProducts
            title="Zameni za:"
            choosenProducts={productsReplacement}
            setChoosenProducts={setProductsReplacement}
            productId={item._id}
            complaintMode={true}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onConfirm}>Potvrdi</Button>
        <Button variant="danger" onClick={onHide}>
          Zatvori
        </Button>
      </Modal.Footer>
      {loading && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
    </Modal>
  );
}
