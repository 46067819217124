import { useSelector } from "react-redux";

export default function Select({
  id,
  className,
  inputClass,
  label,
  value,
  onChange,
  children,
}) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className={`${className}`}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <select
        id={id}
        className={`${
          inputClass ||
          `form-control ${
            theme === "dark" ? "dark-theme-section text-light" : ""
          }`
        }`}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
}
