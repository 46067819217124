import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import PageWrapper from "../../components/dashboard/common/PageWapper";
import TodoMain from "../../components/dashboard/toDo/TodoMain";

export default function TodoScreen() {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <TodoMain />
      </PageWrapper>
    </>
  );
};
