import {
  TODO_ADD_FAIL,
  TODO_ADD_REQUEST,
  TODO_ADD_SUCCESS,
  TODO_DELETE_FAIL,
  TODO_DELETE_REQUEST,
  TODO_DELETE_SUCCESS,
  TODO_DONE_FAIL,
  TODO_DONE_REQUEST,
  TODO_DONE_SUCCESS,
  TODO_LIST_PENDING_FAIL,
  TODO_LIST_PENDING_REQUEST,
  TODO_LIST_PENDING_SUCCESS,
  TODO_LIST_DONE_FAIL,
  TODO_LIST_DONE_REQUEST,
  TODO_LIST_DONE_SUCCESS,
} from "../Constants/ToDoConstants";

// TO DO PENDING LIST
export const todoListPendingReducer = (state = {}, action) => {
  switch (action.type) {
    case TODO_LIST_PENDING_REQUEST:
      return { loading: true };
    case TODO_LIST_PENDING_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case TODO_LIST_PENDING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// TO DO DONE LIST
export const todoListDoneReducer = (state = {}, action) => {
  switch (action.type) {
    case TODO_LIST_DONE_REQUEST:
      return { loading: true };
    case TODO_LIST_DONE_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case TODO_LIST_DONE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ADD TO DO
export const todoAddReducer = (state = {}, action) => {
  switch (action.type) {
    case TODO_ADD_REQUEST:
      return { loading: true };
    case TODO_ADD_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case TODO_ADD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE TO DO
export const todoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TODO_DELETE_REQUEST:
      return { loading: true };
    case TODO_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TODO_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DONE TO DO
export const todoDoneReducer = (state = {}, action) => {
  switch (action.type) {
    case TODO_DONE_REQUEST:
      return { loading: true };
    case TODO_DONE_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case TODO_DONE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
