import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import io from "socket.io-client";
import { useSelector } from "react-redux";

export default function Sidebar() {
  const socket = io.connect(`${process.env.REACT_APP_API_URL}`);
  const [newOrdersNum, setNewOrdersNum] = useState(0);
  const [adminRequestsChanged, setAdminRequestsChanged] = useState(false);
  const [sAdminRequestsChanged, setSadminRequestsChanged] = useState(false);

  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const settingsData = useSelector((state) => state.settings);
  const { logo } = settingsData;

  useEffect(() => {
    socket.on("new_orders_counted", (ordersNum) => {
      setNewOrdersNum(ordersNum);
    });
    socket.on("receive_admin_request", () => {
      setAdminRequestsChanged(true);
      localStorage.setItem("adminRequest", true);
    });
    socket.on("receive_procesed_request", () => {
      setSadminRequestsChanged(true);
      localStorage.setItem("categoryProcesed", true);
    });
    socket.on("request_visited", () => {
      setSadminRequestsChanged(false);
      localStorage.setItem("categoryProcesed", false);
    });
    socket.on("requests_visited", () => {
      setAdminRequestsChanged(false);
      localStorage.setItem("adminRequest", false);
    });
    socket.on("receive_procesed_request", () => {
      setSadminRequestsChanged(true);
      localStorage.setItem("categoryProcesed", true);
    });

    socket.emit("new_orders_count", userInfo);

    return () => {
      socket.emit("user-disconect");
    };
  }, []);

  useEffect(() => {
    setAdminRequestsChanged(JSON.parse(localStorage.getItem("adminRequest")));
    setSadminRequestsChanged(
      JSON.parse(localStorage.getItem("categoryProcesed"))
    );
  }, []);

  return (
    <aside
      className={`navbar-aside ${theme === "light" ? "bg-light" : "bg-dark"}`}
      id="offcanvas_aside"
    >
      <div className="aside-top">
        <Link to="/dashboard" className="brand-wrap">
          <img
            src={logo && logo[0].src}
            style={{ height: "70px" }}
            className="logo"
            alt="Ecommerce dashboard template"
          />
        </Link>
        <div>
          <button className="btn btn-icon btn-aside-minimize">
            <i className="text-muted fas fa-stream"></i>
          </button>
        </div>
      </div>

      <nav>
        <ul className="menu-aside">
          <li className="menu-item">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/dashboard"
              exact={true}
            >
              <i className="icon fas fa-home"></i>
              <span className="text">Statistika</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/dashboard-products"
            >
              <i className="icon fas fa-shopping-bag"></i>
              <span className="text">Proizvodi</span>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/addproduct"
            >
              <i className="icon fas fa-cart-plus"></i>
              <span className="text">Dodaj proizvod</span>
            </NavLink>
          </li>
          {userInfo.isSuperAdmin && (
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/category"
              >
                <i className="icon fas fa-list"></i>
                <span className="text">Kategorije</span>
              </NavLink>
            </li>
          )}
          {userInfo.isSuperAdmin && (
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/brands"
              >
                <i className="icon fas fa-boxes"></i>
                <span className="text">Brendovi</span>
              </NavLink>
            </li>
          )}
          {userInfo.isSuperAdmin && (
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/materials"
              >
                <i className="icon fas fa-boxes"></i>
                <span className="text">Materijali</span>
              </NavLink>
            </li>
          )}
          {userInfo.isSuperAdmin && (
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/blogs"
              >
                <i className="icon far fa-newspaper"></i>
                <span className="text">Novosti</span>
              </NavLink>
            </li>
          )}
          <li className="menu-item position-relative">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/orders"
            >
              <i className="icon fal fa-bags-shopping"></i>
              <span className="text">Porudzbine</span>
            </NavLink>
            {newOrdersNum !== 0 && (
              <div
                style={{
                  top: "-8px",
                  right: "-7px",
                  minWidth: "32px",
                  minHeight: "20px",
                }}
                className="absolute rounded-circle p-1 bg-red flex text-white content-center items-center"
              >
                {newOrdersNum}
              </div>
            )}
          </li>

          {userInfo.isSuperAdmin && (
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/procurement"
              >
                <i className="icon fad fa-forklift"></i>
                <span className="text">Nabavka</span>
              </NavLink>
            </li>
          )}

          <li className="menu-item">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/users"
            >
              <i className="icon fas fa-user"></i>
              <span className="text">{`${
                userInfo.isSuperAdmin ? "Korisnici" : "Prodavci"
              }`}</span>
            </NavLink>
          </li>

          {/* {userInfo.isAdmin && (
            <li className="menu-item position-relative">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/category-request"
              >
                <i
                  className={`${
                    sAdminRequestsChanged && "text-danger"
                  } icon fas fa-exclamation-circle`}
                ></i>
                <span className="text">Zahtevi za kategorijom</span>
              </NavLink>
            </li>
          )} */}
          {/* {userInfo.isSuperAdmin && (
            <li className="menu-item position-relative">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/category-requests"
              >
                <i
                  className={`${
                    adminRequestsChanged && "text-danger"
                  } icon fas fa-exclamation-circle`}
                ></i>
                <span className="text">Zahtevi za kategorijama</span>
              </NavLink>
            </li>
          )} */}
          <li className="menu-item">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/todos"
            >
              <i className="icon fas fa-tasks"></i>
              <span className="text">Todos</span>
            </NavLink>
          </li>
          <li className="menu-item position-relative">
            <NavLink
              activeClassName="active"
              className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
              to="/chat"
            >
              <i className="icon fas fa-comment"></i>
              <span className="text">Cet</span>
            </NavLink>
          </li>
          {userInfo.isSuperAdmin && (
            <li className="menu-item position-relative">
              <NavLink
                activeClassName="active"
                className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                to="/settings"
              >
                <i className="icon fas fa-cog"></i>
                <span className="text">Podesavanja</span>
              </NavLink>
            </li>
          )}
          {/* {userInfo.isSuperAdmin && (
              <li className="menu-item">
                <NavLink
                  activeClassName="active"
                  className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                  to="/sellers"
                >
                  <i className="icon fas fa-store-alt"></i>
                  <span className="text">Sellers</span>
                </NavLink>
              </li>
            )}
            {userInfo.isSuperAdmin && (
              <li className="menu-item">
                <NavLink
                  activeClassName="active"
                  className={`menu-link ${theme === "dark" ? "dark-link" : ""}`}
                  to="/commissions"
                >
                  <i className="icon fas fa-usd-circle"></i>
                  <span className="text">Commissions</span>
                </NavLink>
              </li>
            )} */}
        </ul>
      </nav>
    </aside>
  );
}
