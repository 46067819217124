import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import MainBrands from "../../components/dashboard/brands/MainBrands";
import PageWrapper from "../../components/dashboard/common/PageWapper";

export default function BrandsScreen() {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <MainBrands />
      </PageWrapper>
    </>
  );
};
