import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import Input from "../ui/form/Input";
import Select from "../ui/form/Select";

const OrderFilters = ({ submitHandler }) => {
  const [status, setStatus] = useState("");
  const [keyword, setKeyword] = useState("");
  const [toShow, setToShow] = useState(20);
  const [firstRender, setFirstRender] = useState(true);

  const memoizedSearch = useMemo(() => {
    const debounceSearch = debounce(async (query) => {
      // Perform your search logic here
      console.log("Searching for:", query);
      // ... Additional search code
      setKeyword(query);
      submitHandler({
        pageNumber: 1,
        keyword: query,
        orderStatus: status,
        toShow,
      });
    }, 500);

    return debounceSearch;
  }, [status, toShow]);

  const search = (query) => {
    memoizedSearch(query);
  };

  useEffect(() => {
    setFirstRender(false);
    if (firstRender) return;

    submitHandler({ pageNumber: "all", keyword, orderStatus: status, toShow });
  }, [status, toShow]);

  return (
    <div className="row gx-3 py-3">
      <div className="col-lg-4 col-md-6 me-auto px-0">
        <Input
          id="search"
          type="search"
          placeholder="Pretraga..."
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="col-lg-2 col-6 col-md-3">
        <Select id="filter_by_status" onChange={(e) => setStatus(e.target.value)}>
          <option value={""}>Sve</option>
          <option value={true}>Dostavljeno</option>
          <option value={false}>Nije dostavljeno</option>
        </Select>
      </div>
      <div className="col-lg-2 col-6 col-md-3">
        <Select id="num_to_show" onChange={(e) => setToShow(e.target.value)}>
          <option value={20}>Prikazi 20</option>
          <option value={30}>Prikazi 30</option>
          <option value={40}>Prikazi 40</option>
        </Select>
      </div>
    </div>
  );
};

export default OrderFilters;
