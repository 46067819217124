import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isEmpty } from "lodash";
import SortableImages from "../common/sortable/SortableImages";
import { editBrand } from "../../../Redux/dashboard/Actions/BrandActions";
import { useDispatch, useSelector } from "react-redux";
import Input from "../ui/form/Input";

export default function EditBrandModal(props) {
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);
  const [imageToDelete, setImageToDelete] = useState("");
  const { brand, onHide } = props;

  const dispatch = useDispatch();

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      editBrand({
        id: brand._id,
        title,
        image: images[0],
        imageToDelete,
      })
    );

    onHide();
  };

  useEffect(() => {
    if (!isEmpty(brand)) {
      setTitle(brand.title);
      setImages([brand.image]);
    }
  }, [brand]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Izmeni brend</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          id="title"
          className="mb-4"
          type="text"
          label="Title"
          required={true}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="mb-4 flex-grow-1">
          <label className="form-label">Slika</label>
          <SortableImages
            images={images}
            setImages={setImages}
            setImageToDelete={setImageToDelete}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!title || isEmpty(images) || images.length > 1}
          onClick={submitHandler}
        >
          Potvrdi
        </Button>
        <Button variant="danger" onClick={() => onHide()}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
