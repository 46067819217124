import React from "react";
import CategoryTree from "./CategoryTree";
import { Card, CardBody } from "../common";

const MainCategories = () => {
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Kategorije</h2>
      </div>

      <Card section className="shadow-sm">
        <CardBody>
          <CategoryTree />
        </CardBody>
      </Card>
    </section>
  );
};

export default MainCategories;
