import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../../Redux/dashboard/Actions/UserActions";
import AvatarComponent from "../common/Avatar";
import Input from "../ui/form/Input";
import { ToastContainer, toast } from "react-toastify";
import { isEmpty } from "lodash";

export default function CreateUserModal(props) {
  const { setShow } = props;

  const [image, setImage] = useState({});
  const [userData, setUserData] = useState({
    userType: "seller",
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
  });

  const { name, email, password, phone, address, userType } = userData;

  const handleInputChange = (name, value) => {
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (isEmpty(image)) {
      toast.warn("Please upload an avatar image.");
      return;
    }
    dispatch(createUser({ image, name, email, password, phone, address, userType }));

    setShow(false);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Kreiraj Korisnika
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="d-flex flex-column gap-3">
            <AvatarComponent setImage={setImage} />

            <Input
              inputClass={`form-control ${
                theme === "dark" ? "bg-dark text-light" : ""
              }`}
              type="text"
              placeholder="Korisnicko Ime"
              required
              value={name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <Input
              inputClass={`form-control ${
                theme === "dark" ? "bg-dark text-light" : ""
              }`}
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            <Input
              inputClass={`form-control ${
                theme === "dark" ? "bg-dark text-light" : ""
              }`}
              type="password"
              placeholder="Lozinka"
              value={password}
              onChange={(e) => handleInputChange("password", e.target.value)}
            />
            <Input
              inputClass={`form-control ${
                theme === "dark" ? "bg-dark text-light" : ""
              }`}
              type="number"
              placeholder="Telefon"
              required
              value={phone}
              onChange={(e) => handleInputChange("phone", e.target.value)}
            />
            <Input
              inputClass={`form-control ${
                theme === "dark" ? "bg-dark text-light" : ""
              }`}
              type="text"
              placeholder="Adresa"
              required
              value={address}
              onChange={(e) => handleInputChange("address", e.target.value)}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={submitHandler}>Confirm</Button>
          <Button variant="danger" onClick={() => setShow(false)}>
            Zatvori
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}
