import {
  SET_THEME,
} from "../Constants/ThemeConstants";

// LOGIN
export const themeReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_THEME:
      return { theme: action.payload };
    default:
      return state;
  }
};
