import { useSelector } from "react-redux";

export default function Input({
  id,
  className,
  inputClass,
  type,
  name,
  label,
  required,
  disabled,
  value,
  sufix,
  placeholder,
  onChange,
  onKeyDown,
  onBlur
}) {
  const themeData = useSelector((state) => state.settings);
  const { theme } = themeData;

  return (
    <div className={`flex-grow-1 text-start ${className}`}>
      {sufix ? (
        <>
          {label && (
            <label htmlFor={id} className="form-label text-gray">
              {label}
            </label>
          )}
          <div className="input-with-suffix">
            <input
              type={type}
              name={name}
              placeholder={placeholder || "Kucaj ovde"}
              className={`text-gray p-2 w-100 focus-none border-focus-${theme}-500 rounded-0 border-1 border-transition-hover-${theme}-500 ${inputClass}`}
              id="product_width"
              value={value}
              onChange={onChange}
            />
            <span className="input-suffix text-gray  px-2">{sufix}</span>
          </div>
        </>
      ) : (
        <>
          {label && (
            <label htmlFor={id} className="form-label text-gray">
              {label}
            </label>
          )}
          <input
            id={id}
            type={type}
            name={name}
            placeholder={placeholder || "Kucaj ovde"}
            className={`text-gray w-100 p-2 focus-none text-gray rounded-0 border-1 border-1 border-focus-${theme}-500 border-transition-hover-${theme}-500 ${inputClass}`}
            required={required}
            disabled={disabled}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          />
        </>
      )}
    </div>
  );
}
