import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
} from "../Constants/CategoryConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET CATEGORIES
export const listCategories = () =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_LIST_REQUEST });

      const { data } = await _axios.get(`categories/client/all`);

      dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_LIST_FAIL,
        payload: message,
      });
    }
  };