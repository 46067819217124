import React, { useState, useEffect, useMemo } from "react";
import SortableTree from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css";
import { Resizable } from "re-resizable";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  listCategories,
  updateCategoryTree,
} from "../../../Redux/dashboard/Actions/CategoryActions";
import AddEditCategoryModal from "./AddEditCategoryModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import {
  CATEGORY_CREATE_RESET,
  CATEGORY_EDIT_RESET,
} from "../../../Redux/dashboard/Constants/CategoryConstants";

export default function CategoryTree() {
  const [treeChanged, setTreeChanged] = useState(false);
  const [movedNode, setMovedNode] = useState({});
  const [afterNodeMovePath, setAfterNodeMovePath] = useState("");
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [categoryToRemove, setCategoryToRemove] = useState("");
  const [nodeToEdit, setNodeToEdit] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [tree, setTree] = useState([]);
  const [actionType, setActionType] = useState("");
  const [parentCategoryPath, setParentCategoryPath] = useState("");

  const categoriesData = useSelector((state) => state.categoriesList);
  const { loading: ctLoading, error: ctError, categories } = categoriesData;

  const categoriesUpdate = useSelector((state) => state.categoryEdit);
  const { loading, error, updatedCategoryPath } = categoriesUpdate;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    category,
    createdCategoryPath,
  } = categoryCreate;

  const dispatch = useDispatch();

  const addPathToNode = (nodes, parentPath = "") => {
    return nodes.map((node, index) => {
      const nodePath = parentPath
        ? `${parentPath.replace(/ /g, "_").toLowerCase()}/${node.title
            .replace(/ /g, "_")
            .toLowerCase()}`
        : node.title.replace(/ /g, "_").toLowerCase();
      const newNode = {
        ...node,
        index: node.isCreateNew ? nodes.length : index,
        path: nodePath,
      };
      if (node.children) {
        newNode.children = addPathToNode(node.children, nodePath);
      }
      return newNode;
    });
  };

  const addCreateNewNodes = (nodes) => {
    return nodes.map((node) => {
      // Check if a "Create new" node already exists
      const hasCreateNewNode = node.children.some((child) => child.isCreateNew);

      const newNode = {
        ...node,
        children: node.children ? addCreateNewNodes(node.children) : [],
      };

      // Add "Dodaj kategoriju" node if it doesn't already exist
      if (!hasCreateNewNode && !newNode.isCreateNew) {
        newNode.children.push({
          title: "Dodaj kategoriju",
          isCreateNew: true,
          index: newNode.children.length,
          children: [],
        });
      }

      // Find "Dodaj kategoriju" node and set its index
      const createNewNode = newNode.children.find((n) => n.isCreateNew);
      if (createNewNode) {
        createNewNode.index = newNode.children.length - 1; // Corrected index assignment
      }

      return newNode;
    });
  };

  const addRootCreateNewNode = (nodes) => {
    const hasRootCreateNewNode = nodes.some((node) => node.isCreateNew);

    if (!hasRootCreateNewNode) {
      nodes.push({
        title: "Dodaj kategoriju",
        isCreateNew: true,
        children: [],
      });
    }

    return nodes;
  };

  const treeWithPaths = useMemo(() => {
    return addPathToNode(tree);
  }, [tree]);

  const gatherImages = (node) => {
    if (!node.isCreateNew) {
      setImagesToRemove((arr) => [
        ...arr,
        ...node.images.map((n) => n.public_id),
      ]);
    }

    if (node.children.length > 0) {
      node.children.forEach((node) => {
        gatherImages(node);
      });
    } else {
      setCategoryToRemove(node.title);
    }
  };

  const openAddCategoryModal = (path) => {
    setActionType("add");
    setShowModal(true);
    let parentPath = path?.split("/").slice(0, -1).join("/");
    parentPath && setParentCategoryPath(parentPath);
  };

  const openEditCategoryModal = (node) => {
    setActionType("edit");
    setShowModal(true);
    setNodeToEdit(node);
  };

  const removeNode = (arr, nodeToRemove) => {
    gatherImages(nodeToRemove);
    setTreeChanged(true);
    const removeRecursively = (nodes) => {
      for (let i = 0; i < nodes.length; i++) {
        const currentNode = nodes[i];

        if (currentNode._id === nodeToRemove._id) {
          nodes.splice(i, 1);
          setTree([...arr]);
          return true;
        }

        if (currentNode.children && currentNode.children.length > 0) {
          const childRemoved = removeRecursively(currentNode.children);
          if (childRemoved) {
            setTree([...arr]);
            return true;
          }
        }
      }

      return false;
    };

    return removeRecursively(arr);
  };

  const save = () => {
    setTreeChanged(false);
    dispatch(
      updateCategoryTree({
        tree: treeWithPaths.filter((n) => !n.isCreateNew),
        beforeNodeMovePath: movedNode.path,
        afterNodeMovePath,
        imagesToRemove,
        categoryToRemove,
      })
    );
  };

  const reset = () => {
    setTreeChanged(false);
    dispatch(listCategories({ pageNumber: 1, keyword: "" }));
  };

  const findMovedNode = (arr) => {
    arr.forEach((n) => {
      if (n._id === movedNode._id) {
        setAfterNodeMovePath(n.path);
      } else {
        findMovedNode(n.children);
      }
    });
  };

  useEffect(() => {
    if (treeWithPaths && movedNode) {
      findMovedNode(treeWithPaths);
    }
  }, [treeWithPaths, movedNode]);

  useEffect(() => {
    if (categories) {
      console.log("categories:::", categories);
      const treeWithCreateNewNodes = addCreateNewNodes(categories);
      const treeWithRootCreateNewNode = addRootCreateNewNode(
        treeWithCreateNewNodes
      );

      if (createdCategoryPath || updatedCategoryPath) {
        const expandTreeToTargetCategory = (nodes) => {
          return nodes.map((node) => {
            if (
              (createdCategoryPath?.includes(node.path) &&
                createdCategoryPath !== node.path) ||
              (updatedCategoryPath?.includes(node.path) &&
                updatedCategoryPath !== node.path)
            ) {
              node.expanded = true;
            }
            if (node.children) {
              return {
                ...node,
                children: expandTreeToTargetCategory(node.children),
              };
            }
            return node;
          });
        };

        setTree(() => expandTreeToTargetCategory(treeWithRootCreateNewNode));
      } else {
        setTree(treeWithRootCreateNewNode);
      }
    }
  }, [categories]);

  useEffect(() => {
    dispatch(listCategories({ pageNumber: 1, keyword: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (afterNodeMovePath) {
      setMovedNode("");
      save();
      setAfterNodeMovePath("");
    }
  }, [afterNodeMovePath]);

  useEffect(() => {
    if (category) {
      dispatch(listCategories({ pageNumber: 1, keyword: "" }));
    }
  }, [category, dispatch]);

  const backend = isMobile ? TouchBackend : HTML5Backend;

  return (
    <DndProvider backend={backend}>
      <div className="container flex justify-content-center">
        <div className="relative">
          {(error || ctError || errorCreate) && (
            <Message variant="alert-danger">
              {error || ctError || errorCreate}
            </Message>
          )}
          <Resizable
            defaultSize={{
              width: "70vw",
              height: 300,
            }}
            maxWidth={"90vw"}
            style={{ border: "1px solid black" }}
            className={`p-3 ${
              theme === "dark" ? "dark-theme-section dark-tree" : ""
            }`}
          >
            <div className="w-100 h-100">
              <SortableTree
                treeData={tree}
                onChange={(treeData) => {
                  setTree(treeData);
                  setTreeChanged(true);
                  dispatch({ type: CATEGORY_CREATE_RESET });
                  dispatch({ type: CATEGORY_EDIT_RESET });
                }}
                canDrag={({ node }) => !node.isCreateNew}
                canNodeHaveChildren={(node) => !node.isCreateNew}
                onMoveNode={(node) => {
                  setTreeChanged(true);
                  setMovedNode(node.node);
                }}
                generateNodeProps={({ node }) => {
                  if (node.isCreateNew) {
                    return {
                      buttons: [
                        <button
                          className="btn btn-outline text-green"
                          onClick={() => openAddCategoryModal(node.path)}
                        >
                          <i className="fas fa-plus"></i>
                        </button>,
                      ],
                      style: { fontStyle: "italic" },
                    };
                  }

                  return {
                    buttons: [
                      <button
                        className="btn text-success"
                        onClick={() => openEditCategoryModal(node)}
                      >
                        <i className="fas fa-pen"></i>
                      </button>,
                      <button
                        className="btn text-danger"
                        onClick={() => removeNode(tree, node)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>,
                    ],
                  };
                }}
              />
            </div>
          </Resizable>
          <div className="flex justify-content-between mt-3">
            <button
              style={{ height: "60px", width: "120px" }}
              className="btn btn-warning py-3"
              disabled={!treeChanged}
              onClick={reset}
            >
              Resetuj
            </button>
            <button
              style={{ height: "60px", width: "120px" }}
              className="btn btn-primary py-3"
              disabled={!treeChanged}
              onClick={save}
            >
              Sacuvaj
            </button>
          </div>
        </div>
      </div>

      <AddEditCategoryModal
        type={actionType}
        show={showModal}
        parentCategoryPath={parentCategoryPath}
        setshow={setShowModal}
        node={nodeToEdit}
      />

      {(loading || ctLoading || loadingCreate) && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
    </DndProvider>
  );
}
