import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";

export default function DoneProductsTable({ todos }) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const todoListDone = useSelector((state) => state.todoListDone);
  const { loading, error } = todoListDone;

  return (
    <>
      {error && <Message variant="error">{error}</Message>}
      <h4 className="mt-4">Odradjeni proizvodi</h4>
      <table
        className={`table orders-table ${theme === "dark" ? "bg-dark" : ""}`}
      >
        <thead>
          <tr
            className={`${
              theme === "dark" ? "text-light dark-theme-hover" : ""
            }`}
          >
            <th scope="col">Naziv</th>
            <th scope="col">Slika</th>
            <th scope="col">Cena</th>
            <th scope="col">Broj dana</th>
            <th scope="col">Datum zavrsetaka</th>
          </tr>
        </thead>
        <tbody>
          {todos?.map((todo) => (
            <tr
              className={`${
                theme === "dark" ? "text-light dark-theme-hover" : ""
              }`}
              key={todo._id}
            >
              <td>
                <b>{todo.name}</b>
              </td>
              <td>
                <img src={`${todo.image.src}`} alt="Todo slika" width={120} />
              </td>
              <td>{todo.price} RSD</td>
              <td>{todo.buildingDaysNum}</td>
              <td>{moment(todo.updatedAt).format("MMM Do YY")}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {loading && <Loading position="absolute" width="100%" height="100%" />}
    </>
  );
}
