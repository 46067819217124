import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import MainProcurement from "../../components/dashboard/procurement/MainProcurement";
import PageWrapper from "../../components/dashboard/common/PageWapper";

export default function ProcurementScreen() {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <MainProcurement />
      </PageWrapper>
    </>
  );
}
