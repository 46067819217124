import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Input from "../ui/form/Input";
import Select from "../ui/form/Select";

const ProductFilters = ({ submitHandler, byEmail, users, outOfStock }) => {
  const [sellerId, setSellerId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortby] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useDispatch();

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const memoizedSearch = useMemo(() => {
    const debounceSearch = debounce(async (query) => {
      setKeyword(query);
      submitHandler({
        pageNumber: 1,
        keyword: query,
        sellerId,
        sortBy,
        byEmail,
        outOfStock,
      });
    }, 500);

    return debounceSearch;
  }, [sellerId, submitHandler]);

  const search = (query) => {
    memoizedSearch(query);
  };

  useEffect(() => {
    setFirstRender(false);
    if (firstRender) return;

    if (
      sellerId ||
      sortBy ||
      dispatch ||
      firstRender ||
      byEmail ||
      outOfStock
    ) {
      submitHandler({
        pageNumber: 1,
        keyword,
        sellerId,
        sortBy,
        byEmail,
        toShow: 12,
        outOfStock,
      });
    }
  }, [sellerId, sortBy, dispatch, firstRender, byEmail, outOfStock]);

  return (
    <div className="row gx-3 py-3">
      <div className="col-lg-4 col-md-6 me-auto px-0">
        <Input
          id="search"
          type="search"
          placeholder="Pretraga..."
          inputClass={`form-control ${
            theme === "dark" ? "bg-dark text-light" : ""
          }`}
          onChange={(e) => search(e.target.value)}
        />
      </div>
      {userInfo?.isSuperAdmin && !byEmail && (
        <div className="col-lg-2 col-6 col-md-3">
          {users && (
            <Select
              id="filter_by_seller"
              inputClass={`form-select ${
                theme === "dark" ? "bg-dark text-light" : ""
              }`}
              onChange={(e) =>
                setSellerId(
                  e.target.value !== "All sellers" ? e.target.value : ""
                )
              }
            >
              <option value="All sellers">Svi prodavci</option>
              {users.map((user, index) => (
                <option key={index} value={user._id}>
                  {user.name}
                </option>
              ))}
            </Select>
          )}
        </div>
      )}
      <div className="col-lg-2 col-6 col-md-3">
        <Select
          id="filter_by_date"
          inputClass={`form-select ${
            theme === "dark" ? "bg-dark text-light" : ""
          }`}
          value={sortBy}
          onChange={(e) => setSortby(e.target.value)}
        >
          <option value="Latest added">Od poslednje dodatog</option>
          <option value="Previous added">Od prvog dodatog</option>
          <option value="Cheap first">Od najjeftinijeg</option>
          <option value="Expensive first">Od najskupljeg</option>
          <option value="Most viewed">Od najpregledanijeg</option>
        </Select>
      </div>
    </div>
  );
};

export default ProductFilters;
