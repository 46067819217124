import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ChatInput from "./ChatInput";
import { isEmpty } from "lodash";
import {
  addMessage,
  listMessages,
  deleteMessage,
  editMessage,
} from "../../../Redux/dashboard/Actions/ChatActions";
import ModalImage from "react-modal-image";
import DeleteMessageModal from "./DeleteMessageModal";
import Loading from "../loading-error/Loading";

export default function ChatContainer({ currentChat, socket, isOnline }) {
  const [messagesLocal, setMessagesLocal] = useState([]);
  const [replyToMessage, setReplyToMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [whoIsTyping, setWhoIsTyping] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [messageId, setMessageId] = useState("");
  const [editMessageId, setEditMessageId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isImage, setIsImage] = useState({});

  const scrollRef = useRef();
  const inputRef = useRef();

  const messageData = useSelector((state) => state.messageList);
  const { loading, messages } = messageData;

  const messageAddData = useSelector((state) => state.messageCreate);
  const { loading: loadingCreate } = messageAddData;

  const loginData = useSelector((state) => state.userLogin);
  const { userInfo } = loginData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const timerRef = useRef("");
  const timerMainRef = useRef("");

  const dispatch = useDispatch();

  const handleSendMsg = async (msg, image) => {
    const messageCurrentId = uuidv4();
    setIsImage(image);

    if (!image?.src) {
      socket.current.emit("send-msg", {
        senderData: userInfo,
        to: currentChat._id || currentChat,
        msg: { text: msg },
        id: messageCurrentId,
        replyToMessage,
        isMain: currentChat === "main",
      });
    }

    dispatch(
      addMessage({
        id: messageCurrentId,
        senderData: userInfo,
        to: currentChat._id || currentChat,
        message: msg,
        image,
        socket,
        replyToMessage,
        isMain: currentChat === "main",
      })
    );

    setMessagesLocal((prevMessages) => [
      ...prevMessages,
      {
        id: messageCurrentId,
        fromSelf: true,
        senderData: userInfo,
        message: { text: msg },
        image,
        replyToMessage,
        isMain: currentChat === "main",
      },
    ]);

    setReplyToMessage("");
  };

  const handleTyping = () => {
    if (currentChat === "main") {
      socket.current.emit("is-main-typing", {
        name: userInfo.name,
        from: userInfo._id,
      });
    } else {
      socket.current.emit("is-typing", {
        to: currentChat._id,
        from: userInfo._id,
      });
    }
  };

  const onEditMessage = () => {
    socket.current.emit("edit-msg", {
      to: currentChat._id || "main",
      messageId: editMessageId,
      msg: inputRef.current.value,
    });

    dispatch(
      editMessage({
        messageId: editMessageId,
        msg: inputRef.current.value,
      })
    );

    setMessagesLocal((prev) => [
      ...prev.map((n) => {
        if (n.id === editMessageId) {
          if (!isEmpty(n.image)) {
            n.image = {};
          } else if (n.message.text) {
            n.message.text = inputRef.current.value;
          }
          n.edited = true;
        }
        return n;
      }),
    ]);

    setInputValue("");
    setEditMessageId("");
  };

  const onDeleteMessage = (messageId) => {
    socket.current.emit("delete-msg", {
      to: currentChat._id || currentChat,
      messageId,
    });

    dispatch(
      deleteMessage({
        messageId,
      })
    );

    setMessagesLocal((prev) => [
      ...prev.map((n) => {
        if (n.id === messageId) {
          if (n.message.image?.public_id || n.message.image?.src) {
            n.message.image = {};
          }
          if (n.message.text) {
            n.message.text = "";
          }
          n.deleted = true;
        }
        return n;
      }),
    ]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentChat) {
        dispatch(
          listMessages({
            from: userInfo._id,
            to: currentChat._id || currentChat,
          })
        );
      }
    };

    fetchData();
  }, [currentChat, dispatch, userInfo._id]);

  console.log("replyToMessage:::", replyToMessage);

  useEffect(() => {
    let typers = [];
    if (socket?.current && currentChat) {
      socket.current.off("msg-recieve");
      socket.current.on("msg-recieve", (data) => {
        if (
          (data.senderData._id === currentChat._id && !data.isMain) ||
          (data.isMain && currentChat === "main")
        ) {
          setArrivalMessage({
            id: data.id,
            fromSelf: false,
            message: data.msg,
            image: data.image,
            senderData: data.senderData,
            deleted: false,
            replyToMessage: data.replyToMessage,
            isMain: currentChat === "main",
          });
        }
      });

      socket.current.off("edit-msg-recieve");
      socket.current.on("edit-msg-recieve", (data) => {
        setMessagesLocal((prev) => [
          ...prev.map((n) => {
            if (n.id === data.messageId) {
              if (!isEmpty(n.image)) {
                n.image = {};
              } else if (n.message.text) {
                n.message.text = data.msg;
              }
              n.edited = true;
            }
            return n;
          }),
        ]);
      });

      socket.current.off("delete-msg-recieve");
      socket.current.on("delete-msg-recieve", (messageId) => {
        setMessagesLocal((prev) => [
          ...prev.map((n) => {
            if (n.id === messageId) {
              if (n.message.image?.public_id) {
                n.message.image = {};
              } else if (n.image?.public_id) {
                n.image = {};
              } else if (n.message.text) {
                n.message.text = "";
              }
              n.deleted = true;
            }
            return n;
          }),
        ]);
      });

      socket.current.off("receive-main-typing");
      socket.current.on("receive-main-typing", (name) => {
        const userExist = typers.find((n) => n === name);
        if (!userExist) {
          typers = [];
          typers.push(name);
          setWhoIsTyping(typers);
        }
        clearTimeout(timerMainRef.current);
        timerMainRef.current = setTimeout(() => {
          typers = typers.filter((n) => n !== name);
          setWhoIsTyping(typers);
        }, 2000);
      });

      socket.current.off("receive-typing");
      socket.current.on("receive-typing", (data) => {
        if (data.from === currentChat._id) {
          setIsTyping(true);
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            setIsTyping(false);
          }, 2000);
        }
      });

      socket.current.on("disconnect", () => {
        setTimeout(() => {
          socket.current.connect();
        }, 1000);
      });
    }

    return () => {
      socket.current.off("msg-recieve");
      socket.current.off("receive-main-typing");
      socket.current.off("receive-typing");
    };
  }, [socket?.current, currentChat]);

  useEffect(() => {
    arrivalMessage && setMessagesLocal((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messagesLocal, isTyping, whoIsTyping]);

  useEffect(() => {
    if (messages) {
      setMessagesLocal(messages);
    } else {
      setMessagesLocal([]);
    }
  }, [messages]);

  return (
    <>
      <Container className={theme === "dark" ? "bg-dark" : "bg-white"}>
        {/* CHAT HEADER  */}
        <div className="chat-header">
          <div className="user-details">
            <div className="avatar">
              <img
                src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
                alt=""
              />
            </div>
            {currentChat !== "main" && (
              <div className="username d-flex gap-2 align-items-center">
                <div>
                  <div
                    className={`chat-indicator ${
                      isOnline ? "online" : "offline"
                    }`}
                  ></div>
                </div>
                <h3 className="m-0">{currentChat.name}</h3>
              </div>
            )}
          </div>
        </div>
        {/* MESSAGES WRAPPER  */}
        <div className="position-relative overflow-auto">
          <div className="chat-messages">
            {messagesLocal?.map((message) => {
              return (
                <div ref={scrollRef} key={uuidv4()}>
                  <div
                    className={`message ${
                      message.fromSelf ? "sended" : "recieved"
                    }`}
                  >
                    {/* SENDER AVATR */}
                    {!message.fromSelf && currentChat === "main" && (
                      <div className="sender-avatar mx-1 ">
                        <img
                          src={message.senderData.avatarImage.url}
                          width={30}
                          height={30}
                          alt=""
                        />
                      </div>
                    )}
                    {/* MESSAGE CONTENT  */}
                    <div className="content">
                      {/* WHOM TO REPLY */}
                      {message.replyToMessage?.senderData?.name &&
                        !message.deleted && (
                          <div className="reply-to">
                            <div className="reply-data">
                              <p className="mb-1">
                                <i>
                                  {message?.replyToMessage?.senderData?._id ===
                                  userInfo._id
                                    ? "Ti"
                                    : message.replyToMessage.senderData.name}
                                </i>
                              </p>
                              {/* if message is image */}
                              {message.replyToMessage.message?.image?.src && (
                                <img
                                  src={message.replyToMessage.message.image.src}
                                  width={100}
                                />
                              )}
                              {/* if message is text  */}
                              {message.replyToMessage.message.text && (
                                <p className="mb-0">
                                  {message.replyToMessage.message.text}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                      {/* MESSAGE IMAGE MODAL */}
                      {message?.message?.image?.src || message?.image?.src ? (
                        <ModalImage
                          small={
                            message?.message?.image
                              ? [message.message.image.src]
                              : [message.image.src]
                          }
                          large={
                            message?.message?.image
                              ? [message.message.image.src]
                              : [message.image.src]
                          }
                          alt="Hello World!"
                        />
                      ) : (
                        <p>
                          {message?.message?.text || <i>Poruka je obrisana</i>}
                        </p>
                      )}

                      {/* MESSAGE ACTIONS  */}
                      {!message.deleted && (
                        <div className="message-actions">
                          <button
                            title="Odgovori"
                            className="reply btn text-success mx-1 rounded-circle"
                            onClick={() => {
                              setReplyToMessage(message);
                              inputRef.current.focus();
                            }}
                          >
                            <i className="fa fa-reply"></i>
                          </button>
                          {message.fromSelf && message.message?.text && (
                            <button
                              title="Izmeni"
                              className="edit btn text-success mx-1 rounded-circle"
                              onClick={() => {
                                setEditMessageId(message.id);
                                setInputValue(message.message.text);
                                inputRef.current.focus();
                              }}
                            >
                              <i className="fa fa-pen"></i>
                            </button>
                          )}

                          {message.fromSelf && (
                            <button
                              title="Obrisi"
                              className="delete btn text-danger mx-1 rounded-circle"
                              onClick={() => {
                                setMessageId(message.id);
                                setModalShow(true);
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* TYPING  */}
        {isTyping && (
          <div className="typing-indicator">
            <img src="typing-message.gif" />
          </div>
        )}

        {/* TYPING FOR MAIN ROOM  */}
        {currentChat === "main" && (
          <div className="typing-indicator">
            {whoIsTyping.map((member) => (
              <h6 className="text-light" key={member}>{`${member} kuca...`}</h6>
            ))}
          </div>
        )}

        {/* REPLY MODE  */}
        {replyToMessage && (
          <div className="reply-to-message">
            <div className="sender-data">
              <p>
                <i>
                  {replyToMessage?.senderData?._id === userInfo._id
                    ? "You"
                    : replyToMessage?.senderData?.name}
                </i>
              </p>

              {replyToMessage.message.image?.src && (
                <img src={replyToMessage.message.image.src} width={100} />
              )}

              {replyToMessage.image?.src && (
                <img src={replyToMessage.image.src} width={100} />
              )}

              {replyToMessage.message.text && (
                <p>{replyToMessage.message.text}</p>
              )}
            </div>

            <div className="close-reply">
              {/* <i className="fa fa-close"></i> */}
              <button
                className="btn btn-close"
                onClick={() => setReplyToMessage("")}
              ></button>
            </div>
          </div>
        )}

        {/* EDIT MODE */}
        {editMessageId && (
          <div className="edit-message">
            <div className="sender-data">
              <p>Izmena</p>
            </div>

            <div className="close-edit">
              <button
                className="btn btn-close"
                onClick={() => {
                  setEditMessageId("");
                  setInputValue("");
                  inputRef.current.blur();
                }}
              ></button>
            </div>
          </div>
        )}

        {!loading && (
          <ChatInput
            handleSendMsg={handleSendMsg}
            handleTyping={handleTyping}
            inputValue={inputValue}
            onEditMessage={onEditMessage}
            ref={inputRef}
          />
        )}

        {(loading || (isImage && loadingCreate)) && (
          <Loading position="absolute" width="100%" height="100%" />
        )}
      </Container>

      <DeleteMessageModal
        onConfirm={onDeleteMessage}
        reqid={messageId}
        onShow={() => setModalShow(true)}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

const Container = styled.div`
  flex: 1;
  display: grid;
  position: relative;
  grid-template-rows: 10% 80% 10%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 70% 15%;
  }
  .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000030;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        background-color: #0000008a;
        padding: 5px 10px;
        border-radius: 10px;
        .chat-indicator {
          width: 12px !important;
          height: 12px !important;
          border-radius: 50%;
          border: 1px solid white;
        }
        .online {
          background-color: green;
        }
        .offline {
          background-color: red;
        }
        h3 {
          color: white;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      position: relative;
      display: flex;
      .sender-avatar {
        width: 30px;
        height: 30px;
      }
      .content {
        width: 40%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (max-width: 1080px) {
          width: 70%;
          padding: 0.3rem;
        }
        .reply-to {
          background-color: #80808094;
          padding: 5px 10px;
          margin-bottom: 10px;
        }
        .message-actions {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          button {
            background-color: #00000069;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1080px) {
              width: 25px;
              height: 25px;
              font-size: 10px;
            }
          }
          div {
            width: auto;
          }
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #204318c9;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #1e5d06b3;
      }
    }
  }
  .reply-to-message,
  .edit-message {
    padding: 12px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 83px;
    background-color: gray;
    display: flex;
    justify-content: space-between;

    .sender-data {
      width: fit-content;
    }
    .close-reply,
    .close-edit {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 600px) {
      bottom: 74px;
    }
  }
  .typing-indicator {
    position: absolute;
    bottom: 100px;
    left: 40px;
    img {
      width: 60px;
      height: 30px;
    }
  }
`;
