import React from "react";
import { useSelector } from "react-redux";

const ProductViewStatistics = ({ title }) => {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className="col-xl-6 col-lg-12">
      <div
        className={`card overflow-auto mb-4 shadow-sm ${
          theme === "dark" ? "bg-dark text-light" : ""
        }`}
      >
        <article className="card-body">
          <h5 className="card-title">Najpregledaniji Proizvodi</h5>
          {theme === "dark" ? (
            <iframe
              style={{ minWidth: "700px", color: "white" }}
              height="353"
              src="https://charts.mongodb.com/charts-mywoodshop-hqcwv/embed/charts?id=4c24cc45-2fc3-43ed-b2d8-b3f5ad839ea2&maxDataAge=3600&theme=dark&autoRefresh=true"
            ></iframe>
          ) : (
            <iframe
              style={{ minWidth: "700px", color: "white" }}
              title={title}
              height="353"
              color="white"
              src="https://charts.mongodb.com/charts-mywoodshop-hqcwv/embed/charts?id=4c24cc45-2fc3-43ed-b2d8-b3f5ad839ea2&maxDataAge=3600&theme=light&autoRefresh=true"
            ></iframe>
          )}
        </article>
      </div>
    </div>
  );
};

export default ProductViewStatistics;
