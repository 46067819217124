import axios from "axios";

const _axios = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api/' : `${process.env.REACT_APP_API_URL}:5000/api/`,
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000/api/"
      : `/api/`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

export default _axios;
