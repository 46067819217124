import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import AddProductMain from "../../components/dashboard/products/AddProductMain";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const AddProduct = () => {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <AddProductMain />
      </PageWrapper>
    </>
  );
};

export default AddProduct;
