import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Input from "../ui/form/Input";
import Select from "../ui/form/Select";

const BlogFilters = ({ submitHandler }) => {
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortby] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useDispatch();

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const memoizedSearch = useMemo(() => {
    const debounceSearch = debounce(async (query) => {
      setKeyword(query);
    }, 500);

    return debounceSearch;
  }, []);

  const search = (query) => {
    memoizedSearch(query);
  };

  useEffect(() => {
    setFirstRender(false);
    if (firstRender) return;
    submitHandler({ pageNumber: 1, keyword, sort: sortBy });
  }, [keyword, sortBy, dispatch, firstRender]);

  return (
    <div className="row py-3">
      <div className="col-lg-4 col-6 me-auto px-0">
        <Input
          id="search"
          type="search"
          placeholder="Pretraga..."
          inputClass={`form-control ${
            theme === "dark" ? "bg-dark text-light" : ""
          }`}
          onChange={(e) => search(e.target.value)}
        />
      </div>
      <div className="col-lg-2 col-6 col-md-3">
        <Select
          id="filter_by_date"
          inputClass={`form-select ${
            theme === "dark" ? "bg-dark text-light" : ""
          }`}
          onChange={(e) => setSortby(e.target.value)}
        >
          <option value="Latest added">Od poslednje dodatog</option>
          <option value="Previous added">Od prvog dodatog</option>
        </Select>
      </div>
    </div>
  );
};

export default BlogFilters;
