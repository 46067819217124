import React from "react";
import { useSelector } from "react-redux";

const WishListStatistics = ({ title }) => {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className="col-xl-6 col-lg-12">
      <div
        className={`card mb-4 shadow-sm ${
          theme === "dark" ? "bg-dark text-light" : ""
        }`}
      >
        <article className="card-body">
          <h5 className="card-title">Proizvodi u listi zelja</h5>
          {theme === "dark" ? (
            <iframe
              style={{ minWidth: "700px" }}
              title={title}
              height="353"
              src="https://charts.mongodb.com/charts-mywoodshop-hqcwv/embed/charts?id=64f72652-75e7-48bb-86e5-45f36b356f13&maxDataAge=3600&theme=dark&autoRefresh=true"
            ></iframe>
          ) : (
            <iframe
              title={title}
              style={{
                border: "none",
                borderRadius: "2px",
                // boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
              }}
              width="100%"
              height="353"
              src="https://charts.mongodb.com/charts-mywoodshop-hqcwv/embed/charts?id=64f72652-75e7-48bb-86e5-45f36b356f13&maxDataAge=3600&theme=light&autoRefresh=true"
            ></iframe>
          )}
        </article>
      </div>
    </div>
  );
};

export default WishListStatistics;
