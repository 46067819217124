import { useSelector } from "react-redux";

export default function Select({
  id,
  className,
  inputClass,
  label,
  value,
  onChange,
  children,
}) {
  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  return (
    <div className={`flex-grow-1 ${className}`}>
      {label && (
        <label htmlFor={id} className="form-label text-gray">
          {label}
        </label>
      )}
      <select
        id={id}
        className={`site-bg w-100 p-2 rounded-0 text-gray shadow-none focus-none border-1 border-focus-${theme}-500 border-transition-hover-${theme}-500 ${inputClass}`}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
}
