import React, { useEffect, useState } from "react";
import Header from "../../components/client/Header";
import Rating from "../../components/client/shop-components/Rating";
import Message from "../../components/client/loading-error/Error";
import Slideshow from "../../components/client/product-components/Slider";
import ProductCarousel from "../../components/client/common/ProductCarousel";
import FollowProduct from "../../components/client/product-components/FollowProduct";
import RatingProduct from "../../components/client/product-components/RatingProduct";
import ProductDescription from "../../components/client/common/ProductDescription";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { listProductDetails } from "../../Redux/client/Actions/ProductActions";
import { addToCart } from "../../Redux/client/Actions/cartActions";
import { listProduct } from "../../Redux/client/Actions/ProductActions";
import Loading from "../../components/client/loading-error/Loading";
import moment from "moment";
import Button from "../../components/client/ui/Button";
import Footer from "../../components/client/Footer";
import { listSimilarProducts } from "../../Redux/dashboard/Actions/ProductActions";
import Select from "../../components/client/ui/form/Select";
import { checkTodo } from "../../Redux/client/Actions/TodoActions";
import { TODO_CHECK_RESET } from "../../Redux/client/Constants/ToDoConstants";

const SingleProduct = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [dimensions, setDimensions] = useState({});
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  const productId = match.params.id;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const todoCheck = useSelector((state) => state.todoCheck);
  const { loadingCheck, errorCheck, numOfDays } = todoCheck;

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { loading: loadingCreateReview, success: successCreateReview } =
    productReviewCreate;

  const productList = useSelector((state) => state.productSimilarList);
  const { loading: loadingSimilarProducts, products: similarProducts } =
    productList;

  const settingsData = useSelector((state) => state.settings);
  const { theme, disableShop, contactPage } = settingsData;

  const AddToCartHandle = (e) => {
    e.preventDefault();
    // dispatch(removefromcart());
    dispatch(addToCart({ productId, qty }));
    history.push("/shipping");
  };

  const onCheckClick = () => {
    dispatch(
      checkTodo({ numOfDays: productDetails?.product.buildingDaysNum || 1 })
    );
  };

  useEffect(() => {
    dispatch(listProductDetails(productId));
  }, [dispatch, productId, successCreateReview]);

  useEffect(() => {
    if (productDetails?.product?.imageFiles) {
      const sliderImages = product.imageFiles.map((img) => {
        return {
          original: img.src,
          thumbnail: img.src,
          // caption: img.product_name
        };
      });
      setImages(sliderImages);
    }
  }, [productDetails?.product?.imageFiles, product?.imageFiles]);

  useEffect(() => {
    if (!product) return;
    product.dimensionsType === "free" &&
      setDimensions({
        width: product.freeDimensions.minWidth,
        length: product.freeDimensions.minLength,
        height: product.freeDimensions.minHeight,
      });
    product.dimensionsType === "specified" &&
      setDimensions(product.specifiedDimensions[0]);

    dispatch(listProduct({ category: product.category }));
    dispatch(listSimilarProducts({ similarProducts: product.similarProducts }));
  }, [dispatch, product]);

  useEffect(() => {
    return () => dispatch({ type: TODO_CHECK_RESET });
  }, []);

  return (
    <>
      <Header />
      <div className="container single-product py-lg-3 py-md-3 py-0">
        {loading ? (
          // <Loading />
          <></>
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div style={{ borderBottom: "1px inset #cfcfcf" }} className="pb-5">
              <div className="p-0 mb-2">
                <Button
                  className="w-auto p-2"
                  variant="outline"
                  onClick={() => history.goBack()}
                >
                  <i className="fas fa-arrow-left"></i> Nazad
                </Button>
              </div>
              <div className="single-product-halfs">
                <div className="mb-0 mb-xs-5 p-0 overflow-hidden">
                  <Slideshow sliderImages={images} />
                </div>

                <div className="mt-2 mt-md-0 mt-lg-0 px-0">
                  <div className="product-dtl">
                    <div
                      className={`border-${theme}-200 border-bottom-0 product-count mt-0 col-lg-7`}
                    >
                      <div
                        className={`border-bottom-1-${theme}-200 flex-box d-flex justify-content-between gap-2 align-items-center`}
                      >
                        <h6>Naziv:</h6>
                        <span>{product.name}</span>
                      </div>
                      <div
                        className={`border-bottom-1-${theme}-200 flex-box d-flex justify-content-between align-items-center`}
                      >
                        <h6>Cena:</h6>
                        <span>
                          {product.discountPrice || product.price} RSD
                        </span>
                      </div>
                      <div
                        className={`border-bottom-1-${theme}-200 flex-box d-flex justify-content-between align-items-center`}
                      >
                        <h6>Ocene:</h6>
                        <Rating
                          value={product.rating}
                          text={`${product.numReviews} ocene`}
                        />
                      </div>
                      {product.dimensionsType === "specified" && (
                        <div
                          className={`border-bottom-1-${theme}-200 flex-box w-auto d-flex justify-content-between gap-2 align-items-center`}
                        >
                          <h6>Dimenzije</h6>
                          <Select
                            onChange={(e) =>
                              setDimensions(JSON.parse(e.target.value))
                            }
                          >
                            {product.specifiedDimensions.map((item) => (
                              <option
                                key={item.id}
                                value={JSON.stringify(item)}
                              >
                                {`Sirina: ${item.width} / Duzina: ${item.length} / Visina: ${item.height} cm`}
                              </option>
                            ))}
                          </Select>
                        </div>
                      )}
                      {product.dimensionsType === "free" && (
                        <>
                          <div
                            className={`border-bottom-1-${theme}-200 flex-box gap-2 w-auto d-flex justify-content-between align-items-center`}
                          >
                            <h6>Sirina</h6>
                            <Select
                              value={dimensions.width}
                              onChange={(e) =>
                                setDimensions((dimensions) => ({
                                  ...dimensions,
                                  width: e.target.value,
                                }))
                              }
                            >
                              {[
                                ...Array(
                                  product.freeDimensions.maxWidth -
                                    product.freeDimensions.minWidth +
                                    1
                                ).keys(),
                              ].map((x) => (
                                <option
                                  key={x + product.freeDimensions.minWidth}
                                  value={x + product.freeDimensions.minWidth}
                                >
                                  {x + product.freeDimensions.minWidth}
                                </option>
                              ))}
                            </Select>
                            <span>cm</span>
                          </div>
                          <div
                            className={`border-bottom-1-${theme}-200 flex-box gap-2 w-auto d-flex justify-content-between align-items-center`}
                          >
                            <h6>Duzina</h6>
                            <Select
                              value={dimensions.length}
                              onChange={(e) =>
                                setDimensions((dimensions) => ({
                                  ...dimensions,
                                  length: e.target.value,
                                }))
                              }
                            >
                              {[
                                ...Array(
                                  product.freeDimensions.maxLength -
                                    product.freeDimensions.minLength +
                                    1
                                ).keys(),
                              ].map((x) => (
                                <option
                                  key={x + product.freeDimensions.minLength}
                                  value={x + product.freeDimensions.minLength}
                                >
                                  {x + product.freeDimensions.minLength}
                                </option>
                              ))}
                            </Select>
                            <span>cm</span>
                          </div>
                          <div
                            className={`border-bottom-1-${theme}-200 flex-box gap-2 w-auto d-flex justify-content-between align-items-center`}
                          >
                            <h6>Visina</h6>
                            <Select
                              value={dimensions.height}
                              onChange={(e) =>
                                setDimensions((dimensions) => ({
                                  ...dimensions,
                                  height: e.target.value,
                                }))
                              }
                            >
                              {[
                                ...Array(
                                  product.freeDimensions.maxHeight -
                                    product.freeDimensions.minHeight +
                                    1
                                ).keys(),
                              ].map((x) => (
                                <option
                                  key={x + product.freeDimensions.minHeight}
                                  value={x + product.freeDimensions.minHeight}
                                >
                                  {x + product.freeDimensions.minHeight}
                                </option>
                              ))}
                            </Select>
                            <span>cm</span>
                          </div>
                        </>
                      )}
                      {product.countInStock > 0 ? (
                        <>
                          <div className="flex-box d-flex gap-5 justify-content-between align-items-center">
                            <h6>Kolicina:</h6>
                            <Select
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            >
                              {[...Array(product.countInStock).keys()].map(
                                (x) => (
                                  <option key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </option>
                                )
                              )}
                            </Select>
                          </div>
                        </>
                      ) : null}
                    </div>
                    {product.countInStock > 0 && !disableShop ? (
                      <>
                        <Button
                          onClick={AddToCartHandle}
                          className="p-3 text-uppercase col-lg-7 col-12"
                        >
                          Dodaj u korpu
                        </Button>
                      </>
                    ) : (
                      <div className="mt-3">
                        <p>Kada bi proizvod bio izradjen?</p>
                        <Button
                          // onClick={AddToCartHandle}
                          className="p-3 text-uppercase col-lg-7 col-12"
                          onClick={onCheckClick}
                        >
                          <span className="mx-2">Upitajte</span>
                          {loadingCheck && (
                            <div
                              className="spinner-border spinner-border-sm text-white"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        </Button>

                        {errorCheck && (
                          <Message variant="error">Doslo je do greske</Message>
                        )}

                        {numOfDays && (
                          <div>
                            <p className="mt-2">
                              Datum izrade bio bi:
                              <span className="fw-bold px-2">
                                {moment()
                                  .add(numOfDays, "days")
                                  .format("DD-MM-YYYY")}
                              </span>
                            </p>
                            <a href="tel:+38162609250">
                              <Button variant="outline" className="mt-2">
                                Pozovite
                              </Button>
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <nav className="mt-4">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className={`text-${theme} nav-link active`}
                        id="nav-follow-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-follow"
                        type="button"
                        role="tab"
                        aria-controls="nav-follow"
                        aria-selected="false"
                      >
                        Zapratite
                      </button>
                      <button
                        className={`text-${theme} nav-link`}
                        id="nav-review-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-review"
                        type="button"
                        role="tab"
                        aria-controls="nav-review"
                        aria-selected="false"
                      >
                        Ocenite
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      style={{ borderColor: "#fff #dee2e6 #dee2e6" }}
                      className="tab-pane p-2 border-1 bg-white fade show active"
                      id="nav-follow"
                      role="tabpanel"
                      aria-labelledby="nav-follow-tab"
                    >
                      <FollowProduct productId={productId} />
                    </div>
                    <div
                      style={{ borderColor: "#fff #dee2e6 #dee2e6" }}
                      className="tab-pane p-2 border-1 bg-white fade"
                      id="nav-review"
                      role="tabpanel"
                      aria-labelledby="nav-review-tab"
                    >
                      <RatingProduct productId={productId} />
                    </div>
                  </div>
                </div>
              </div>
              <ProductDescription
                tableData={product.descriptionTable}
                description={product.description}
              />
            </div>

            {/* RATING */}
            <div className="row my-5">
              <h6 className="mb-3 p-0">OCENE</h6>
              <div
                style={{ maxHeight: "442px" }}
                className="col-md-6 px-0 overflow-auto"
              >
                {product.reviews.length === 0 && (
                  <Message variant={`bg-${theme}-100 mt-3`}>Nema ocena</Message>
                )}
                {product.reviews.map((review) => (
                  <div
                    key={review._id}
                    className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded"
                  >
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <span>{moment(review.createdAt).calendar()}</span>
                    <div className={`alert bg-${theme}-100 mt-3`}>
                      {review.comment}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        <ProductCarousel
          products={similarProducts}
          title="SLICNI PROIZVODI"
          similarProducts={product?.similarProducts}
        />
      </div>
      <Footer />

      {(loading || loadingCreateReview || loadingSimilarProducts) && (
        <Loading />
      )}
    </>
  );
};

export default SingleProduct;
