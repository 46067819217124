import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import Toast from "../loading-error/Toast";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import { addEmail } from "../../../Redux/client/Actions/NewsletterActions";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const CalltoActionSection = () => {
  const [email, setEmail] = useState("");
  const settingsData = useSelector((state) => state.settings);
  const { theme, callTo } = settingsData;

  const emailsData = useSelector((state) => state.addEmail);
  const { loading, success, error } = emailsData;

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      dispatch(addEmail({ email }));
      setEmail("");
    } else {
      toast.error("Neispravna email adresa.", ToastObjects);
    }
  };

  useEffect(() => {
    if (success) {
      toast.success("Uspesno ste se pretplatili.", ToastObjects);
    }
  }, [success]);

  return (
    <>
      <Toast />
      <div className="subscribe-section position-relative">
        <div
          className={`call-to-overlay bg-${theme}-700 position-absolute top-0 left-0 w-100 h-100`}
        ></div>
        <div className="container position-relative">
          {error && (
            <Message className="rounded-3" variant="alert-danger">
              {error}
            </Message>
          )}
          <div className="row">
            <div className="col-xs-12">
              <div className="subscribe-head text-white">
                <div className="mb-4">{parse(callTo?.callToText || "")}</div>
                <form className="form-section d-flex flex-lg-row flex-md-row flex-sm-row flex-column justify-content-center">
                  <input
                    placeholder="Vas Email..."
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className={`text-white border-${theme}-500 bg-${theme}-500 bg-transition-hover-${theme}-600`}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Da. Zelim!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Loading />}
    </>
  );
};

export default CalltoActionSection;
