import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Message from "../loading-error/Error";
import SortableImages from "../common/sortable/SortableImages";
import {
  createBlog,
  singleBlog,
  updateBlog,
} from "../../../Redux/dashboard/Actions/BlogActions";
import TextEditor from "../ui/TextEditor";
import Input from "../ui/form/Input";
import Loading from "../loading-error/Loading";

export default function CreateEditBlogModal(props) {
  const [title, setTitle] = useState("");
  const [richText, setRichText] = useState("");
  const [images, setImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const { onHide, blogid, show, type } = props;

  const dispatch = useDispatch();

  const inputRef = useRef();

  const blogData = useSelector((state) => state.blogSingle);
  const { loading, error, blog } = blogData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const resetForm = () => {
    setTitle("");
    setRichText("");
    setImages([]);
    inputRef.current.value = null;
    onHide();
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (blogid) {
      dispatch(
        updateBlog({
          id: blogid,
          title,
          richText,
          image: images[0],
          imageToDelete: imagesToDelete[0],
        })
      );
    } else {
      dispatch(
        createBlog({
          title,
          richText,
          image: images[0],
        })
      );
    }

    resetForm();
  };

  useEffect(() => {
    if (blogid && show && type === "edit") {
      dispatch(singleBlog(blogid));
    }
  }, [blogid, show, type]);

  useEffect(() => {
    if (blog && type === "edit") {
      setTitle(blog.title);
      setRichText(blog.richText);
      setImages([blog.image]);
    }
  }, [blog]);

  return (
    <Modal
      {...props}
      size="lg"
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={resetForm}
      className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`${blogid ? "Izmeni" : "Kreiraj"} Blog`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Message variant="alert-danger">{error}</Message>}
        <form style={{height: "calc(100% - 70px)"}} onSubmit={submitHandler}>
          <div className="d-flex flex-column gap-3 mb-4 overflow-auto h-100">
            <Input
              id="blog_title"
              label="Naziv"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div>
              <label className="form-label">Image</label>
              <SortableImages
                ref={inputRef}
                images={images}
                setImages={setImages}
                setImagesToDelete={blogid && setImagesToDelete}
              />
            </div>

            <TextEditor
              value={richText}
              onChange={(value) => setRichText(value)}
            />
          </div>

          {error && <Message variant="alert-danger">{error}</Message>}

          <div className="d-flex flex-column flex-md-row flex-lg-row mt-3 gap-3 flex-grow-1">
            <button
              disabled={!richText || images.length === 0}
              className="btn btn-primary py-3 flex-grow-1"
            >
              Sacuvaj
            </button>
            <button
              className="btn btn-danger py-3 flex-grow-1"
              onClick={(e) => {
                e.preventDefault();
                props.onHide();
                resetForm();
              }}
            >
              Zatvori
            </button>
          </div>
        </form>
      </Modal.Body>

      {loading && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
    </Modal>
  );
}
