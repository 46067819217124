import { useState, useEffect } from "react";
import Breadcrumb from "./Breadcrumb";
import { useSelector } from "react-redux";
export default function ShopImage({ breadcrumb }) {
  const [activeCategory, setActiveCategory] = useState("");

  const settingsData = useSelector((state) => state.settings);
  const { theme, breadcrumbBg } = settingsData;

  useEffect(() => {
    if (breadcrumb) {
      let breadcrumbArray = breadcrumb.split("/");
      setActiveCategory(
        breadcrumbArray[breadcrumbArray.length - 1].replace(/_/g, " ")
      );
    }
  }, [breadcrumb]);

  return (
    <div style={{backgroundImage: `url(${breadcrumbBg && breadcrumbBg[0].src})`}} className="shop-image-wrapper py-5 d-flex content-center items-center w-100">
      <div>
        {breadcrumb !== "undefined" && <Breadcrumb path={breadcrumb} />}
        {activeCategory !== "undefined" && (
          <h1 className={`text-capitalize text-center text-${theme}-500`}>{activeCategory}</h1>
        )}
      </div>
    </div>
  );
}
