import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import Orders from "./Orders";
import OrderFilters from "./OrderFilters";
import Pagination from "../common/Pagination";
import { listOrders } from "../../../Redux/dashboard/Actions/OrderActions";
import { Section, Card, CardHeader, CardBody } from "../common";

const socket = io.connect(`${process.env.REACT_APP_API_URL}`);
const OrderMain = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [toShow, setToShow] = useState(20);

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const dispatch = useDispatch();

  const submitHandler = ({ pageNumber, keyword, orderStatus, toShow }) => {
    dispatch(listOrders({ pageNumber, keyword, orderStatus, toShow }));
  };

  useEffect(() => {
    submitHandler({pageNumber: 1, keyword: "", orderStatus: "", toShow: 20});
  }, [dispatch]);

  useEffect(() => {
    if (orders?.orders?.length) {
      const { page, pages, prevKeyword, orderStatus, toShow } = orders;
      setPage(page);
      setPages(pages);
      setKeyword(prevKeyword);
      setOrderStatus(orderStatus);
      setToShow(toShow);
      socket.emit('new_orders_count');
    }
  }, [orders, dispatch]);

  useEffect(() => {
    socket.on("list_order", () => {
      submitHandler({pageNumber: 1, keyword: "", orderStatus: "", toShow: 20});
    });
    
    socket.emit("get_in_orders_room");

    return () => {
      socket.emit("get_out_orders_room");
      socket.emit("user-disconect");
    }
  }, []);

  return (
    <Section className="position-relative">
      <div className="content-header">
        <h2 className="content-title">Porudzbine</h2>
      </div>

      <Card section className="mb-4 shadow-sm">
        <CardHeader>
          <OrderFilters submitHandler={submitHandler} />
        </CardHeader>

        <CardBody className="card-body">
          <div className="table-responsive">
            {loading ? (
              <Loading position="position-absolute" width="100%" height="100%" />
            ) : error ? (
              <Message variant="alert-danger">{error}</Message>
            ) : (
              <Orders orders={orders?.orders} />
            )}
          </div>

          <Pagination
            page={page}
            pages={pages}
            keyword={keyword}
            orderStatus={orderStatus}
            toShow={toShow}
            submitHandler={submitHandler}
          />
        </CardBody>
      </Card>
    </Section>
  );
};

export default OrderMain;
