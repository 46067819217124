import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import UserMain from "../../components/dashboard/users/SingleUserMain";
import PageWrapper from "../../components/dashboard/common/PageWapper";

export default function SingleUserScreen({ match }) {
  const userId = match.params.id;
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <UserMain userId={userId} />
      </PageWrapper>
    </>
  );
}
