import React from "react";
import { slide as Menu } from "react-burger-menu";
import ShopFilters from "./ShopFilters";

export default function MobileFilters({ keywordProp }) {
  let styles = {
    bmBurgerButton: {
      position: "sticky",
      top: "10px",
      justifyContent: "space-between",
      width: "80px",
      height: "25px",
      fontSize: "21px",
    },
    bmBurgerBars: {
      background: "gray",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      width: "80%",
      left: 0,
      top: 0,
      background: "white",
    },
    bmMenu: {
      background: "white",
      padding: "2.5em 0 0",
      fontSize: "1.15em",
      margin: "auto",
    },
    bmMorphShape: {
      fill: "white",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
      width: "100%",
    },
    bmItem: {
      display: "block",
      color: "#916408",
      padding: "10px 15px 10px 0",
      whiteSpace: "nowrap",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      top: 0,
      left: 0,
    },
  };

  return (
    <>
      <Menu
        styles={styles}
        customBurgerIcon={
          <>
            <i className="fal text-gray fa-bars"></i> Filteri
          </>
        }
      >
        <ShopFilters keywordProp={keywordProp} />
      </Menu>
    </>
  );
}
