import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createProductReview } from "../../../Redux/client/Actions/ProductActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../../../Redux/client/Constants/ProductConstants";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import Select from "../ui/form/Select";
import Button from "../ui/Button";

const RatingProduct = ({ productId }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingCreateReview,
    error: errorCreateReview,
    success: successCreateReview,
  } = productReviewCreate;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(productId, {
        rating,
        comment,
      })
    );
  };

  useEffect(() => {
    if (successCreateReview) {
      setRating(0);
      setComment("");
    }
    dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
  }, [dispatch, productId, successCreateReview]);

  return (
    <div className="col-md-12">
      {loadingCreateReview && <Loading />}
      {errorCreateReview && (
        <div className="my-4">
          <Message variant="alert-danger">{errorCreateReview}</Message>
        </div>
      )}
      {userInfo ? (
        <form onSubmit={submitHandler}>
          <div className="my-4">
            <Select
              label="Ocena"
              value={rating}
              required
              onChange={(e) => setRating(e.target.value)}
            >
              <option value="">Odabir...</option>
              <option value="1">1 - Lose</option>
              <option value="2">2 - Nije Lose</option>
              <option value="3">3 - Dobro</option>
              <option value="4">4 - Veoma Dobro</option>
              <option value="5">5 - Odlicno</option>
            </Select>
          </div>
          <div className="my-4">
            <label className="form-label text-gray">Komentar</label>
            <textarea
              row="3"
              value={comment}
              placeholder="Ostavite komentar"
              required
              onChange={(e) => setComment(e.target.value)}
              className={`col-12 p-3 mt-2 text-gray border-1 rounded-0 border-transition-hover-${theme}-500`}
            ></textarea>
          </div>
          <div className="my-3">
            <Button
              variant="outline"
              disabled={loadingCreateReview}
              className="p-3 text-uppercase w-100"
            >
              OCENITE
            </Button>
          </div>
        </form>
      ) : (
        <div className="my-3">
          <Message variant={"alert-warning"}>
            Molimo{" "}
            <Link to="/login">
              " <strong>Prijavite se</strong> "
            </Link>{" "}
            da bi ste ocenili proizvod{" "}
          </Message>
        </div>
      )}
    </div>
  );
};

export default RatingProduct;
