import React, { useMemo, Suspense } from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removefromcart,
} from "../../../Redux/client/Actions/cartActions";
// import parse from "html-react-parser";
import { Badge } from "antd";
import { useImage } from "react-image";
import ProductDescription from "../common/ProductDescription";
import {
  likeProduct,
  likeProductFromCarousel,
} from "../../../Redux/client/Actions/ProductActions";

export default function ProductCard({
  product,
  preview,
  setShowModal,
  fromCarousel,
  similarProducts,
}) {
  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state.settings);
  const { theme, disableShop } = settingsData;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const onLikeClick = (e) => {
    e.preventDefault();

    if (userInfo) {
      if (fromCarousel) {
        dispatch(
          likeProductFromCarousel({
            productId: product._id,
            similarProducts,
          })
        );
      } else {
        dispatch(
          likeProduct({
            productId: product._id,
          })
        );
      }
    } else {
      setShowModal(true);
    }
  };

  console.log("product:::", product);

  const onCartClick = (e, productId) => {
    e.preventDefault();

    const cartProduct = cartItems?.find((n) => n.product === productId);
    if (cartProduct) {
      dispatch(removefromcart(productId));
    } else {
      dispatch(addToCart({ productId: productId, qty: 1 }));
    }
  };

  const getBadgeColor = useMemo(() => {
    if (product.discount.type === "%") {
      return product.discount.value < 30 ? "yellow" : "red";
    } else {
      const percent = (100 * product.discount.value) / product.price;
      return percent < 30 ? "yellow" : "red";
    }
  }, [product]);

  const ProductImage = React.memo(({ imgSrc, alt }) => {
    const { src } = useImage({
      srcList: imgSrc,
    });

    return (
      <img
        style={{
          width: preview === "card" && "30%",
          height: preview === "card" && "30%",
        }}
        src={src}
        alt={alt}
      />
    );
  });

  const Product = () => {
    return (
      <div
        style={{ height: preview === "card" ? "auto" : "395px" }}
        className={`product-card card border-1 rounded-0 border-transition-hover-${theme}-500 p-3 w-100`}
        key={product._id}
      >
        <div
          style={{ height: preview === "grid" && "350px" }}
          className={`${
            preview === "card"
              ? "gap-2"
              : "d-flex h-100 flex-column justify-content-between"
          }`}
        >
          <Link
            style={{ width: preview === "card" && "40%" }}
            to={`/products/${product._id}`}
          >
            <div className="shopBack">
              <Suspense
                fallback={<span className="text-gray">Ucitavanje...</span>}
              >
                <ProductImage
                  imgSrc={product.imageFiles[0]?.src}
                  alt={product.name}
                />
              </Suspense>
            </div>
          </Link>

          <div
            className={`shoptext ${
              preview === "card" ? "d-flex px-1 flex-column flex-grow-1" : ""
            }`}
          >
            <Link
              to={`/products/${product._id}`}
              className="text-gray d-block text-truncate w-100"
            >
              {product.name}
            </Link>

            <Rating
              value={product.rating}
              text={`${product.numReviews} ocena`}
            />
            {preview === "card" && (
              <div className="flex-grow-1 mb-4">
                <ProductDescription
                  productId={product._id}
                  tableData={product.descriptionTable}
                  description={product.description}
                />
              </div>
            )}
            <div className="flex space-between">
              <h5 className="text-gray">
                {!product.discount.value ? (
                  <span>{Number.parseFloat(product.price)} RSD</span>
                ) : (
                  <div>
                    <div className="position-relative">
                      <span>{Number.parseFloat(product.price)} RSD</span>
                      <div
                        style={{ height: "2px", top: "49%" }}
                        className="red-line position-absolute bg-red w-100"
                      ></div>
                    </div>
                    <p>{Number.parseFloat(product.discountPrice)} RSD</p>
                  </div>
                )}
              </h5>
              <div
                style={{ fontSize: "18px" }}
                className="d-flex gap-2 align-items-end"
              >
                <button className="btn p-1 shadow-none" onClick={onLikeClick}>
                  <div
                    className={`position-relative text-hover-${theme}-500 ${
                      userInfo && product.likes.includes(userInfo._id)
                        ? `text-${theme}-500`
                        : ""
                    } text-gray`}
                  >
                    {product?.likes.length > 0 && (
                      <span
                        style={{ top: "-15px", left: "-5px" }}
                        className="num-likes small text-gray position-absolute"
                      >
                        {product?.likes.length}
                      </span>
                    )}
                    <i className="fas fa-heart"></i>
                  </div>
                </button>

                {!disableShop && (
                  <button
                    className="add-to-cart p-1 btn shadow-none"
                    disabled={product.countInStock === 0}
                    onClick={(e) => onCartClick(e, product._id)}
                  >
                    <div
                      className={`text-hover-${theme}-500 ${
                        cartItems?.find((n) => n.product === product._id)
                          ? `text-${theme}-500`
                          : ""
                      } text-gray`}
                    >
                      <i className="fas fa-cart-plus"></i>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* {!sellerId && (
            <a
              href={`/shop-seller/${product.seller._id}`}
              className={`avatar-wrapper border-${theme}-500 outline-hover-${theme}-500`}
            >
              <img src={product.seller?.avatarImage?.url} alt="Avatar image" />
            </a>
          )} */}
        </div>

        {product.isNew && (
          <div
            style={{ top: "8px", left: "8px", fontSize: "15px" }}
            className={`position-absolute px-2 py-1 bg-${theme}-500 text-white`}
          >
            NOVO
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {product.discount.value > 0 ? (
        <Badge.Ribbon
          text={`-${product.discount.value} ${product.discount.type}`}
          color={getBadgeColor}
          className={getBadgeColor === "yellow" ? "badge-text-dark" : ""}
          key={product._id}
        >
          <Product />
        </Badge.Ribbon>
      ) : (
        <Product />
      )}
    </>
  );
}
