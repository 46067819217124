import React, { useEffect } from "react";
import "./App.scss";
import "./responsive.css";
import "./dashboard.scss";
import "./dashboard-responsive.css";
import "./dashboard-theme.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/client/HomeScreen";
import ContactScreen from "./screens/client/ContactScreen";
import ShopScreen from "./screens/client/ShopScreen";
import BlogClientScreen from "./screens/client/BlogClientScreen";
import SingleBlog from "./screens/client/SingleBlogScreen";
import SellerShopScreen from "./screens/client/SellerShopScreen";
import SingleProduct from "./screens/client/SingleProductScreen";
import Login from "./screens/client/Login";
import Register from "./screens/client/Register";
import CartScreen from "./screens/client/CartScreen";
import FavoriteScreen from "./screens/client/FavoriteScreen";
import ShippingScreen from "./screens/client/ShippingScreen";
import ProfileScreen from "./screens/client/ProfileScreen";
import PaymentScreen from "./screens/client/PaymentScreen";
import PlaceOrderScreen from "./screens/client/PlaceOrderScreen";
import OrderScreen from "./screens/client/OrderScreen";
import ForgotPassword from "./screens/client/ForgotPassword";
import ResetPassword from "./screens/client/ResetPassword";
import NotFound from "./screens/client/NotFound";

// DASHBOARD
import DashProfileScreen from "./screens/dashboard/ProfileScreen";
import DashHomeScreen from "./screens/dashboard/HomeScreen";
import ProductScreen from "./screens/dashboard/ProductScreen";
import CategoryScreen from "./screens/dashboard/CategoryScreen";
import BrandScreen from "./screens/dashboard/BrandScreen";
import MaterialScreen from "./screens/dashboard/MaterialScreen";
import BlogScreen from "./screens/dashboard/BlogScreen";
import CategoryRequestScreen from "./screens/dashboard/CategoryRequestScreen";
import CategoryRequestsScreen from "./screens/dashboard/CategoryRequestsScreen";
import CategoryEditScreen from "./screens/dashboard/CategoryEditScreen";
import SellersScreen from "./screens/dashboard/SellersScreen";
import SellerEditScreen from "./screens/dashboard/SellerEditScreen";
import DashOrderScreen from "./screens/dashboard/OrderScreen";
import CommissionScreen from "./screens/dashboard/CommissionScreen";
import OrderDetailScreen from "./screens/dashboard/OrderDetailScreen";
import SettingsScreen from "./screens/dashboard/SettingsScreen";
import AddProduct from "./screens/dashboard/AddProduct";
// import DashLogin from "./screens/dashboard/LoginScreen";
import UsersScreen from "./screens/dashboard/UsersScreen";
import SingleUserScreen from "./screens/dashboard/SingleUserScreen";
import ProductEditScreen from "./screens/dashboard/ProductEditScreen";
import Chat from "./screens/dashboard/ChatScreen";
import ProcurementScreen from "./screens/dashboard/ProcurementScreen";
// import DashNotFound from "./screens/dashboard/NotFound";

import {
  LogedinRouter,
  SuperAdminRouter,
  BothAdminRouter,
  AdminRouter,
} from "./PrivateRouter";
import { useDispatch, useSelector } from "react-redux";
// import { io } from "socket.io-client";
import { getSettings } from "./Redux/dashboard/Actions/SettingsActions";
import ShopBrandScreen from "./screens/client/ShopBrandScreen";
import TodoScreen from "./screens/dashboard/TodoScreen";
// const socket = io.connect(`${process.env.REACT_APP_API_URL}`);
const App = () => {
  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state.settings);
  const { disableShop } = settingsData;

  useEffect(() => {
    dispatch(getSettings());
    // socket.on("settings_saved", () => {
    //   dispatch(getSettings());
    // });
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <HomeScreen {...props} authed={true} />}
        />
        <Route path="/search/:keyword" component={HomeScreen} exact />
        <Route path="/page/:pagenumber" component={HomeScreen} exact />
        <Route path="/" component={HomeScreen} exact />
        <Route path="/contact" component={ContactScreen} />
        <Route path="/client-blogs" component={BlogClientScreen} exact />
        <Route path="/client-blogs/:id" component={SingleBlog} />
        <Route path="/products/:id" component={SingleProduct} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <LogedinRouter path="/profile" component={ProfileScreen} />
        <Route path="/cart/:id?" component={CartScreen} />
        <Route path="/favorite" component={FavoriteScreen} />

        <Route path="/shop/:slug" component={ShopScreen} />
        <Route path="/shop-brand/:brand" component={ShopBrandScreen} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route
          path="/reset-password/:userId/:token"
          component={ResetPassword}
          exact
        />

        {/* DASHBOARD */}
        {/* <div className="dashboard-wrapper"> */}
        <BothAdminRouter path="/dashboard" component={DashHomeScreen} />
        <BothAdminRouter
          path="/dashboard-profile"
          component={DashProfileScreen}
        />
        <BothAdminRouter path="/dashboard-products" component={ProductScreen} />
        <SuperAdminRouter path="/category" component={CategoryScreen} />
        <SuperAdminRouter
          path="/category-edit-info/:id"
          component={CategoryEditScreen}
        />
        <SuperAdminRouter path="/brands" component={BrandScreen} />
        <SuperAdminRouter path="/materials" component={MaterialScreen} />
        <SuperAdminRouter path="/blogs" component={BlogScreen} />
        <SuperAdminRouter path="/sellers" component={SellersScreen} />
        <SuperAdminRouter
          path="/seller-edit-info/:id"
          component={SellerEditScreen}
        />
        <BothAdminRouter path="/orders" component={DashOrderScreen} />
        <SuperAdminRouter path="/procurement" component={ProcurementScreen} />
        <SuperAdminRouter path="/commissions" component={CommissionScreen} />
        <BothAdminRouter
          path="/dashboard-order/:id"
          component={OrderDetailScreen}
        />
        <BothAdminRouter path="/addproduct" component={AddProduct} />
        <BothAdminRouter path="/users" component={UsersScreen} exact />
        <BothAdminRouter path="/users/:id" component={SingleUserScreen} />
        <BothAdminRouter
          path="/product/:id/edit"
          component={ProductEditScreen}
        />
        <AdminRouter
          path="/category-request"
          component={CategoryRequestScreen}
        />
        <SuperAdminRouter
          path="/category-requests"
          component={CategoryRequestsScreen}
        />
        <SuperAdminRouter path="/todos" component={TodoScreen} />
        <BothAdminRouter path="/chat" component={Chat} />
        <SuperAdminRouter path="/settings" component={SettingsScreen} />
        {/* <Route path="/login" component={Login} /> */}
        {/* <BothAdminRouter path="*" component={NotFound} /> */}
        {/* </div> */}

        {!disableShop && (
          <>
            <Route path="/shipping" component={ShippingScreen} />
            <Route path="/placeorder" component={PlaceOrderScreen} />
            <LogedinRouter path="/payment" component={PaymentScreen} />
            <LogedinRouter path="/order/:id" component={OrderScreen} />
            <Route path="/shop-seller/:sellerId" component={SellerShopScreen} />
          </>
        )}

        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
