import {
  TODO_CHECK_REQUEST,
  TODO_CHECK_SUCCESS,
  TODO_CHECK_FAIL,
  TODO_CHECK_RESET,
} from "../Constants/ToDoConstants";

// TO DO CHECK DAYS
export const todoCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case TODO_CHECK_REQUEST:
      return { loading: true };
    case TODO_CHECK_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case TODO_CHECK_FAIL:
      return { loading: false, error: action.payload };
    case TODO_CHECK_RESET:
      return {};
    default:
      return state;
  }
};
