import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import Commissions from "./CommissionsTable";
import CommissionFilters from "./CommissionFilters";
import Pagination from "../common/Pagination";
import {
  listCommissions,
  deleteCommission,
} from "../../../Redux/dashboard/Actions/CommissionActions";
import { sendCommissionMail } from "../../../Redux/dashboard/Actions/PostActions";

const CommissionMain = () => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [toShow, setToShow] = useState(20);
  const [commissionStatus, setCommissionStatus] = useState();

  const commissionData = useSelector((state) => state.commissionList);
  const { loading, error, commissions } = commissionData;

  const sendMail = useSelector((state) => state.sendMail);
  const { sendMailInfo } = sendMail;

  const dispatch = useDispatch();

  const submitHandler = (pageNumber, keyword, commissionStatus, toShow) => {
    dispatch(listCommissions({ pageNumber, keyword, commissionStatus, toShow }));
  };

  const forwardHandler = (seller) => {
    dispatch(sendCommissionMail({ seller }));
  };

  const deleteHandler = (id) => {
    dispatch(deleteCommission(id));
  };

  useEffect(() => {
    submitHandler(1, "", "", 20);
  }, [dispatch, sendMailInfo?.success]);

  useEffect(() => {
    if (commissions?.commission?.length) {
      const { page, pages } = commissions;
      setPage(page);
      setPages(pages);
    }
  }, [commissions, dispatch]);


  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Commissions</h2>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white">
          <CommissionFilters
            submitHandler={submitHandler}
            setKeyword={setKeyword}
            keyword={keyword}
            setToShow={setToShow}
            toShow={toShow}
            setCommissionStatus={setCommissionStatus}
            commissionStatus={commissionStatus}
          />
        </header>

        <div className="card-body">
          <div /* className="table-responsive" */>
            {loading ? (
              <Loading />
            ) : error ? (
              <Message variant="alert-danger">{error}</Message>
            ) : (
              <Commissions
                commissions={commissions?.commission}
                forwardHandler={forwardHandler}
                deleteHandler={deleteHandler}
              />
            )}
          </div>

          <Pagination
            page={page}
            pages={pages}
            keyword={keyword}
            commissionStatus={commissionStatus}
            toShow={toShow}
            submitHandler={submitHandler}
          />
        </div>
      </div>
    </section>
  );
};

export default CommissionMain;
