import React, { useEffect, useState } from "react";
import Toast from "../loading-error/Toast";
import { toast } from "react-toastify";
import CategoryTree from "../common/CategoryTree";
// import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategoryRequest,
  categoryRequestList,
} from "../../../Redux/dashboard/Actions/CategoryRequestActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import RequestsTable from "../common/RequestsTable";
import { io } from "socket.io-client";
import { Section } from "../common";

const CategoryRequest = () => {
  const socket = io.connect(`${process.env.REACT_APP_API_URL}`);
  const [targetCategory, setTargetCategory] = useState("");
  const [productsNumber, setProductsNumber] = useState("");
  const [newCategoryName, setNewCategoryName] = useState("");
  const [additionalRequests, setAdditionalRequests] = useState("");

  // const categoryRequestData = useSelector(
  //   (state) => state.categoryRequestCreate
  // );

  const categoryRequestData = useSelector((state) => state.categoryRequestList);
  const { loading, error, requests } = categoryRequestData;

  const userData = useSelector((state) => state.userLogin);
  const { userInfo } = userData;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    theme,
  };

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (targetCategory) {
      dispatch(
        createCategoryRequest({
          targetCategory,
          productsNumber,
          newCategoryName,
          additionalRequests,
        })
      );

      socket.emit("admin_sent_request");

      toast.success("Your request has been sent.", ToastObjects);
      setTargetCategory("");
      setProductsNumber("");
      setNewCategoryName("");
      setAdditionalRequests("");
    } else {
      toast.error("Select target category.", ToastObjects);
    }
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(
        categoryRequestList({
          pageNumber: 1,
          keyword: "",
          email: userInfo.email,
        })
      );
    }
  }, [userInfo]);

  useEffect(() => {
    socket.emit("request");

    return () => {
      socket.emit("disconect");
    };
  }, [dispatch, socket, userInfo]);

  return (
    <>
      <Toast />
      <Section>
        <form onSubmit={submitHandler}>
          <div className="mb-4">
            <div className="p-0 m-auto">
              <div
                className={`card mb-4 shadow-sm ${
                  theme === "dark" ? "dark-theme-section" : ""
                }`}
              >
                <div
                  className={`card-body ${
                    theme === "dark" ? "dark-theme-section" : ""
                  }`}
                >
                  <div className="mb-4">
                    <label
                      htmlFor="category"
                      className="form-label text-center d-block"
                    >
                      Select the category where requested category should be
                      placed:
                    </label>
                    <CategoryTree
                      targetCategory={targetCategory}
                      setTargetCategory={setTargetCategory}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="category" className="form-label">
                      The new category will be created only if you have to offer
                      5 or more different products for the category. How many
                      products do you have to offer?
                    </label>
                    <select
                      placeholder="Choose option"
                      className={`form-control ${
                        theme === "dark" ? "bg-dark text-light" : ""
                      }`}
                      id="category"
                      required
                      value={productsNumber}
                      onChange={(e) => {
                        setProductsNumber(e.target.value);
                      }}
                    >
                      <option value={""}></option>
                      <option value={"more than 5"}>More than 5</option>
                      <option value={"5 - 10"}>5 - 10</option>
                      <option value={"more than 10"}>More than 10</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="categoryName" className="form-label">
                      The New Category's Name
                    </label>
                    <input
                      type="text"
                      placeholder="New Category Name"
                      className={`form-control ${
                        theme === "dark" ? "bg-dark text-light" : ""
                      }`}
                      id="categoryName"
                      required
                      value={newCategoryName}
                      onChange={(e) => {
                        setNewCategoryName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="additionalRequest" className="form-label">
                      Additional Request
                    </label>
                    <textarea
                      placeholder="Additional request"
                      className={`form-control ${
                        theme === "dark" ? "bg-dark text-light" : ""
                      }`}
                      id="additionalRequest"
                      value={additionalRequests}
                      onChange={(e) => {
                        setAdditionalRequests(e.target.value);
                      }}
                    />
                  </div>

                  {error && <Message variant="alert-danger">{error}</Message>}
                  <div className="d-flex justify-content-end ">
                    <button type="submit" className="btn btn-primary">
                      Send Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="mb-4">
          <div className="p-0 m-auto">
            <div
              className={`card mb-4 shadow-sm ${
                theme === "dark" ? "dark-theme-section" : ""
              }`}
            >
              <div
                className={`card-body overflow-auto ${
                  theme === "dark" ? "dark-theme-section" : ""
                }`}
              >
                <h5 className="text-center">Your Requests</h5>
                <div className="table-responsive">
                  <RequestsTable requests={requests?.requests} />
                </div>

                {error && <Message variant="alert-danger">{error}</Message>}
                {loading && (
                  <Loading
                    position="position-absolute"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default CategoryRequest;
