import {
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_FOLLOWER_FAIL,
  PRODUCT_FOLLOWER_REQUEST,
  PRODUCT_FOLLOWER_RESET,
  PRODUCT_FOLLOWER_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIKE_FAIL,
  PRODUCT_LIKE_REQUEST,
  PRODUCT_LIKE_SUCCESS,
  PRODUCT_HIGHEST_PRICE_FAIL,
  PRODUCT_HIGHEST_PRICE_REQUEST,
  PRODUCT_HIGHEST_PRICE_SUCCESS,
  PRODUCT_PREVIEW,
  PRODUCT_LIST_HOME_REQUEST,
  PRODUCT_LIST_HOME_SUCCESS,
  PRODUCT_LIST_HOME_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,
  FAVORITE_LIST_REQUEST,
  FAVORITE_LIST_SUCCESS,
  FAVORITE_LIST_FAIL,
} from "../Constants/ProductConstants";

// PRODUCT LIST
export const clientProductListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        pages: action.payload.pages,
        page: action.payload.page,
        prevKeyword: action.payload.prevKeyword,
        category: action.payload.category,
        toShow: action.payload.toShow,
        sort: action.payload.sort,
        prevSlug: action.payload.prevSlug,
        products: action.payload.products,
      };
    case PRODUCT_LIST_SUCCESS:
      return {
        products: action.payload,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSearchReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_SEARCH_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_SEARCH_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
      };
    case PRODUCT_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// HIGHEST PRODUCT PRICE
export const highestPriceReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_HIGHEST_PRICE_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_HIGHEST_PRICE_SUCCESS:
      return { loading: false, highest: action.payload };
    case PRODUCT_HIGHEST_PRICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// SINGLE PRODUCT
export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCT REVIEW CREATE
export const productCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

// PRODUCT FOLLOWER CREATE
export const productFollowerReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_FOLLOWER_REQUEST:
      return { loading: true };
    case PRODUCT_FOLLOWER_SUCCESS:
      return { loading: false, success: "Uspesno ste zapratili ovaj proizvod" };
    case PRODUCT_FOLLOWER_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_FOLLOWER_RESET:
      return {};
    default:
      return state;
  }
};

// PRODUCT LIKE
export const productLikeReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_LIKE_REQUEST:
      return { loading: true };
    case PRODUCT_LIKE_SUCCESS:
      return { loading: false, success: action.payload.message };
    case PRODUCT_LIKE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCT PREVIEW
export const productPreviewReducer = (state = { preview: {} }, action) => {
  switch (action.type) {
    case PRODUCT_PREVIEW:
      return { preview: action.payload };
    default:
      return state;
  }
};

export const clientProductListDiscountReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_HOME_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_HOME_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_LIST_HOME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// FAVORITE PRODUCTS
export const favoriteReducer = (state = {}, action) => {
  switch (action.type) {
    case FAVORITE_LIST_REQUEST:
      return { loading: true };
    case FAVORITE_LIST_SUCCESS:
      return { loading: false, ...action.payload };
    case FAVORITE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
