import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_PREVIEW,
} from "../Constants/BlogConstants";

// ALL BLOGS
export const clientBlogListReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_LIST_REQUEST:
      return { loading: true };
    case BLOG_LIST_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case BLOG_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCT PREVIEW
export const blogPreviewReducer = (state = { preview: {} }, action) => {
  switch (action.type) {
    case BLOG_PREVIEW:
      return { preview: action.payload };
    default:
      return state;
  }
};
