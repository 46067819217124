import React, { useEffect } from "react";
import { Card, CardBody, CardHeader } from "../common";
import { Section } from "../common";
import ProcurementTable from "./ProcurementTable";
import { useDispatch, useSelector } from "react-redux";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";
import { listProducts } from "../../../Redux/dashboard/Actions/ProductActions";
import ProductFilters from "../common/ProductFilters";
import Pagination from "../common/Pagination";

export default function MainProcurement() {
  const productsData = useSelector((state) => state.productList);
  const { loading, error, page, pages, prevKayword, sort, products } =
    productsData;

  const dispatch = useDispatch();

  const submitHandler = ({ pageNumber, keyword, outOfStock }) => {
    dispatch(listProducts({ pageNumber, keyword, outOfStock }));
  };

  return (
    <Section className="position-relative">
      <div className="content-header">
        <h2 className="content-title">Proizvodi</h2>
        <button className="btn btn-primary">Poruci</button>
      </div>

      <Card section className="shadow-sm">
        <CardHeader>
          <ProductFilters submitHandler={submitHandler} outOfStock={true} />
        </CardHeader>
        <CardBody>
          {error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <ProcurementTable products={products} />
          )}
        </CardBody>
      </Card>
      {loading && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
      <Pagination
        page={page}
        pages={pages}
        keyword={prevKayword}
        sort={sort}
        submitHandler={submitHandler}
      />
    </Section>
  );
}
