import _axios from "_axios";
import {
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL,
} from "../Constants/SettingsConstants";
import { io } from "socket.io-client";

// ADD TO CART
export const getSettings = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SETTINGS_REQUEST });
    const { data } = await _axios.get(`/settings`);
    dispatch({
      type: SETTINGS_SUCCESS,
      payload: data[0],
    });

    localStorage.setItem("settings", JSON.stringify(getState().settings));
  } catch (error) {
    dispatch({
      type: SETTINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const saveSettings =
  (settings, imagesToDelete) => async (dispatch, getState) => {
    try {
      dispatch({ type: SETTINGS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };
      const socket = io.connect(`${process.env.REACT_APP_API_URL}`);

      const { data } = await _axios.post(
        `/settings/save`,
        { settings, imagesToDelete },
        config
      );

      socket.emit("settings_save");

      dispatch(getSettings());
    } catch (error) {
      dispatch({
        type: SETTINGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

