import React from "react";
import CreateSeller from "./CreateSeller";
import SellersTable from "./SellersTable";

const MainSellers = () => {
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Prodavci</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create seller */}
            <CreateSeller />
            {/* Sellers table */}
            <SellersTable />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSellers;
