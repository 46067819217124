import {
  ORDER_DELIVERED_FAIL,
  ORDER_DELIVERED_REQUEST,
  ORDER_DELIVERED_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  NEW_ORDER_REQUEST,
  NEW_ORDER_SUCCESS,
  NEW_ORDER_FAIL,
  ORDER_STATUS_REQUEST,
  ORDER_STATUS_SUCCESS,
  ORDER_STATUS_FAIL,
  ORDER_COMPLAINT_REQUEST,
  ORDER_COMPLAINT_SUCCESS,
  ORDER_COMPLAINT_FAIL,
} from "../Constants/OrderConstants";
import { logout } from "./UserActions";
import _axios from "_axios";
import io from "socket.io-client";

export const listOrders =
  ({ pageNumber, keyword, orderStatus, toShow, byEmail }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `orders/all?pageNumber=${pageNumber}&keyword=${keyword}&orderStatus=${orderStatus}&toShow=${toShow}&isAdmin=${userInfo.isAdmin}&email=${userInfo.email}&byEmail=${byEmail}`,
        config
      );

      dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_LIST_FAIL,
        payload: message,
      });
    }
  };

// ORDER DETAILS
export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await _axios.get(`orders/${id}`, config);

    const socket = io.connect(`${process.env.REACT_APP_API_URL}`);
    socket.emit("new_orders_count");

    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};

// ORDER DELEVERED
export const sendOrderToSeller = (order) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DELIVERED_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await _axios.put(
      `orders/${order._id}/delivered`,
      {},
      config
    );

    dispatch({ type: ORDER_DELIVERED_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELIVERED_FAIL,
      payload: message,
    });
  }
};

export const newOrderCount = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NEW_ORDER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await _axios.get(`orders/new-order-count`, config);

    dispatch({ type: NEW_ORDER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: NEW_ORDER_FAIL,
      payload: message,
    });
  }
};

export const orderStatus =
  ({ id, status }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_STATUS_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.put(
        `orders/${id}/status`,
        { status },
        config
      );

      dispatch({ type: ORDER_STATUS_SUCCESS, payload: data });
      dispatch(getOrderDetails(id));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_STATUS_FAIL,
        payload: message,
      });
    }
  };

export const orderComplaint =
  ({ orderId, itemId, refundNum, productsReplacement }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_COMPLAINT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.put(
        `orders/${orderId}/complaint`,
        { itemId, refundNum, productsReplacement },
        config
      );

      dispatch({ type: ORDER_COMPLAINT_SUCCESS, payload: data });
      dispatch(getOrderDetails(orderId));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_COMPLAINT_FAIL,
        payload: message,
      });
    }
  };
