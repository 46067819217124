import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const Target = styled.img.attrs(({ offset, src, opacity }) => ({
  style: {
    left: `${offset.left}px`,
    top: `${offset.top}px`,
    opacity: opacity,
  },
  src: src,
}))`
  position: absolute;
  width: auto !important;
`;

const SlideshowContainer = styled.div`
  position: relative;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    pointer-events: none;
  }
  .image-gallery-slide-wrapper {
    overflow: hidden;
    // border: 1px solid ${({ theme }) => theme};
    border: 1px solid #ccc;
  }
  .image-gallery-slides,
  .image-gallery-slide {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-gallery-content {
    width: 100%;
  }
  .image-gallery-content.fullscreen {
    background: #e3e3e3 !important;
  }
  .image-gallery-content.fullscreen .image-gallery-slide-wrapper {
    border: none;
  }
  .image-gallery-content
    .image-gallery-slide-wrapper:hover
    .image-gallery-slide {
    opacity: 0;
  }
  @media screen and (max-width: 1200px) {
    .image-gallery-content
      .image-gallery-slide-wrapper:hover
      .image-gallery-slide {
      opacity: 1;
    }
  }
  .image-gallery-content.fullscreen
    .image-gallery-slide-wrapper:hover
    .image-gallery-slide {
    opacity: 1;
  }
  .image-gallery-content.fullscreen .zoom-target-elem {
    display: none !important;
  }
  @media screen and (max-width: 1200px) {
    .zoom-target-elem {
      display: none !important;
    }
  }
  .image-gallery-content.fullscreen .image-gallery-slide img {
    height: auto !important;
    max-height: calc(100vh - 150px) !important;
  }
  .image-gallery-slide-wrapper button {
    transition: all 0.3s;
    opacity: 0;
  }
  .image-gallery-slide-wrapper:hover button {
    transition: all 0.3s;
    opacity: 1;
  }
  .image-gallery-slide img {
    height: 400px;
    margin-top: 12px;
  }
  .image-gallery-thumbnails-wrapper button {
    // display: none;
  }
  // .image-gallery-content.fullscreen .image-gallery-thumbnails-wrapper {
  //   display: block;
  // }
  .image-gallery-thumbnail {
    height: 100px;
    border: 2px solid transparent;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus,
  .image-gallery-thumbnail:hover {
    border: 2px solid #a1a1a1;
  }
  .image-gallery-thumbnail img {
    height: 100%;
    object-fit: contain;
  }
  .image-gallery-svg {
    width: 25px !important;
  }
  .image-gallery-play-button {
    display: none !important;
  }
  .opacity-0 {
    opacity: 0;
  }
`;

const Slideshow = ({ sliderImages }) => {
  const { theme } = useSelector((state) => state.settings);

  const targetRef = useRef(null);
  const mountedRef = useRef(true);

  const [image, setImage] = useState("");
  const [container, setContainer] = useState("");
  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({});
  const [targetWrapper, setTargetWrapper] = useState("");

  const handleMouseEnter = useCallback(() => setOpacity(1), []);

  const handleMouseLeave = useCallback(() => setOpacity(0), []);

  const handleMouseMove = useCallback(
    (e) => {
      e.preventDefault();
      !opacity && setOpacity(1);
      if (
        document
          .querySelector(".image-gallery-content")
          .classList.contains("fullscreen")
      )
        return;

      if (!image || !container) return;

      const targetRect = targetRef.current?.getBoundingClientRect();
      const sourceRect = image?.getBoundingClientRect();
      const containerRect = container?.getBoundingClientRect();

      if (!targetRect || !sourceRect) return;

      const xRatio =
        (targetRect.width - containerRect.width) / sourceRect.width;
      const yRatio =
        (targetRect.height - containerRect.height) / sourceRect.height;

      const left = Math.max(
        Math.min(e.pageX - sourceRect.left, sourceRect.width),
        0
      );
      const top = Math.max(
        Math.min(e.pageY - sourceRect.top, sourceRect.height),
        0
      );

      setOffset({
        left: left * -xRatio,
        top: top * -yRatio,
      });
    },
    [image, container]
  );

  const onImageClick = useCallback(() => {
    if (
      document
        .querySelector(".image-gallery-content")
        .classList.contains("fullscreen")
    )
      return;

    document.querySelector(".image-gallery-fullscreen-button").click();
  }, []);

  useEffect(() => {
    if (isEmpty(sliderImages)) return;

    const img = document.querySelector(".image-gallery-center img");
    setImage(img);

    const container = document.querySelector(".image-gallery-slide-wrapper");
    setContainer(container);

    return () => {
      mountedRef.current = false;
    };
  }, [sliderImages]);

  useEffect(() => {
    if (container) {
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
      container.addEventListener("mousemove", handleMouseMove);

      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
        container.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [container, handleMouseEnter, handleMouseLeave, handleMouseMove]);

  useEffect(() => {
    if (isEmpty(sliderImages)) return;

    const images = document.querySelectorAll(".image-gallery-slide");
    images.forEach((img) => img.addEventListener("click", onImageClick));

    return () => {
      images.forEach((img) => img.removeEventListener("click", onImageClick));
    };
  }, [sliderImages, onImageClick]);

  useEffect(() => {
    const handleResize = () => {
      if (!mountedRef.current) return;

      const img = document.querySelector(".image-gallery-center img");
      if (img) {
        setImage(img);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const slideWrapper = document.querySelector(".image-gallery-slide-wrapper");
    if (slideWrapper) {
      setTargetWrapper(slideWrapper);
    }
  }, [image, offset]);

  return (
    <SlideshowContainer theme={theme}>
      {!isEmpty(sliderImages) && (
        <ImageGallery
          items={sliderImages}
          onSlide={() => {
            const img = document.querySelector(".image-gallery-center img");
            if (img) {
              setImage(img);
            }
          }}
        />
      )}
      {targetWrapper &&
        ReactDOM.createPortal(
          <Target
            className="zoom-target-elem"
            ref={targetRef}
            alt="target"
            opacity={opacity}
            offset={offset}
            src={image?.src}
          />,
          targetWrapper
        )}
    </SlideshowContainer>
  );
};

export default React.memo(Slideshow, (prevProps, nextProps) => {
  return prevProps.sliderImages === nextProps.sliderImages;
});

// ZOOM ON touchmove

// import React, { useCallback, useEffect, useRef, useState } from "react";
// import ReactDOM from "react-dom";
// import ImageGallery from "react-image-gallery";
// import styled from "styled-components";
// import "react-image-gallery/styles/css/image-gallery.css";
// import { useSelector } from "react-redux";
// import { isEmpty } from "lodash";

// const Target = styled.img.attrs(({ offset, src, opacity }) => ({
//   style: {
//     left: `${offset.left}px`,
//     top: `${offset.top}px`,
//     opacity: opacity,
//   },
//   src: src,
// }))`
//   position: absolute;
//   width: auto !important;
// `;

// const SlideshowContainer = styled.div`
//   position: relative;
//   overflow: hidden;
//   img {
//     display: block;
//     width: 100%;
//     pointer-events: none;
//   }
//   // ... (styles remain the same)
// `;

// const Slideshow = ({ sliderImages }) => {
//   const { theme } = useSelector((state) => state.settings);

//   const targetRef = useRef(null);
//   const mountedRef = useRef(true);

//   const [image, setImage] = useState(null);
//   const [container, setContainer] = useState(null);
//   const [opacity, setOpacity] = useState(0);
//   const [offset, setOffset] = useState({});
//   const [targetWrapper, setTargetWrapper] = useState(null);

//   const handleEnter = useCallback(() => setOpacity(1), []);
//   const handleLeave = useCallback(() => setOpacity(0), []);

//   const handleMove = useCallback(
//     (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       !opacity && setOpacity(1);
//       if (
//         document
//           .querySelector(".image-gallery-content")
//           .classList.contains("fullscreen")
//       )
//         return;

//       if (!image || !container) return;

//       const targetRect = targetRef.current?.getBoundingClientRect();
//       const sourceRect = image?.getBoundingClientRect();
//       const containerRect = container?.getBoundingClientRect();

//       if (!targetRect || !sourceRect) return;

//       const xRatio =
//         (targetRect.width - containerRect.width) / sourceRect.width;
//       const yRatio =
//         (targetRect.height - containerRect.height) / sourceRect.height;

//       const left =
//         e.pageX !== undefined
//           ? Math.max(Math.min(e.pageX - sourceRect.left, sourceRect.width), 0)
//           : Math.max(
//               Math.min(
//                 e.touches[0].clientX - sourceRect.left,
//                 sourceRect.width
//               ),
//               0
//             );
//       const top =
//         e.pageY !== undefined
//           ? Math.max(Math.min(e.pageY - sourceRect.top, sourceRect.height), 0)
//           : Math.max(
//               Math.min(
//                 e.touches[0].clientY - sourceRect.top,
//                 sourceRect.height
//               ),
//               0
//             );

//       setOffset({
//         left: left * -xRatio,
//         top: top * -yRatio,
//       });
//     },
//     [image, container, opacity]
//   );

//   const onImageClick = useCallback(() => {
//     if (
//       document
//         .querySelector(".image-gallery-content")
//         .classList.contains("fullscreen")
//     )
//       return;

//     document.querySelector(".image-gallery-fullscreen-button").click();
//   }, []);

//   useEffect(() => {
//     if (isEmpty(sliderImages)) return;

//     const img = document.querySelector(".image-gallery-center img");
//     setImage(img);

//     const container = document.querySelector(".image-gallery-slide-wrapper");
//     setContainer(container);

//     return () => {
//       mountedRef.current = false;
//     };
//   }, [sliderImages]);

//   useEffect(() => {
//     if (container) {
//       container.addEventListener("mouseenter", handleEnter);
//       container.addEventListener("mouseleave", handleLeave);
//       container.addEventListener("mousemove", handleMove);
//       container.addEventListener("touchstart", handleEnter);
//       container.addEventListener("touchend", handleLeave);
//       container.addEventListener("touchmove", handleMove);

//       return () => {
//         container.removeEventListener("mouseenter", handleEnter);
//         container.removeEventListener("mouseleave", handleLeave);
//         container.removeEventListener("mousemove", handleMove);
//         container.removeEventListener("touchstart", handleEnter);
//         container.removeEventListener("touchend", handleLeave);
//         container.removeEventListener("touchmove", handleMove);
//       };
//     }
//   }, [container, handleEnter, handleLeave, handleMove]);

//   useEffect(() => {
//     if (isEmpty(sliderImages)) return;

//     const images = document.querySelectorAll(".image-gallery-slide");
//     images.forEach((img) => img.addEventListener("click", onImageClick));

//     return () => {
//       images.forEach((img) => img.removeEventListener("click", onImageClick));
//     };
//   }, [sliderImages, onImageClick]);

//   useEffect(() => {
//     const handleResize = () => {
//       if (!mountedRef.current) return;

//       const img = document.querySelector(".image-gallery-center img");
//       if (img) {
//         setImage(img);
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     const slideWrapper = document.querySelector(".image-gallery-slide-wrapper");
//     if (slideWrapper) {
//       setTargetWrapper(slideWrapper);
//     }
//   }, [image, offset]);

//   return (
//     <SlideshowContainer theme={theme}>
//       {!isEmpty(sliderImages) && (
//         <ImageGallery
//           items={sliderImages}
//           onSlide={() => {
//             const img = document.querySelector(".image-gallery-center img");
//             if (img) {
//               setImage(img);
//             }
//           }}
//         />
//       )}
//       {targetWrapper &&
//         ReactDOM.createPortal(
//           <Target
//             className="zoom-target-elem"
//             ref={targetRef}
//             alt="target"
//             opacity={opacity}
//             offset={offset}
//             src={image?.src}
//           />,
//           targetWrapper
//         )}
//     </SlideshowContainer>
//   );
// };

// export default React.memo(Slideshow, (prevProps, nextProps) => {
//   return prevProps.sliderImages === nextProps.sliderImages;
// });
