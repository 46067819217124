import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "../common";
import { listOrders } from "../../../Redux/dashboard/Actions/OrderActions";

const LatestOrder = () => {
  const orderList = useSelector((state) => state.orderList);
  const { orders } = orderList;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listOrders({ pageNumber: 1 }));
  }, [dispatch]);

  return (
    <Card className="col-xl-12 col-lg-12 border-0" section={true}>
      <CardBody>
        <h4 className="card-title">Nove porudzbine</h4>

        <div className="table-responsive latest-orders">
          <table
            className={`table ${theme === "dark" ? "bg-dark text-white" : ""}`}
          >
            <thead>
              <tr>
                <th>Ime / Korisnik</th>
                <th>Email</th>
                <th>Iznos</th>
                <th>Status</th>
                <th>Datum</th>
                <th>Akcije</th>
              </tr>
            </thead>
            <tbody>
              {orders?.orders?.slice(0, 5).map((order) => (
                <tr
                  key={order._id}
                  className={`${theme === "dark" ? "dark-theme-hover" : ""}`}
                >
                  <td>
                    <b>{order.user?.name}</b>
                  </td>
                  <td>{order.user?.email}</td>
                  <td>{order.totalPrice} RSD</td>
                  <td>
                    <span
                      className={`badge ${
                        order.status === "Dostavljeno"
                          ? "btn-success"
                          : order.status === "Predato brzoj posti"
                          ? "btn-warning text-dark"
                          : "btn-dark"
                      }`}
                    >
                      {order.status}
                    </span>
                  </td>
                  <td>{moment(order.createdAt).calendar()}</td>
                  <td className="d-flex justify-content-end align-item-center">
                    <Link to={`/dashboard-order/${order._id}`} className="text-success">
                      <i className="fas fa-eye"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default LatestOrder;
