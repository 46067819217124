import React, { useState, useEffect, forwardRef, useRef } from "react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";
import styled from "styled-components";
import Picker from "emoji-picker-react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const ChatInput = forwardRef(
  ({ handleSendMsg, handleTyping, inputValue, onEditMessage }, ref) => {
    const [msg, setMsg] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const themeData = useSelector((state) => state.theme);
    const { theme } = themeData;

    const buttonRef = useRef();

    const readImage = (file, id) => {
      if (!file.type || !file.type.startsWith("image/")) {
        console.log("File is not an image.", file.type, file);
        return;
      }

      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        const imageObj = {
          src: event.target.result,
          original: event.target.result,
          fromSelf: true,
          id: id,
        };
        handleSendMsg(msg, imageObj);
      });
      reader.readAsDataURL(file);
    };

    const pickFiles = async (files) => {
      for (const [key, value] of Object.entries(files)) {
        const _id = uuidv4();
        const imageFile = value;
        imageFile.id = _id;

        try {
          readImage(imageFile, _id);
        } catch (error) {
          console.error("Error processing image:", error);
        }
      }
    };

    const handleEmojiPickerhideShow = () => {
      setShowEmojiPicker(!showEmojiPicker);
    };

    const hideEmojiPicker = () => {
      setShowEmojiPicker(false);
    };

    const handleEmojiClick = (event, emojiObject) => {
      let message = msg;
      message += emojiObject.emoji;
      setMsg(message);
    };

    const sendChat = (event) => {
      event.preventDefault();
      if (inputValue) {
        onEditMessage();
        return;
      } else if (msg.length > 0) {
        handleSendMsg(msg);
        setMsg("");
      }
    };

    useEffect(() => {
      window.addEventListener("click", hideEmojiPicker);

      return () => window.removeEventListener("click", hideEmojiPicker);
    }, []);

    useEffect(() => {
      setMsg(inputValue);
    }, [inputValue]);

    return (
      <Container className={theme === "dark" ? "bg-dark" : "bg-white"}>
        <div className="d-flex align-items-center ">
          <input
            ref={buttonRef}
            className={`form-control visually-hidden mt-3 ${
              theme === "dark" ? "dark-theme-section text-light" : ""
            }`}
            type="file"
            multiple
            onChange={(e) => pickFiles(e.target.files)}
          />
          <button
            className={`${theme === "light" ? "bg-gray" : ""} upload-image btn text-light p-0 d-flex align-items-center`}
            onClick={() => buttonRef.current.click()}
          >
            <i className="fas fa-solid fa-image"></i>
          </button>
        </div>
        <div className="button-container">
          <div className="emoji emoji-dark" onClick={(e) => e.stopPropagation()}>
            <BsEmojiSmileFill  onClick={handleEmojiPickerhideShow} />
            {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
          </div>
        </div>
        <form className={`input-container ${theme === "light" ? "border-1 border-gray" : ""}`} onSubmit={(event) => sendChat(event)}>
          <input
            className={`bg-transparent ${theme === "dark" ? "text-white" : "text-dark"}`}
            type="text"
            ref={ref}
            placeholder="ovde kucaj svoju poruku"
            onChange={(e) => {
              setMsg(e.target.value);
              handleTyping();
            }}
            value={msg}
          />
          <button type="submit">
            <IoMdSend />
          </button>
        </form>
      </Container>
    );
  }
);

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 5% 5% 90%;
  padding: 0 2rem;
  border-top: 1px solid gray;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    padding: 0 1rem;
    gap: 1rem;
  }
  @media screen and (max-width: 720px){
    grid-template-columns: 10% 10% 80%;
  }
  .button-container {
    display: flex;
    align-items: center;
    color: white;
    gap: 1rem;
    .emoji-dark {
      svg {
        background-color: gray;
        border-radius: 50%;
      }
    }
    .emoji {
      position: relative;
      svg {
        font-size: 1.5rem;
        color: #ffff00c8;
        cursor: pointer;
      }
      .emoji-picker-react {
        position: absolute;
        top: -350px;
        background-color: #212529;
        box-shadow: 0 5px 10px #212529;
        border-color: #212529;
        .emoji-scroll-wrapper::-webkit-scrollbar {
          background-color: #272431;
          width: 5px;
          &-thumb {
            background-color: #272431;
          }
        }
        .emoji-categories {
          button {
            filter: contrast(0);
          }
        }
        .emoji-search {
          background-color: #212121;
          border-color: #3c4248;
          color: white;
        }
        .emoji-group:before {
          background-color: #3c4248;
        }
      }
    }
  }
  .input-container {
    width: 100%;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #ffffff34;
    input {
      width: 90%;
      height: 60%;
      background-color: transparent;
      color: white;
      border: none;
      padding-left: 1rem;
      font-size: 1.2rem;

      &::selection {
        background-color: #4fa607;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      padding: 0.3rem 2rem;
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #4fa607;
      border: none;
      @media screen and (min-width: 720px) and (max-width: 1080px) {
        padding: 0.3rem 1rem;
        svg {
          font-size: 1rem;
        }
      }
      svg {
        font-size: 2rem;
        color: white;
      }
    }
  }

  .upload-image {
    font-size: 22px;
  }
  .upload-image:hover {
    cursor: pointer;
  }
`;

export default ChatInput;
