import React from 'react';
import Avatar from 'react-avatar-edit';

export default function AvatarComponent({ setImage, src }) {
  const onClose = () => {
    setImage(null);
  };

  const onCrop = (preview) => {
    setImage(preview);
  };

  return (
    <div className='user-avatar'>
      <Avatar
        src={src || ""}
        width={200}
        height={200}
        onCrop={onCrop}
        onClose={onClose}
      />
    </div>
  );
};