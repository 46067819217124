import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import MainSellers from "../../components/dashboard/sellers/MainSellers";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const SellersScreen = () => {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <MainSellers />
      </PageWrapper>
    </>
  );
};

export default SellersScreen;
