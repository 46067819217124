import React from "react";
import { Card, CardBody } from "../common";
import { Section } from "../common";
import CreateBrand from "./CreateBrand";
import BrandsTable from "./BrandsTable";
import { useSelector } from "react-redux";
import Message from "../loading-error/Error";
import Loading from "../loading-error/Loading";

export default function MainCategories() {
  const brandsData = useSelector((state) => state.brandList);
  const { loading, error } = brandsData;

  const brandsAddData = useSelector((state) => state.brandCreate);
  const { loading: loadingAdd, error: errorAdd } = brandsAddData;

  const brandsEditData = useSelector((state) => state.brandEdit);
  const { loading: loadingEdit, error: errorEdit } = brandsEditData;

  const brandsDeleteData = useSelector((state) => state.brandDelete);
  const { loading: loadingDelete, errorDelete } = brandsDeleteData;

  return (
    <Section className="position-relative">
      <div className="content-header">
        <h2 className="content-title">Brendovi</h2>
      </div>

      <Card section className="shadow-sm">
        <CardBody>
          <CreateBrand />
          {error || errorAdd || errorEdit || errorDelete ? (
            <Message variant="alert-danger">
              {error || errorAdd || errorEdit || errorDelete}
            </Message>
          ) : (
            <BrandsTable />
          )}
        </CardBody>
      </Card>
      {(loading || loadingAdd || loadingEdit || loadingDelete) && (
        <Loading position="position-absolute" width="100%" height="100%" />
      )}
    </Section>
  );
}
