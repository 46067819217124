import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function Breadcrumb({ path }) {
  const [separatedPath, setSeparatedPath] = useState([]);

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const concatLink = (index) => {
    let i = 0;
    let link;
    while (i <= index) {
      link = i === 0 ? separatedPath[i] : `${link}/${separatedPath[i]}`;
      i++;
    }

    return link;
  };

  useEffect(() => {
    if (!path) return;

    let pathArray = path.split("/");
    setSeparatedPath(pathArray);
  }, [path]);

  return (
    <>
      {separatedPath && separatedPath.length > 1 && (
        <nav className="d-flex justify-content-center " aria-label="breadcrumb">
          <ol className="breadcrumb">
            {separatedPath.map((link, i) => (
              <li
                className={`breadcrumb-item ${
                  separatedPath.length - 1 === i ? "active" : ""
                }`}
                key={i}
                {...(separatedPath.length - 1 === i
                  ? { "aria-current": "page" }
                  : {})}
              >
                <a
                  className={`text-capitalize ${
                    i < separatedPath.length - 1 ? `text-${theme}-500` : ""
                  }`}
                  href={`/shop/${concatLink(i)}`}
                >
                  {link.replace(/_/g, " ")}
                </a>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
}
