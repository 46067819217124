import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Pagination({
  page,
  pages,
  keyword,
  sort,
  status,
  toShow,
  submitHandler,
}) {
  const [linkArray, setLinkArray] = useState([]);

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const prevClick = (event, prevPage) => {
    event.preventDefault();
    if (page === 1) return;
    submitHandler({ pageNumber: prevPage, keyword, status, toShow, sort });
  };

  const nextClick = (event, nextPage) => {
    event.preventDefault();
    if (page === pages) return;
    submitHandler({ pageNumber: nextPage, keyword, status, toShow, sort });
  };

  const pageClick = (event, nextPage) => {
    event.preventDefault();
    submitHandler({ pageNumber: nextPage, keyword, status, toShow, sort });
  };

  useEffect(() => {
    const generatePageLinks = () => {
      const maxLinks = 5;
      let start = Math.max(1, page - 2); // Start at 2 pages before the current one
      let end = Math.min(pages, page + maxLinks - 1); // Show up to 5 pages after

      // Ensure that at least 5 pages are shown (if possible)
      if (end - start < maxLinks - 1) {
        if (start === 1) {
          end = Math.min(pages, start + maxLinks - 1);
        } else {
          start = Math.max(1, end - maxLinks + 1);
        }
      }

      const newLinkArray = [];
      for (let i = start; i <= end; i++) {
        newLinkArray.push(i);
      }
      setLinkArray(newLinkArray);
    };

    generatePageLinks();
  }, [page, pages, keyword]);

  return (
    <nav className="float-end mt-4" aria-label="Page navigation">
      <ul className="pagination">
        <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
          <Link
            className={`page-link ${theme === "dark" ? "bg-dark" : ""}`}
            to="#"
            onClick={(e) => prevClick(e, page - 1)}
          >
            Previous
          </Link>
        </li>

        {linkArray.map((n, index) => (
          <li className={`page-item ${n === page ? "active" : ""}`} key={index}>
            <Link
              className={`page-link ${theme === "dark" ? "bg-dark" : ""}`}
              to="#"
              onClick={(e) => pageClick(e, n)}
            >
              {n}
            </Link>
          </li>
        ))}

        <li className={`page-item ${page === pages ? "disabled" : ""}`}>
          <Link
            className={`page-link ${theme === "dark" ? "bg-dark" : ""}`}
            to="#"
            onClick={(e) => nextClick(e, page + 1)}
          >
            Next
          </Link>
        </li>
      </ul>
    </nav>
  );
}
