import React from "react";
import { useSelector } from "react-redux";

export default function CardHeader({ children, className, section }) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className={`card-header position-relative ${className} ${theme === "dark" ? section ? "dark-theme-section" : "bg-dark" : "bg-white"}`}>
      {children}
    </div>
  );
}
