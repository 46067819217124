import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_EDIT_REQUEST,
  CATEGORY_EDIT_SUCCESS,
  CATEGORY_EDIT_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CHECK_AVAILABILITY_REQUEST,
  CHECK_AVAILABILITY_SUCCESS,
  CHECK_AVAILABILITY_FAIL,
} from "../Constants/CategoryConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET CATEGORIES
export const listCategories =
  ({ filtered }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      if (!filtered) {
        const { data } = await _axios.get(`categories/all`, config);
        dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
      } else {
        const { data } = await _axios.get(`categories/client/all`, config);
        dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_LIST_FAIL,
        payload: message,
      });
    }
  };

// CREATE CATEGORY
export const checkAvailability =
  (title) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CHECK_AVAILABILITY_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `categories/check-availability`,
        { title },
        config
      );

      dispatch({ type: CHECK_AVAILABILITY_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CHECK_AVAILABILITY_FAIL,
        payload: message,
      });
    }
  };

// CREATE CATEGORY
export const createCategory =
  ({ title, path, description, images, parentCategoryPath }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `categories/`,
        { title, path, images, description, parentCategoryPath },
        config
      );

      dispatch({ type: CATEGORY_CREATE_SUCCESS, payload: data });
      dispatch(listCategories({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT CATEGORY
export const editCategory =
  ({ id, oldPath, oldTitle, title, description, images, imagesToDelete }) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: CATEGORY_EDIT_REQUEST });
      const { data } = await _axios.put(
        `categories/${id}`,
        { oldPath, oldTitle, title, description, images, imagesToDelete },
        config
      );
      dispatch({ type: CATEGORY_EDIT_SUCCESS, payload: data });
      dispatch(listCategories({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_EDIT_FAIL,
        payload: message,
      });
    }
  };

// UPDATE CATEGORY TREE
export const updateCategoryTree =
  ({
    tree,
    beforeNodeMovePath,
    afterNodeMovePath,
    imagesToRemove,
    categoryToRemove,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_EDIT_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(
        `categories/update-tree`,
        {
          tree,
          beforeNodeMovePath,
          afterNodeMovePath,
          imagesToRemove,
          categoryToRemove,
        },
        config
      );
      dispatch(listCategories({ pageNumber: 1, keyword: "" }));
      dispatch({ type: CATEGORY_EDIT_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_EDIT_FAIL,
        payload: message,
      });
    }
  };

// UPDATE CATEGORY
export const updateCategory = (category) => async (dispatch, getState) => {
  const { _id, title, description, images, imageFiles, imagesToDelete } =
    category;

  try {
    dispatch({ type: CATEGORY_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    const formData = new FormData();

    if (imageFiles) {
      for (let i = 0; i < imageFiles.length; i++) {
        formData.append("file", imageFiles[i]);
      }
    }

    console.log("images:", images);
    console.log("imagesToDelete:", imagesToDelete);

    const _images = JSON.stringify(images);
    const _imagesToDelete = JSON.stringify(imagesToDelete);

    formData.append("title", title);
    formData.append("description", description);
    formData.append("images", _images);
    formData.append("imagesToDelete", _imagesToDelete);

    const { data } = await _axios.put(`categories/${_id}`, formData, config);

    dispatch({ type: CATEGORY_UPDATE_SUCCESS, payload: data });
    dispatch({ type: CATEGORY_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORY_UPDATE_FAIL,
      payload: message,
    });
  }
};

// DELETE CATEGORY
export const deleteCategory = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`categories/${id}`, config);

    dispatch({ type: CATEGORY_DELETE_SUCCESS });
    dispatch(listCategories({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload: message,
    });
  }
};
