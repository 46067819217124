import React from "react";
import Sidebar from "./../../components/dashboard/Sidebar";
import Header from "./../../components/dashboard/Header";
import OrderDetailmain from "../../components/dashboard/orders/OrderDetailmain";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const OrderDetailScreen = ({ match }) => {
  const orderId = match.params.id;
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <OrderDetailmain orderId={orderId} />
      </PageWrapper>
    </>
  );
};

export default OrderDetailScreen;
