import React from "react";
import Header from "../../components/client/Header";
import ShopImage from "../../components/client/common/ShopImage";
import ShopMain from "../../components/client/shop-components/ShopMain";
import Footer from "../../components/client/Footer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ShopBrandScreen() {
  let { brand } = useParams();

  window.scrollTo(0, 0);

  return (
    <>
      <Header />
      <ShopImage breadcrumb={`Brend: ${brand}`} />
      <ShopMain byBrand={brand} />
      <Footer />
    </>
  );
}
