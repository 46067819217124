import {
  MAIL_SEND_REQUEST,
  MAIL_SEND_SUCCESS,
  MAIL_SEND_FAIL,
} from "../Constants/PostConstants";

// SEND MAIL
export const sendMailReducer = (state = {}, action) => {
  switch (action.type) {
    case MAIL_SEND_REQUEST:
      return { loading: true };
    case MAIL_SEND_SUCCESS:
      return { loading: false, sendMailInfo: action.payload };
    case MAIL_SEND_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
