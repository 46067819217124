import React, { useEffect } from "react";
import Header from "../../components/client/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removefromcart,
} from "../../Redux/client/Actions/cartActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/client/ui/Button";
import Select from "../../components/client/ui/form/Select";

const CartScreen = ({ match, location }) => {
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const productId = match.params.id;
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const history = useHistory();

  const total = cartItems
    .reduce((a, i) => a + i.qty * (i.discountPrice || i.price), 0)
    .toFixed(2);

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  const checkOutHandler = () => {
    // history.push("/login?redirect=shipping");
    history.push("/shipping");
  };

  const removeFromCartHandle = (id) => {
    dispatch(removefromcart(id));
  };

  useEffect(() => {
    console.log("CART:::", cart);
  }, [cart]);

  return (
    <>
      <Header />
      {/* Cart */}
      <div className="container">
        {cartItems.length === 0 ? (
          <div className={`alert bg-${theme}-100 text-center mt-3`}>
            Vasa korpa je prazna
            <Link
              className={`btn bg-${theme} text-white mx-5 px-5 py-3`}
              to="#"
              style={{
                fontSize: "12px",
              }}
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              NAZAD
            </Link>
          </div>
        ) : (
          <>
            <div className={`alert bg-${theme}-100 text-center mt-3`}>
              Ukupno artikala u korpi
              <Link className="text-success mx-2" to="/cart">
                ({cartItems.length})
              </Link>
            </div>
            {/* cartiterm */}
            {cartItems.map((item, index) => (
              <div className="cart-iterm site-bg row" key={index}>
                <div>
                  <button
                    type="button"
                    className={`btn-close shadow-focus-${theme}-100`}
                    aria-label="Close"
                    onClick={() => removeFromCartHandle(item.product)}
                  ></button>
                </div>
                <div className="cart-image col-md-3">
                  <img src={item.images[0].src} alt={item.name} />
                </div>
                <div className="cart-text col-md-5 d-flex align-items-center">
                  <Link to={`/products/${item.product}`}>
                    <h4>{item.name}</h4>
                  </Link>
                </div>
                <div className="cart-qty col-md-2 col-sm-5 mt-md-5 mt-3 mt-md-0 d-flex flex-column justify-content-center">
                  <h6>KOLICINA</h6>
                  <Select
                    value={item.qty}
                    onChange={(e) =>
                      dispatch(
                        addToCart({
                          productId: item.product,
                          qty: Number(e.target.value),
                        })
                      )
                    }
                  >
                    {[...Array(item.countInStock).keys()].map((x) => (
                      <option key={x + 1} value={x + 1}>
                        {x + 1}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="cart-price mt-3 mt-md-0 col-md-2 align-items-sm-end align-items-start  d-flex flex-column justify-content-center col-sm-7">
                  <h6>CENA</h6>
                  <h4>{item.price} RSD</h4>
                </div>
              </div>
            ))}

            {/* End of cart iterms */}
            <div className="total">
              <span className="sub">ukupno:</span>
              <span className="total-price">{total} RSD</span>
            </div>
            <hr />
            <div className="cart-buttons d-flex gap-5 justify-content-between align-items-center">
              <Button
                variant="outline"
                className="w-25 py-2 flex-grow-0 text-uppercase"
                onClick={(e) => {
                  e.stopPropagation();
                  history.goBack();
                }}
              >
                Nazad
              </Button>

              {total > 0 && (
                <Button
                  className="text-uppercase flex-grow-0 py-2 w-25"
                  onClick={checkOutHandler}
                >
                  Dalje
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CartScreen;
