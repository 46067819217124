import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import MainProducts from "../../components/dashboard/common/MainProducts";
import PageWrapper from "../../components/dashboard/common/PageWapper"

const ProductScreen = () => {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <MainProducts fromAdminDashboard={true} />
      </PageWrapper>
    </>
  );
};

export default ProductScreen;
