import {
  COMMISSION_LIST_REQUEST,
  COMMISSION_LIST_SUCCESS,
  COMMISSION_LIST_FAIL,
  COMMISSION_CREATE_REQUEST,
  COMMISSION_CREATE_SUCCESS,
  COMMISSION_CREATE_FAIL,
  COMMISSION_CREATE_RESET,
  COMMISSION_DELETE_REQUEST,
  COMMISSION_DELETE_SUCCESS,
  COMMISSION_DELETE_FAIL,
} from "../Constants/CommissionConstants";

// COMMISSION LIST
export const commissionListReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMISSION_LIST_REQUEST:
      return { loading: true };
    case COMMISSION_LIST_SUCCESS:
      return { loading: false, success: true, commissions: action.payload };
    case COMMISSION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE COMMISSION
export const commissionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMISSION_CREATE_REQUEST:
      return { loading: true };
    case COMMISSION_CREATE_SUCCESS:
      return { loading: false, success: true, seller: action.payload };
    case COMMISSION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case COMMISSION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// DELETE COMMISSION
export const commissionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMISSION_DELETE_REQUEST:
      return { loading: true };
    case COMMISSION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case COMMISSION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};