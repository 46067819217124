import { useSelector } from "react-redux";

export default function Button({
  className,
  onClick,
  type,
  variant,
  disabled,
  children,
}) {

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  return (
    <>
      {variant === "outline" ? (
        <button
          className={`btn shadow-none text-white text-${theme}-500 text-transition-hover-white rounded-0 border-${theme}-500 bg-transition-hover-${theme}-500 ${className}`}
          type={type || ""}
          disabled={disabled}
          onClick={onClick && onClick}
        >
          {children}
        </button>
      ) : (
        <button
          className={`btn shadow-none text-white bg-${theme}-500 text-transition-hover-white rounded-0 bg-transition-hover-${theme}-600 ${className}`}
          type={type || ""}
          disabled={disabled}
          onClick={onClick && onClick}
        >
          {children}
        </button>
      )}
    </>
  );
}
