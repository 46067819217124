import {
  MATERIAL_LIST_REQUEST,
  MATERIAL_LIST_SUCCESS,
  MATERIAL_LIST_FAIL,
  MATERIAL_CREATE_REQUEST,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_CREATE_FAIL,
  MATERIAL_EDIT_REQUEST,
  MATERIAL_EDIT_SUCCESS,
  MATERIAL_EDIT_FAIL,
  MATERIAL_DELETE_REQUEST,
  MATERIAL_DELETE_SUCCESS,
  MATERIAL_DELETE_FAIL,
} from "../Constants/MaterialConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET MATERIALS
export const listMaterials =
  () =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MATERIAL_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `materials/all`,
        config
      );

      dispatch({ type: MATERIAL_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MATERIAL_LIST_FAIL,
        payload: message,
      });
    }
  };

// CREATE MATERIAL
export const createMaterial =
  ({ title, image }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MATERIAL_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(`materials/`, { title, image }, config);

      dispatch({ type: MATERIAL_CREATE_SUCCESS, payload: data });
      dispatch(listMaterials());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MATERIAL_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT MATERIAL
export const editMaterial =
  ({ id, title, image, imageToDelete }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: MATERIAL_EDIT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.put(
        `materials/${id}`,
        { title, image, imageToDelete },
        config
      );
      dispatch({ type: MATERIAL_EDIT_SUCCESS, payload: data });
      dispatch(listMaterials());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: MATERIAL_EDIT_FAIL,
        payload: message,
      });
    }
  };

// DELETE MATERIAL
export const deleteMaterial = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MATERIAL_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`materials/${id}`, config);

    dispatch({ type: MATERIAL_DELETE_SUCCESS });
    dispatch(listMaterials());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: MATERIAL_DELETE_FAIL,
      payload: message,
    });
  }
};
