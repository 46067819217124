import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../loading-error/Error";
import Toast from "./../loading-error/Toast";
import { toast } from "react-toastify";
import { updateUserProfile } from "../../../Redux/client/Actions/UserActions";
import Input from "../ui/form/Input";
import Button from "../ui/Button";

const ProfileTabs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
  };

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, [dispatch, user]);

  const submitHandler = (e) => {
    e.preventDefault();
    // Password match
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Lozinke se ne poklapaju", Toastobjects);
      }
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }));
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Profil je Izmenjen", Toastobjects);
      }
    }
  };
  return (
    <>
      <Toast />
      {error && <Message variant="alert-danger">{error}</Message>}
      <form
        style={{ minHeight: "358.5px" }}
        className="form-container d-flex flex-column justify-content-between"
        onSubmit={submitHandler}
      >
        <div className="form-inputs">
          <Input
            type="text"
            label="Korisnicko Ime"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Input
            type="email"
            label="E-mail Addresa"
            value={email}
            required
            disabled
            onChange={(e) => setEmail(e.target.value)}
          />

          <Input
            type="password"
            label="Nova Lozinka"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Input
            type="password"
            label="Potvrdite Lozinku"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <Button className="text-white p-3" type="submit">
          Izmenite Profil
        </Button>
      </form>
    </>
  );
};

export default ProfileTabs;
