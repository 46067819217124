import React from "react";
import Sidebar from "./../../components/dashboard/Sidebar";
import Header from "./../../components/dashboard/Header";
import UserComponent from "../../components/dashboard/users/Users";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const UsersScreen = () => {

  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <UserComponent />
      </PageWrapper>
    </>
  );
};

export default UsersScreen;
