import Footer from "../../components/client/Footer";
import Header from "../../components/client/Header";
import ContactMain from "../../components/client/contact-components/ContactMain";

export default function ContactScreen() {
  return (
    <>
      <Header />
      <ContactMain />
      <Footer />
    </>
  );
}
