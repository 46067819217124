import React, { useEffect } from "react";
import Toast from "../loading-error/Toast";
// import { toast } from "react-toastify";
// import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryRequestList,
  categoryRequestAprove,
  categoryRequestReject,
} from "../../../Redux/dashboard/Actions/CategoryRequestActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import RequestsTable from "../common/RequestsTable";
import { io } from "socket.io-client";
import { Section, Card, CardBody } from "../common";

// const ToastObjects = {
//   pauseOnFocusLoss: false,
//   draggable: false,
//   pauseOnHover: false,
//   autoClose: 2000,
// };
const CategoryRequest = () => {
  const socket = io.connect(`${process.env.REACT_APP_API_URL}`);

  const categoryRequestData = useSelector((state) => state.categoryRequestList);
  const { loading, error, requests } = categoryRequestData;

  // const themeData = useSelector((state) => state.theme);
  // const { theme } = themeData;

  const dispatch = useDispatch();

  const aproveHandler = (id, details) => {
    dispatch(categoryRequestAprove(id, details));
    socket.emit("procesed_request");
  };

  const rejectHandler = (id, details) => {
    dispatch(categoryRequestReject(id, details));
    socket.emit("procesed_request");
  };

  useEffect(() => {
    dispatch(categoryRequestList({ pageNumber: 1, keyword: "" }));
  }, [dispatch]);

  useEffect(() => {
    socket.emit("requests");

    return () => {
      socket.emit("disconect");
    };
  }, []);

  return (
    <>
      <Toast />
      <Section>
        <Card className="mb-4 shadow-sm">
          <CardBody>
            <div className="table-responsive">
              <RequestsTable
                requests={requests?.requests}
                aproveHandler={aproveHandler}
                rejectHandler={rejectHandler}
              />
            </div>
            {error && <Message variant="alert-danger">{error}</Message>}
            {loading && (
              <Loading
                position="position-absolute"
                width="100%"
                height="100%"
              />
            )}
          </CardBody>
        </Card>
      </Section>
    </>
  );
};

export default CategoryRequest;
