// App.js
import React, { useEffect, useMemo, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import "./sortable.css";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import Input from "../../ui/form/Input";

export default function TableGenerator({ tableValue, onTableChange }) {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const handleSelectCol = (cols) => {
    const newGrid = Array.from({ length: cols });
    setColumns(newGrid);
  };

  const handleAddRow = (e, rows) => {
    e.preventDefault();

    const newTableData = [...tableData];
    const _id = uuidv4();
    newTableData[rows] = { id: _id, index: rows, columns: {} };
    columns.forEach((_, i) => {
      newTableData[rows].columns[i] = "";
    });
    setTableData(newTableData);
    onTableChange(newTableData);
  };

  const handleRemoveRow = (id) => {
    const newTableData = tableData.filter((row) => row.id !== id);

    // Update indexes
    newTableData.forEach((row, index) => (row.index = index));
    onTableChange(newTableData);
    setTableData(newTableData);
  };

  const handleTableDataChange = (row, col, value) => {
    const newTableData = [...tableData];
    if (!newTableData[row]) newTableData[row] = {};
    newTableData[row].columns[col] = value;
    setTableData(newTableData);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    const newTableData = arrayMoveImmutable(tableData, oldIndex, newIndex);

    // Update indexes
    newTableData.forEach((row, index) => (row.index = index));
    setTableData(newTableData);
  };

  useEffect(() => {
    const sortedTableData = !isEmpty(tableValue)
      ? [...tableValue].sort((a, b) => a.index - b.index)
      : [];
    setTableData(sortedTableData);
  }, [tableValue]);

  const renderTable = useMemo(() => {
    return (
      <div className="container mb-5">
        <SortableList
          onSortEnd={onSortEnd}
          className="sortable-item-list"
          draggedItemClassName="dragged"
        >
          {tableData?.map((rowValue, rowIndex) => (
            <SortableItem key={rowValue.id}>
              <div>
                <div
                  style={{ paddingRight: "30px" }}
                  className="d-flex flex-grow-1 gap-1 position-relative"
                >
                  {Object.values(rowValue.columns).map((colValue, colIndex) => (
                    <div className="flex-grow-1" key={colIndex}>
                      <Input
                        type="text"
                        value={colValue}
                        onChange={(e) =>
                          handleTableDataChange(
                            rowIndex,
                            colIndex,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  ))}
                  <div
                    className="remove-row pointer position-absolute right-1 top-2 text-danger"
                    onClick={() => handleRemoveRow(rowValue.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </SortableItem>
          ))}
        </SortableList>
      </div>
    );
  }, [tableData]);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center px-2 mb-3 align-items-end">
        <div>
          <Input
            type="number"
            label="Broj kolona:"
            min="1"
            // disabled={tableData.length !== 0}
            onChange={(e) => handleSelectCol(e.target.value)}
          />
        </div>
        <div>
          <button
            className="btn btn-primary"
            disabled={columns.length === 0}
            onClick={(e) => handleAddRow(e, tableData.length)}
          >
            DODAJ
          </button>
        </div>
      </div>
      {renderTable}
    </div>
  );
}
