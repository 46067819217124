import {
  CLOUDINARY_USAGE_REQUEST,
  CLOUDINARY_USAGE_SUCCESS,
  CLOUDINARY_USAGE_FAIL,
} from "../Constants/CloudinaryConstants";

// CLOUDINARY USAGE
export const cloudinaryUsageReducer = (state = {}, action) => {
  switch (action.type) {
    case CLOUDINARY_USAGE_REQUEST:
      return { loading: true };
    case CLOUDINARY_USAGE_SUCCESS:
      return { loading: false, success: true, ...action.payload };
    case CLOUDINARY_USAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};