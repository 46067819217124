import {
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_LIST_FAIL,
  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_CREATE_FAIL,
  BRAND_EDIT_REQUEST,
  BRAND_EDIT_SUCCESS,
  BRAND_EDIT_FAIL,
  BRAND_DELETE_REQUEST,
  BRAND_DELETE_SUCCESS,
  BRAND_DELETE_FAIL,
} from "../Constants/BrandConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET BRANDS
export const listBrands =
  ({ pageNumber, keyword }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `brands/all?pageNumber=${pageNumber}&keyword=${keyword}`,
        config
      );

      dispatch({ type: BRAND_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: BRAND_LIST_FAIL,
        payload: message,
      });
    }
  };

// CREATE BRAND
export const createBrand =
  ({ title, image }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.post(`brands/`, { title, image }, config);

      dispatch({ type: BRAND_CREATE_SUCCESS, payload: data });
      dispatch(listBrands({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: BRAND_CREATE_FAIL,
        payload: message,
      });
    }
  };

// EDIT BRAND
export const editBrand =
  ({ id, title, image, imageToDelete }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_EDIT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const { data } = await _axios.put(
        `brands/${id}`,
        { title, image, imageToDelete },
        config
      );
      dispatch({ type: BRAND_EDIT_SUCCESS, payload: data });
      dispatch(listBrands({ pageNumber: 1, keyword: "" }));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: BRAND_EDIT_FAIL,
        payload: message,
      });
    }
  };

// DELETE BRAND
export const deleteBrand = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BRAND_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.delete(`brands/${id}`, config);

    dispatch({ type: BRAND_DELETE_SUCCESS });
    dispatch(listBrands({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: BRAND_DELETE_FAIL,
      payload: message,
    });
  }
};
