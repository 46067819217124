import { useSelector } from "react-redux";

export default function Input({
  id,
  className,
  inputClass,
  type,
  label,
  min,
  required,
  disabled,
  value,
  sufix,
  placeholder,
  onChange,
}) {
  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  return (
    <div className={`${className}`}>
      <>
        {label && (
          <label htmlFor={id} className="form-label">
            {label}
          </label>
        )}
        <div className="input-with-suffix">
          <input
            id={id}
            type={type}
            required={required}
            disabled={disabled}
            placeholder={placeholder || "Kucaj ovde"}
            min={min || ""}
            className={`${
              inputClass ||
              `form-control ${
                theme === "dark" ? "dark-theme-section text-light" : ""
              }`
            }`}
            value={value}
            onChange={onChange}
          />
          {sufix && (
            <span
              className={`input-suffix ${
                theme === "dark" ? "text-light" : "text-dark"
              }`}
            >
              {sufix}
            </span>
          )}
        </div>
      </>
    </div>
  );
}
