import React, { useEffect } from "react";
import Header from "../../components/client/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Button from "../../components/client/ui/Button";
import {
  favoriteList,
  likeProduct,
} from "../../Redux/client/Actions/ProductActions";
import Loading from "../../components/client/loading-error/Loading";
import Message from "../../components/client/loading-error/Error";

export default function FavoriteScreen({ location }) {
  window.scrollTo(0, 0);

  const dispatch = useDispatch();

  const favorite = useSelector((state) => state.favorite);
  const { loading, error, products } = favorite;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const history = useHistory();

  const removeFromFavoriteHandle = (productId) => {
    dispatch(likeProduct({ productId }));
  };

  useEffect(() => {
    dispatch(favoriteList());
  }, []);

  return (
    <>
      <Header />
      {/* Cart */}
      <div className="container pt-3">
        {products?.length === 0 ? (
          <div className={`alert bg-${theme}-100 text-center mt-3`}>
            Lista zelja je prazna.
            <Link
              className={`btn bg-${theme} text-white mx-5 px-5 py-3`}
              to="#"
              style={{
                fontSize: "12px",
              }}
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              NAZAD
            </Link>
          </div>
        ) : (
          <>
            <Button variant="outline" onClick={() => history.goBack()}>
              <i className="fas fa-arrow-left"></i> Nazad
            </Button>
            <div className={`alert bg-${theme}-100 text-center mt-3`}>
              Ukupno artikala u listi zelja.
              <Link className="text-success mx-2" to="/cart">
                ({products?.length})
              </Link>
            </div>
            {/* cartiterm */}
            {loading ? (
              <Loading />
            ) : error ? (
              <Message>{error}</Message>
            ) : (
              products?.map((product, index) => (
                <div className="cart-iterm site-bg row" key={index}>
                  <div>
                    <button
                      type="button"
                      className={`btn-close shadow-focus-${theme}-100`}
                      aria-label="Close"
                      onClick={() => removeFromFavoriteHandle(product._id)}
                    ></button>
                  </div>
                  <Link
                    className="cart-image col-md-3"
                    to={`/products/${product._id}`}
                  >
                    <img src={product.imageFiles[0].src} alt={product.name} />
                  </Link>
                  <div className="cart-text col-md-5 d-flex align-items-center">
                    <Link to={`/products/${product._id}`}>
                      <h4>{product.name}</h4>
                    </Link>
                  </div>
                  <div className="cart-price mt-3 mt-md-0 col-md-2 align-items-sm-end align-items-start  d-flex flex-column justify-content-center col-sm-7">
                    <h6>CENA</h6>
                    <h4>{product.price} RSD</h4>
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </div>
    </>
  );
}
