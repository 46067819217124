export const SELLER_LIST_REQUEST = "SELLER_LIST_REQUEST";
export const SELLER_LIST_SUCCESS = "SELLER_LIST_SUCCESS";
export const SELLER_LIST_FAIL = "SELLER_LIST_FAIL";

export const SELLER_COMMISSION_REQUEST = "SELLER_COMMISSION_REQUEST";
export const SELLER_COMMISSION_SUCCESS = "SELLER_COMMISSION_SUCCESS";
export const SELLER_COMMISSION_FAIL = "SELLER_COMMISSION_FAIL";

export const SELLER_CREATE_REQUEST = "SELLER_CREATE_REQUEST";
export const SELLER_CREATE_SUCCESS = "SELLER_CREATE_SUCCESS";
export const SELLER_CREATE_FAIL = "SELLER_CREATE_FAIL";
export const SELLER_CREATE_RESET = "SELLER_CREATE_RESET";

export const SELLER_EDIT_REQUEST = "SELLER_EDIT_REQUEST";
export const SELLER_EDIT_SUCCESS = "SELLER_EDIT_SUCCESS";
export const SELLER_EDIT_FAIL = "SELLER_EDIT_FAIL";
export const SELLER_EDIT_RESET = "SELLER_UPDATE_RESET";

export const SELLER_UPDATE_REQUEST = "SELLER_UPDATE_REQUEST";
export const SELLER_UPDATE_SUCCESS = "SELLER_UPDATE_SUCCESS";
export const SELLER_UPDATE_FAIL = "SELLER_EDIT_FAIL";
export const SELLER_UPDATE_RESET = "SELLER_UPDATE_RESET";

export const SELLER_DELETE_REQUEST = "SELLER_DELETE_REQUEST";
export const SELLER_DELETE_SUCCESS = "SELLER_DELETE_SUCCESS";
export const SELLER_DELETE_FAIL = "SELLER_DELETE_FAIL";