import React from "react";
import ProductCard from "../shop-components/ProductCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isEmpty } from "lodash";

export default function ProductCarousel({ products, title, similarProducts }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {products && !isEmpty(products) && (
        <div className="site-bg py-1">
          <div className="container px-0 site-bg my-5 pb-5">
            <h3 className="text-center text-gray">{title}</h3>
            {products && (
              <Carousel
                infinite={true}
                autoPlay={true}
                responsive={responsive}
              >
                {products.map((product, index) => (
                  <div className="shop p-2" key={index}>
                    <ProductCard product={product} fromCarousel={true} similarProducts={similarProducts} />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      )}
    </>
  );
}
