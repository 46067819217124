import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteMaterial } from "../../../Redux/dashboard/Actions/MaterialActions";

export default function DeleteMaterialModal(props) {
  const { id, onHide } = props;

  const dispatch = useDispatch();

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      deleteMaterial(id)
    );

    onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={theme === "dark" ? "dark-theme-modal" : "light-theme-modal"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Obrisi materijal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="">Da li sigurno zelis da obrises ovaj materijal?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={submitHandler}>Potvrdi</Button>
        <Button variant="danger" onClick={() => onHide()}>
          Zatvori
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
