import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../loading-error/Toast";
import { toast } from "react-toastify";
import Message from "../loading-error/Error";
import SortableImages from "../common/sortable/SortableImages";
import {
  createBrand,
  listBrands,
} from "../../../Redux/dashboard/Actions/BrandActions";
import Input from "../ui/form/Input";

export default function CreateBrand() {
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  const inputRef = useRef();

  const brandCreate = useSelector((state) => state.brandCreate);
  const { error, brand } = brandCreate;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
    theme,
  };

  const resetForm = () => {
    setTitle("");
    setImages([]);
    inputRef.current.value = null;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createBrand({
        title,
        image: images[0],
      })
    );

    resetForm();
  };

  useEffect(() => {
    if (brand) {
      toast.success("Brand je kreiran", ToastObjects);
      setTitle("");
      setImages([]);

      dispatch(listBrands({ pageNumber: 1, keyword: "" }));
    }
  }, [brand, dispatch]);

  return (
    <>
      <Toast />
      <div className="container mt-3 mb-5">
        <form onSubmit={submitHandler}>
          <div className="d-flex flex-column flex-md-row flex-lg-row gap-3 mb-4">
            <Input
              id="brand_name"
              className="flex-grow-1"
              type="text"
              label="Naziv"
              required={true}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="flex-grow-1">
              <label className="form-label">Slika</label>
              <SortableImages
                ref={inputRef}
                images={images}
                setImages={setImages}
              />
            </div>
          </div>

          {error && <Message variant="alert-danger">{error}</Message>}

          <div className="d-flex flex-column flex-md-row flex-lg-row mt-3 gap-3 flex-grow-1">
            <button
              disabled={!title || images.length === 0}
              className="btn btn-primary py-3 flex-grow-1"
            >
              Dodaj brend
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
