import React, { useEffect, useMemo } from "react";
import OrderDetailProducts from "./OrderDetailProducts";
import OrderDetailInfo from "./OrderDetailInfo";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  // sendOrderToSeller,
  getOrderDetails,
  orderStatus,
} from "../../../Redux/dashboard/Actions/OrderActions";
// import { sendOrderMail } from "../../../Redux/dashboard/Actions/PostActions";
import Loading from "../loading-error/Loading";
import Message from "../loading-error/Error";
import moment from "moment";
import { Section, Card, CardHeader } from "../common";

const OrderDetailmain = ({ orderId }) => {
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetails;

  const orderComplaint = useSelector((state) => state.orderComplaint);
  const { loading: complaintLoading, error: complaintError } = orderComplaint;

  // const orderDeliver = useSelector((state) => state.orderDeliver);
  // const { loading: loadingDelivered, success: successDelivered } = orderDeliver;

  const themeData = useSelector((state) => state.theme);
  const { theme } = themeData;

  // const forwardHandler = () => {
  //   dispatch(
  //     sendOrderMail({ orderId, sortOrderItems, shippingAddress: order.shippingAddress })
  //   );
  //   dispatch(sendOrderToSeller(order));
  // };

  // const sortOrderItems = useMemo(() => {
  //   const ordersBySellers = order?.orderItems.reduce(
  //     (accumulator, currentItem) => {
  //       let existingSeller = accumulator.find(
  //         (n) => n.sellerEmail === currentItem.seller.email
  //       );

  //       if (existingSeller) {
  //         let _index = accumulator.indexOf(existingSeller);
  //         accumulator[_index].items.push(currentItem);
  //       } else {
  //         accumulator.push({
  //           sellerEmail: currentItem.seller.email,
  //           items: [currentItem],
  //         });
  //       }

  //       return accumulator;
  //     },
  //     []
  //   );

  //   return ordersBySellers;
  // }, [order]);

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
  }, [dispatch, orderId]);

  return (
    <Section className="position-relative">
      <div className="content-header">
        <Link
          to="/orders"
          className={`btn ${theme === "dark" ? "btn-dark" : "btn-light"}`}
        >
          Nazad na porudzbine
        </Link>
      </div>

      {loading || complaintLoading ? (
        <Loading position="position-absolute" width="100%" height="100%" />
      ) : error || complaintError ? (
        <Message variant="alert-danger">{error || complaintError}</Message>
      ) : (
        <Card section>
          <CardHeader className="p-3 Header-green">
            <div className="row align-items-center ">
              <div className="col-lg-6 col-md-6">
                <span>
                  <i className="far fa-calendar-alt mx-2"></i>
                  <b className="text-white">
                    {moment(order.createdAt).format("llll")}
                  </b>
                </span>
                <br />
                <small className="text-white mx-3 ">
                  ID Porudzbine: {order._id}
                </small>
              </div>
              <div className="col-lg-6 col-md-6 ms-auto d-flex justify-content-end align-items-center">
                <select
                  className={`form-select d-inline-block ${
                    theme === "dark" ? "dark-theme-section" : ""
                  }`}
                  value={order.status && order.status}
                  style={{ maxWidth: "200px" }}
                  onChange={(e) =>
                    dispatch(
                      orderStatus({ id: order._id, status: e.target.value })
                    )
                  }
                >
                  <option value="">Promeni status</option>
                  <option value="Predato brzoj posti">
                    Predato brzoj posti
                  </option>
                  <option value="Dostavljeno">Dostavljeno</option>
                </select>
                <Link className="btn btn-success ms-2" to="#">
                  <i className="fas fa-print"></i>
                </Link>
              </div>
            </div>
          </CardHeader>
          <div className="card-body">
            {/* Order info */}
            <OrderDetailInfo order={order} />

            <div className="row">
              <div className="table-responsive mb-4">
                <OrderDetailProducts order={order} loading={loading} />
              </div>
              {/* Payment Info */}
              {/* <div className="col-lg-3">
                <div className="box shadow-sm bg-light">
                  {order.isDelivered ? (
                    <button className="btn btn-success col-12">
                      PROSLEDJENO ({" "}
                      {moment(order.isDeliveredAt).format("MMM Do YY")})
                    </button>
                  ) : (
                    <>
                      {loadingDelivered && <Loading />}
                      <button
                        onClick={forwardHandler}
                        className="btn btn-dark col-12"
                      >
                        FORWARD ORDER
                      </button>
                    </>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </Card>
      )}
    </Section>
  );
};

export default OrderDetailmain;
