import React from "react";
import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import CategoryRequest from "../../components/dashboard/category-request/CategoryRequest";
import PageWrapper from "../../components/dashboard/common/PageWapper";

const CategoryRequestScreen = () => {
  return (
    <>
      <Sidebar />
      <PageWrapper>
        <Header />
        <CategoryRequest />
      </PageWrapper>
    </>
  );
};

export default CategoryRequestScreen;
