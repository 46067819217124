import {
  CATEGORY_REQUEST_LIST_REQUEST,
  CATEGORY_REQUEST_LIST_SUCCESS,
  CATEGORY_REQUEST_LIST_FAIL,
  CATEGORY_REQUEST_CREATE_REQUEST,
  CATEGORY_REQUEST_CREATE_SUCCESS,
  CATEGORY_REQUEST_CREATE_FAIL,
  CATEGORY_REQUEST_APROVE_REQUEST,
  CATEGORY_REQUEST_APROVE_SUCCESS,
  CATEGORY_REQUEST_APROVE_FAIL,
  CATEGORY_REQUEST_REJECT_REQUEST,
  CATEGORY_REQUEST_REJECT_SUCCESS,
  CATEGORY_REQUEST_REJECT_FAIL,
} from "../Constants/CategoryRequestConstants";
import { logout } from "./UserActions";
import _axios from "_axios";

// GET CATEGORY REQUEST LIST
export const categoryRequestList =
  ({ pageNumber, keyword, email }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_REQUEST_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await _axios.get(
        `category-request/all/?pageNumber=${pageNumber}&keyword=${keyword}&email=${email}`,
        config
      );

      dispatch({ type: CATEGORY_REQUEST_LIST_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_REQUEST_LIST_FAIL,
        payload: message,
      });
    }
  };

// CREATE CATEGORY REQUEST
export const createCategoryRequest =
  ({
    targetCategory,
    productsNumber,
    newCategoryName,
    additionalRequests,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CATEGORY_REQUEST_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const seller = JSON.stringify({
        name: userInfo.name,
        email: userInfo.email,
      });

      const { data } = await _axios.post(
        `category-request/`,
        {
          targetCategory: JSON.stringify(targetCategory),
          productsNumber,
          newCategoryName,
          additionalRequests,
          seller,
        },
        config
      );

      dispatch({ type: CATEGORY_REQUEST_CREATE_SUCCESS, payload: data });
      dispatch(categoryRequestList({pageNumber: 1, keyword: "", email: userInfo.email}));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Autorizacija neuspela, nema tokena") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_REQUEST_CREATE_FAIL,
        payload: message,
      });
    }
  };

// CATEGORY REQUEST APROVE
export const categoryRequestAprove = (id, details) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_REQUEST_APROVE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    console.log('config::::', config);
    const requestAproved = await _axios.post(`category-request/aprove/${id}`, { details }, config);

    dispatch({ type: CATEGORY_REQUEST_APROVE_SUCCESS, payload: requestAproved });
    dispatch(categoryRequestList({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORY_REQUEST_APROVE_FAIL,
      payload: message,
    });
  }
};

// CATEGORY REQUEST REJECT
export const categoryRequestReject = (id, details) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_REQUEST_REJECT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await _axios.post(`category-request/reject/${id}`, { details }, config);

    dispatch({ type: CATEGORY_REQUEST_REJECT_SUCCESS });
    dispatch(categoryRequestList({ pageNumber: 1, keyword: "" }));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Autorizacija neuspela, nema tokena") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORY_REQUEST_REJECT_FAIL,
      payload: message,
    });
  }
};
