import React, { useEffect, useState } from "react";
import MultiRangeSlider from "../ui/multiRangeSlider/MultiRangeSlider";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  listProduct,
  highestProductPrice,
} from "../../../Redux/client/Actions/ProductActions";
import Checkbox from "../ui/form/Checkbox";
import { listBrands } from "../../../Redux/dashboard/Actions/BrandActions";
import { isEmpty } from "lodash";
import { listMaterials } from "../../../Redux/dashboard/Actions/MaterialActions";

function ShopFilters({ keywordProp }) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [localPrevBrands, setLocalPrevBrands] = useState([]);
  const [localPrevMaterials, setLocalPrevMaterials] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();

  const highestPrice = useSelector((state) => state.highestPrice);
  const { highest } = highestPrice;

  const productList = useSelector((state) => state.productList);
  const { prevSlug, prevBrands, prevMaterials, toShow, sort } = productList;

  const brandList = useSelector((state) => state.brandList);
  const { brands } = brandList;

  const materialData = useSelector((state) => state.materialList);
  const { materials } = materialData;

  const settingsData = useSelector((state) => state.settings);
  const { theme } = settingsData;

  const path = history.location.pathname.split("/")[1];

  const onBrandInput = (e) => {
    let newBrands = prevBrands ? [...prevBrands] : [];
    if (!e.target.checked) {
      newBrands = [...newBrands, e.target.value];
    } else {
      newBrands = newBrands.filter((n) => n !== e.target.value);
    }

    filterProducts({ brands: newBrands, materials: prevMaterials });
  };

  const onMaterialInput = (e) => {
    let newMaterials = prevMaterials ? [...prevMaterials] : [];
    if (!e.target.checked) {
      newMaterials = [...newMaterials, e.target.value];
    } else {
      newMaterials = newMaterials.filter((n) => n !== e.target.value);
    }

    filterProducts({ brands: prevBrands, materials: newMaterials });
  };

  const filterProducts = ({ brands, materials }) => {
    setLocalPrevBrands(brands);
    setLocalPrevMaterials(materials);

    if (path === "shop") {
      dispatch(
        listProduct({
          pageNumber: 1,
          keyword: "",
          slug: prevSlug,
          toShow,
          sortBy: sort,
          brands: brands?.toString(),
          materials: materials?.toString(),
          min,
          max,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(highestProductPrice());
    dispatch(listBrands({ pageNumber: "all", keyword: "" }));
    dispatch(listMaterials());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(prevBrands)) {
      setLocalPrevBrands(prevBrands);
    }
  }, [prevBrands]);

  useEffect(() => {
    if (!isEmpty(prevMaterials)) {
      setLocalPrevMaterials(prevMaterials);
    }
  }, [prevMaterials]);

  useEffect(() => {
    if (keywordProp) {
      setLocalPrevBrands([]);
      setLocalPrevMaterials([]);
    }
  }, [keywordProp]);

  return (
    <>
      <div className="d-flex flex-column justify-content-end">
        <h6 className="text-center mb-4 text-gray">RASPON CENE</h6>
        {highest?.price && (
          <MultiRangeSlider
            min={0}
            max={highest.price}
            onChange={({ min, max }) => {
              setMin(min);
              setMax(max);
            }}
          />
        )}

        <button
          type="submit"
          className={`search-button mt-5 w-auto px-2 py-1 align-self-end bg-${theme} bg-transition-hover-${theme}-600`}
          onClick={() =>
            filterProducts({ brands: prevBrands, materials: prevMaterials })
          }
        >
          Filtriraj
        </button>
      </div>

      {path === "shop" && brands && !isEmpty(brands.brands) && (
        <>
          <h6 className="text-center mt-5 text-gray">BREND</h6>
          <div className="d-flex flex-wrap gap-2 mt-3">
            {brands?.brands?.map((brand) => (
              <Checkbox
                id="brand_check"
                key={brand._id}
                label={brand.title}
                value={brand.title}
                checked={localPrevBrands?.includes(brand.title)}
                onInput={onBrandInput}
              />
            ))}
          </div>
        </>
      )}

      {!isEmpty(materials) && (
        <>
          <h6 className="text-center mt-5 text-gray">MATERIJAL</h6>
          <div className="d-flex flex-wrap gap-2 mt-3">
            {materials?.map((material) => (
              <Checkbox
                id="materijal_check"
                key={material._id}
                label={material.title}
                value={material.title}
                checked={localPrevMaterials?.includes(material.title)}
                onInput={onMaterialInput}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(ShopFilters);
